import { FC } from "react";
import { Link } from "react-location";
// import { LocationGenerics } from "router/location";
import {
  CursorArrowRaysIcon,
  EnvelopeOpenIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "utils";
import lodash from "lodash";
import { currentUserVar } from "apollo/cache/auth";
import { Header } from "components";

const templateList = [
  {
    name: "Bulk Replacement",
    fileUrl:
      "https://subs-production.s3.us-west-2.amazonaws.com/templates/meter-replacement-template.xlsx",
    icon: CursorArrowRaysIcon,
    description:"Template to initiate bulk replacement of meters"
  },
  {
    name: "Service Backlog",
    fileUrl:
      "https://subs-production.s3.us-west-2.amazonaws.com/templates/service-backlog-template.xlsx",
    icon: UsersIcon,
    description:"Template to initiate service backlog"
  },
  {
    name: "SHEP Backlog",
    fileUrl:
      "https://subs-production.s3.us-west-2.amazonaws.com/templates/shep-backlog-template.xlsx",
    icon: EnvelopeOpenIcon,
    description:"Template to initiate SHEP backlog"
  },
  {
    name: "Installation Backlog",
    fileUrl:
      "https://subs-production.s3.us-west-2.amazonaws.com/templates/installation-backlog-template.xlsx",
    icon: CursorArrowRaysIcon,
    description:"Template to initiate installation backlog"
  },
  {
    name: "Lrp Paid-Up Backlog",
    fileUrl:
      "https://subs-templates.s3.us-west-2.amazonaws.com/paid-up-backlog-template.xlsx",
    icon: EnvelopeOpenIcon,
    description:"Template to initiate Lrp Paid-Up Backlog"
  },
];

const TemplatesPage: FC = () => {
  return (
    <main className='flex-1 flex flex-col overflow-hidden bg-gray-50'>
      <Header />
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto mt-8  p-4 sm:p-6 overflow-y-auto bg-gray-50 '>
          <div className='grid grid-cols-1 items-start gap-4 lg:grid-cols-12 lg:gap-8'>
            <div className='col-span-12'>
              <dl className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
                {templateList.map((item, index) => (
                  <div
                    key={index}
                    className='relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6'
                  >
                    <dt>
                      <div className='absolute rounded-md bg-primary-500 p-3'>
                        <item.icon
                          className='h-6 w-6 text-white'
                          aria-hidden='true'
                        />
                      </div>
                      <p className='ml-16 truncate  font-medium text-gray-700'>
                        {item.name}
                      </p>
                    </dt>
                    <dd className='ml-16 flex justify-between items-end pb-6 sm:pb-7'>
                      <p className='text-sm  text-gray-500 mt-0.5'>
                        {item?.description || ""}
                      </p>
                      <div className='absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6'>
                        <div className='text-sm'>
                          <a
                            href={item.fileUrl}
                            className='font-medium text-primary-600 hover:text-primary-500'
                            target='_blank'
                            download
                            rel='noreferrer'
                          >
                            Click Here to download
                          </a>
                        </div>
                      </div>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default TemplatesPage;
