import _ from "lodash";
import moment from "moment";
import numeral from "numeral";

const CustomerInfoSummary = ({ data, config: { dateFormat } }: any) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3">
    <div className="divide-y-2 divide-gray-300 divide-dashed space-y-6">
      <div>
        <div className="grid grid-cols-4 gap-4">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Replacement Type
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {_.startCase(data?.type) || "N/A"}
            </div>
          </div>
          {data?.type?.startsWith("Prepaid") && (
            <>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Debt Amount
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  GHS {_.isNumber(data?.debtAmount)
                    ? numeral(data?.debtAmount).format("0,0.00")
                    : "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Credit Amount
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  GHS {_.isNumber(data?.creditAmount)
                    ? numeral(data?.creditAmount).format("0,0.00")
                    : "N/A"}
                </div>
              </div>
            </>
          )}
          <div>
            <span className="block text-sm font-light text-gray-700">
              Old Meter Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.oldMeterNumber || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Old Meter Phase
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.oldMeterPhase || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <div className="grid grid-cols-4 gap-4">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Priority
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.priority || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Estimated Resolution Date
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.estimatedResolutionDate
                ? moment(data.estimatedResolutionDate).format(dateFormat)
                : "N/A"}
            </div>
          </div>
          <div className="col-span-3">
            <span className="block text-sm font-light text-gray-700">
              Extra Notes
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.notes || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CustomerInfoSummary;
