import { gql, useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import {
  Shimmers,
  TableComponent,
  ReplacementsInitiationTable,
  OfficeCalendarHeader,
} from "components";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { Link, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  wrapClick,
  useUrlState,
  classNames,
  useTableData,
  useDownloadFile,
} from "utils";
import config from "config";
import AssignReplacementServiceOrderContainer from "./assign";
import ViewReplacementServiceOrderContainer from "./view";
import { Avatar, SearchSelectInput, SelectInput } from "components/core";
import { currentConfigVar } from "apollo/cache/config";
import ActionButton, { Action } from "components/buttons/action-button";
import _ from "lodash";
import OfficeHeader from "components/layouts/office-header";
import { ArrowDownTrayIcon, PlusIcon } from "@heroicons/react/24/outline";
import { currentDistrictVar } from "apollo/cache/auth";
import numeral from "numeral";
import {
  GET_REPLACEMENT_SERVICE_ORDERS,
  GET_REPLACEMENT_SERVICE_ORDER_INITIATIONS,
} from "./hooks";
import ApproveReplacementServiceOrderContainer from "./approve";
import BulkInitiateReplacementServiceOrderContainer from "./initiate-bulk";
import CancelReplacementServiceOrderContainer from "./cancel";
import InitiateReplacementServiceOrderContainer from "./initiate";
import ViewReplacementServiceOrderInitiationContainer from "./view-order-initiation";
import DisapproveReplacementServiceOrderContainer from "./disapprove";
import { Menu } from "@headlessui/react";
import toast from "react-hot-toast";
import ExportDataContainer from "./export";

const GET_SERVICE_ORDER_SUMMARY = gql`
  query GetReplacementServiceOrderSummary(
    $district: ID
    $fromDate: Date
    $toDate: Date
    $dateField: String
  ) {
    getReplacementServiceOrderSummary(
      district: $district
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
    ) {
      Pending
      AssignedToSupplier
      AssignedToInstaller
      Resolved
      InProgress
      Completed
      Rejected
      Disapproved
      Cancelled
    }
  }
`;

export const GET_SERVICE_WORK_ORDERS = gql`
  query GetReplacementServiceOrderAssignments(
    $district: ID
    $page: Int
    $pageSize: Int
    $search: String
    $searchFields: [String!]
    $sort: String
    $status: ReplacementServiceOrderAssignmentStatus
  ) {
    rows: getReplacementServiceOrderAssignments(
      district: $district
      page: $page
      pageSize: $pageSize
      search: $search
      searchFields: $searchFields
      sort: $sort
      status: $status
    ) {
      _id
      code
      status
      district {
        _id
        code
        name
      }
      meta {
        totalSuccessAssignments
        totalFailedAssignments
        totalAssignments
      }
      createdBy {
        _id
        lastName
        firstName
        phoneNumber
        profileImageUrl
      }
      createdAt
      updatedAt
    }
    count: getReplacementServiceOrderAssignmentsCount(
      district: $district
      search: $search
      searchFields: $searchFields
      status: $status
    )
  }
`;

const orderStatuses = [
  { name: "Pending", href: "Pending" },
  { name: "Assigned To Supplier", href: "AssignedToSupplier" },
  { name: "Assigned To Installer", href: "AssignedToInstaller" },
  { name: "In Progress", href: "InProgress" },
  { name: "Resolved", href: "Resolved" },
  { name: "Completed", href: "Completed" },
];

const altOrderStatuses = [
  { name: "Rejected", href: "Rejected" },
  { name: "Disapproved", href: "Disapproved" },
  { name: "Cancelled", href: "Cancelled" },
  { name: "Bulk Initiations", href: "BulkInitiation" },
];
const searchOptions = [
  { label: "Code", value: "code", min: 4 },
  { label: "Service Point Number", value: "servicePointCode", min: 5 },
  { label: "Contact Name", value: "contactPerson.fullName", min: 4 },
  { label: "Contact Phone Number", value: "contactPerson.phoneNumber", min: 4 },
  { label: "Location Address", value: "location.address", min: 4 },
  { label: "Location Community", value: "location.community", min: 4 },
];

const workOrderSearchOptions = [
  { label: "Code", value: "code", min: 4 },
  { label: "Full Name", value: "assignments.fullName", min: 4 },
  {
    label: "Service Point Code",
    value: "assignments.servicePointCode",
    min: 4,
  },
  { label: "Address", value: "assignments.address", min: 4 },
  { label: "Digital Address", value: "assignments.ghanaPostAddress", min: 4 },
];

const ReplacementServiceOrdersPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [serviceOrder, setServiceOrder] = useUrlState("serviceOrder");
  const [priority, setPriority] = useUrlState("priority");
  const [prepaidSyncStatus, setPrepaidSyncStatus] =
    useUrlState("prepaidSyncStatus");
  const [orderStatus] = useUrlState("orderStatus");
  const currentDistrict = useReactiveVar(currentDistrictVar);
  const [searchBy, setSearchParameter] = useUrlState("search_field");
  const [searchValue, setSearch] = useUrlState<string>("search");
  const [minSearch, setMinSearch] = useUrlState("minSearch");
  const updateSearchBy = (key: string) => {
    setSearchParameter(key);
    if (key) {
      setMinSearch(searchOptions.find((option) => option.value === key)?.min);
    }
  };
  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search:
        searchParams.search && searchBy
          ? (searchParams.search || "").toString()
          : undefined,
      searchFields: searchBy && searchValue ? [searchBy] : undefined,
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      ...(searchParams?.orderStatus !== "BulkInitiation"
        ? {
            status: searchParams.orderStatus || undefined,
            installationMeterSystemSyncStatus:
              searchParams.prepaidSyncStatus || undefined,
          }
        : {}),
      priority: searchParams.priority || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
      dateField: searchParams.dateField || "createdAt"
    }),
    [searchParams]
  );

  const workOrdersFilter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search:
        searchParams.search && searchBy
          ? (searchParams.search || "").toString()
          : undefined,
      searchFields: searchBy && searchValue ? [searchBy] : undefined,
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      status: searchParams?.orderInitiationStatus || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
    }),
    [searchParams]
  );

  const { data, loading, networkStatus, refetch } = useQuery(
    searchParams?.orderStatus === "BulkInitiation"
      ? GET_REPLACEMENT_SERVICE_ORDER_INITIATIONS
      : GET_REPLACEMENT_SERVICE_ORDERS,
    {
      variables:
        searchParams?.orderStatus === "BulkInitiation"
          ? workOrdersFilter
          : filter,
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        refetchSummary?.();
      },
    }
  );

  const { data: dataSummary, refetch: refetchSummary } = useQuery(
    GET_SERVICE_ORDER_SUMMARY,
    {
      variables: {
        ...(currentDistrict ? { district: currentDistrict } : searchParams.district ? { district: searchParams.district } : {}),
        fromDate: searchParams.fromDate || undefined,
        toDate: searchParams.toDate || undefined,
        dateField: searchParams.dateField || "createdAt",
      },
      notifyOnNetworkStatusChange: false,
    }
  );

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "createdAt" } : {}),
      }),
    });
  }, [navigate]);

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        id: undefined,
      }),
    });
  }, [searchParams.orderStatus, navigate]);

  const records = useTableData(data || {});

  return (
    <main className='flex-1 flex flex-col overflow-hidden bg-gray-50'>
      <OfficeCalendarHeader
        renderActions={() => (
          <>
            <Menu as='div' className='relative inline-block text-left'>
              <Menu.Button className=' inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2.5 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'>
                <PlusIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
                Initiate Replacement
              </Menu.Button>
              <Menu.Items className='absolute z-50 mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
              <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={wrapClick(() => setModal("initiate"))}
                      className={`${
                        active ? "bg-primary-600 text-white" : "text-gray-900"
                      } group flex items-center w-full rounded-md px-2 py-2 text-sm`}
                    >
                      Single
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={wrapClick(() => setModal("create-bulk"))}
                      className={`${
                        active ? "bg-primary-600 text-white" : "text-gray-900"
                      } group flex items-center w-full rounded-md px-2 py-2 text-sm`}
                    >
                      Bulk
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </>
        )}
      />
      <div className='block'>
        <div className='border-b border-gray-200 bg-white px-6'>
          <nav
            className='-mb-px flex space-x-4 items-center overflow-x-scroll no-scrollbar'
            aria-label='Tabs'
          >
            <Link
              search={(old) => ({
                ...old,
                orderStatus: undefined,
                page: config.constants.page,
                pageSize: config.constants.pageSize,
              })}
              className={classNames(
                !orderStatus
                  ? "border-primary-500 text-primary-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={!orderStatus ? "page" : undefined}
            >
              All Orders
              <span
                className={classNames(
                  !orderStatus
                    ? "bg-primary-100 text-primary-600"
                    : "bg-gray-100 text-gray-900",
                  "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                )}
              >
                {numeral(
                  _.sum(
                    _.values(
                      _.omit(
                        dataSummary?.getReplacementServiceOrderSummary,
                        "__typename"
                      )
                    )
                  )
                ).format("0,0")}
              </span>
            </Link>
            {orderStatuses.map((_orderStatus) => (
              <Link
                key={_orderStatus.name}
                search={(old) => ({
                  ...old,
                  orderStatus: _orderStatus.href,
                  page: config.constants.page,
                  pageSize: config.constants.pageSize,
                })}
                className={classNames(
                  orderStatus === _orderStatus.href
                    ? "border-primary-500 text-primary-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={
                  orderStatus === _orderStatus.href ? "page" : undefined
                }
              >
                {_orderStatus.name}
                {_orderStatus.href ? (
                  <span
                    className={classNames(
                      orderStatus === _orderStatus.href
                        ? "bg-primary-100 text-primary-600"
                        : "bg-gray-100 text-gray-900",
                      "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                    )}
                  >
                    {numeral(
                      dataSummary?.getReplacementServiceOrderSummary?.[
                        _orderStatus?.href
                      ] || 0
                    ).format("0,0")}
                  </span>
                ) : null}
              </Link>
            ))}
            <div className='h-6 w-px bg-gray-300' />
            {altOrderStatuses.map((_orderStatus) => (
              <Link
                key={_orderStatus.name}
                search={(old) => ({
                  ...old,
                  orderStatus: _orderStatus.href,
                  page: config.constants.page,
                  pageSize: config.constants.pageSize,
                })}
                className={classNames(
                  orderStatus === _orderStatus.href
                    ? "border-primary-500 text-primary-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={
                  orderStatus === _orderStatus.href ? "page" : undefined
                }
              >
                {_orderStatus.name}
                {_orderStatus.href && _orderStatus.href !== "BulkInitiation" ? (
                  <span
                    className={classNames(
                      orderStatus === _orderStatus.href
                        ? "bg-primary-100 text-primary-600"
                        : "bg-gray-100 text-gray-900",
                      "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                    )}
                  >
                    {numeral(
                      dataSummary?.getReplacementServiceOrderSummary?.[
                        _orderStatus?.href
                      ] || 0
                    ).format("0,0")}
                  </span>
                ) : null}
              </Link>
            ))}
          </nav>
        </div>
      </div>
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          {searchParams?.orderStatus === "BulkInitiation" && (
            <ReplacementsInitiationTable
              searchOptions={workOrderSearchOptions}
              updateSearchBy={updateSearchBy}
              searchBy={searchBy}
              setSearch={setSearch}
              refetch={refetch}
              isRefetching={loading && networkStatus === 4}
              loading={loading && ![4, 6].includes(networkStatus)}
              data={records}
              dispatchAction={dispatchAction}
            />
          )}

          {((searchParams?.orderStatus &&
            [
              "Pending",
              "AssignedToSupplier",
              "AssignedToInstaller",
              "InProgress",
              "Resolved",
              "Completed",
              "Rejected",
              "Disapproved",
              "Cancelled",
            ].includes(searchParams?.orderStatus)) ||
            !searchParams?.orderStatus) && (
            <TableComponent
              title={"service orders"}
              refetch={refetch}
              isRefetching={loading && networkStatus === 4}
              loading={loading && ![4, 6].includes(networkStatus)}
              data={records}
              hasSearch={true}
              onSearchClicked={setSearch}
              disableSearch={!searchBy}
              renderExport={({ exportOpen, setExportOpen }) => (
                <ExportDataContainer
                  entityType={""}
                  open={exportOpen}
                  setOpen={setExportOpen}
                  filter={filter}
                />
              )}
              renderSearchOption={() => (
                <SelectInput
                  id={"searchBy"}
                  label={""}
                  values={{ searchBy }}
                  handleChange={(e: any) => updateSearchBy(e.target.value)}
                  handleBlur={undefined}
                  options={[
                    {
                      label: "Search By",
                      value: "",
                    },
                    ...searchOptions.map((option) => ({
                      label: option.label,
                      value: option.value,
                    })),
                  ]}
                />
              )}
              renderHeaderItems={() => (
                <div className='mt-4 sm:mt-0.5 sm:ml-16 sm:flex space-x-2'>
                  <SearchSelectInput
                    id='priority'
                    labelHidden={true}
                    options={[
                      { label: { title: "Low" }, value: "Low" },
                      { label: { title: "Medium" }, value: "Medium" },
                      { label: { title: "High" }, value: "High" },
                      { label: { title: "Critical" }, value: "Critical" },
                    ]}
                    label={""}
                    placeholder='Select Priority'
                    setFieldValue={(_: any, value: string) =>
                      setPriority(value)
                    }
                    values={{ priority }}
                  />
                  <SearchSelectInput
                    id='prepaidSyncStatus'
                    labelHidden={true}
                    options={[
                      { label: { title: "Pending" }, value: "Pending" },
                      {
                        label: { title: "Forward Synced" },
                        value: "ForwardSynced",
                      },
                      {
                        label: { title: "Reverse Synced" },
                        value: "ReverseSynced",
                      },
                      { label: { title: "Completed" }, value: "Completed" },
                    ]}
                    label={""}
                    placeholder='Select Sync Status'
                    setFieldValue={(_: any, value: string) =>
                      setPrepaidSyncStatus(value)
                    }
                    values={{ prepaidSyncStatus }}
                  />
                </div>
              )}
              renderColumns={() => (
                <tr>
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Code | Date
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Customer
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Location
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Type
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    ServicePoint
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Tariff Class
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Old Meter
                  </th>
                  {["AssignedToInstaller", "InProgress", "Resolved"].includes(
                    orderStatus
                  ) && (
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Assigned Meter
                    </th>
                  )}
                  {["Completed"].includes(orderStatus) && (
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Installed Meter
                    </th>
                  )}
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Priority
                  </th>
                  {[
                    "AssignedToInstaller",
                    "InProgress",
                    "Resolved",
                    "Completed",
                  ].includes(orderStatus) && (
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Prepayment Sync Status
                    </th>
                  )}
                  {[
                    "AssignedToSupplier",
                    "AssignedToInstaller",
                    "InProgress",
                    "Resolved",
                  ].includes(orderStatus) && (
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Assigned | ETA
                    </th>
                  )}
                  {["Rejected"].includes(orderStatus) && (
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Assigned | Rejected
                    </th>
                  )}
                  {["Disapproved"].includes(orderStatus) && (
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Disapproved
                    </th>
                  )}
                  {["Completed"].includes(orderStatus) && (
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Resolution Period
                    </th>
                  )}
                  {[
                    "AssignedToSupplier",
                    "AssignedToInstaller",
                    "InProgress",
                    "Resolved",
                    "Completed",
                    "Disapproved",
                  ].includes(orderStatus) && (
                    <>
                      <th
                        scope='col'
                        className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                      >
                        Assigner
                      </th>
                      <th
                        scope='col'
                        className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                      >
                        Assignee
                      </th>
                    </>
                  )}
                  {["Completed", "Disapproved"].includes(orderStatus) && (
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      {orderStatus === "Disapproved"
                        ? "Disapprover"
                        : "Approver"}
                    </th>
                  )}
                  {!orderStatus && (
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Status
                    </th>
                  )}
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  ></th>
                </tr>
              )}
              renderLoader={() => (
                <tr>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.SingleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                  {[
                    "AssignedToInstaller",
                    "InProgress",
                    "Resolved",
                    "Completed",
                  ].includes(orderStatus) && (
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.DoubleShimmer />
                    </td>
                  )}
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.SingleShimmer />
                  </td>
                  {[
                    "AssignedToInstaller",
                    "InProgress",
                    "Resolved",
                    "Completed",
                  ].includes(orderStatus) && (
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.DoubleShimmer />
                    </td>
                  )}
                  {[
                    "AssignedToSupplier",
                    "AssignedToInstaller",
                    "InProgress",
                    "Resolved",
                  ].includes(orderStatus) && (
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.DoubleShimmer />
                    </td>
                  )}
                  {["Rejected"].includes(orderStatus) && (
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.DoubleShimmer />
                    </td>
                  )}
                  {["Disapproved"].includes(orderStatus) && (
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.DoubleShimmer />
                    </td>
                  )}
                  {["Completed"].includes(orderStatus) && (
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.DoubleShimmer />
                    </td>
                  )}

                  {[
                    "AssignedToSupplier",
                    "AssignedToInstaller",
                    "InProgress",
                    "Resolved",
                    "Completed",
                  ].includes(orderStatus) && (
                    <>
                      <td className='px-6 py-4 border-b border-gray-200'>
                        <Shimmers.AvatarShimmer />
                      </td>
                      <td className='px-6 py-4 border-b border-gray-200'>
                        <Shimmers.AvatarShimmer />
                      </td>
                    </>
                  )}
                  {["Completed", "Disapproved"].includes(orderStatus) && (
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.AvatarShimmer />
                    </td>
                  )}
                  {!orderStatus && (
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.SingleShimmer />
                    </td>
                  )}
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.ActionsShimmer actionsCount={4} />
                  </td>
                </tr>
              )}
              renderItem={(item) => (
                <tr
                  key={item._id}
                  className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                  onClick={wrapClick(dispatchAction(item._id, "view"))}
                >
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                    <div className='font-medium text-gray-900 dark:text-gray-100'>
                      {item?.code || "N/A"}
                    </div>
                    <div className='font-medium text-gray-500 dark:text-gray-400'>
                      {moment(item?.createdAt).format(dateFormat)}
                    </div>
                  </td>
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                    <div className='text-gray-900 dark:text-gray-100'>
                      {item?.contactPerson?.fullName || "N/A"}
                    </div>
                    <div className='text-gray-500 dark:text-gray-500'>
                      {item?.contactPerson?.phoneNumber || "N/A"}
                    </div>
                  </td>
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                    <div className='text-gray-900 dark:text-gray-100'>
                      {item?.location?.address || "N/A"}
                    </div>
                    <div className='text-gray-500 dark:text-gray-500'>
                      {item?.location?.community || "N/A"}
                    </div>
                  </td>
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                    <span
                      className={classNames(
                        "bg-gray-100 text-gray-800",
                        item?.replacementType === "High"
                          ? "bg-orange-100 text-orange-800 dark:bg-orange-600 dark:text-orange-100"
                          : "",
                        item?.replacementType === "Low"
                          ? "bg-gray-100 text-gray-800 dark:bg-gray-600 dark:text-gray-50"
                          : "",
                        item?.replacementType === "Critical"
                          ? "bg-red-100 text-red-800 dark:bg-red-600 dark:text-red-100"
                          : "",
                        item?.replacementType === "Medium"
                          ? "bg-yellow-100 text-yellow-800 dark:bg-yello-600 dark:text-yellow-100"
                          : "",
                        !item?.replacementType
                          ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                          : "",
                        "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                      )}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 10 10'
                        fill='currentColor'
                        className='w-1.5 h-1.5'
                      >
                        <circle
                          fillRule='evenodd'
                          cx='5'
                          cy='5'
                          r='5'
                          clipRule='evenodd'
                        />
                      </svg>
                      <span>
                        {_.startCase(item?.replacementType) || "Unknown"}
                      </span>
                    </span>
                  </td>
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                    <div className='text-gray-900 dark:text-gray-100'>
                      {item?.servicePoint?.code || "N/A"}
                    </div>
                    <div className='text-gray-500 dark:text-gray-500'>
                      {item?.servicePoint?.geoCode || "N/A"}
                    </div>
                  </td>
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                    <div className='text-gray-900 dark:text-gray-100'>
                      {item?.servicePoint?.tariffClass?.code || "N/A"} (
                      {item?.servicePoint?.tariffClass?.name || "N/A"})
                    </div>
                    <div className='text-gray-500 dark:text-gray-500'>
                      {item?.meter?.modelMeta?.phase || "N/A"}{" "}
                    </div>
                  </td>
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                    <div className='text-gray-900 dark:text-gray-100'>
                      {item?.meter?.code || "N/A"}
                    </div>
                    <div className='text-gray-500 dark:text-gray-500'>
                      {item?.meter?.modelMeta?.brandName || "N/A"} -{" "}
                      {item?.meter?.modelMeta?.modelName || "N/A"}
                    </div>
                  </td>
                  {["AssignedToInstaller", "InProgress", "Resolved"].includes(
                    orderStatus
                  ) && (
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                      <div className='text-gray-900 dark:text-gray-100'>
                        {item?.installationMeter?.code || "N/A"}
                      </div>
                      <div className='text-gray-500 dark:text-gray-500'>
                        {item?.installationMeter?.modelMeta?.brandName || "N/A"}{" "}
                        -{" "}
                        {item?.installationMeter?.modelMeta?.modelName || "N/A"}
                      </div>
                    </td>
                  )}
                  {["Completed"].includes(orderStatus) && (
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                      <div className='text-gray-900 dark:text-gray-100'>
                        {item?.installedMeter?.code || "N/A"}
                      </div>
                      <div className='text-gray-500 dark:text-gray-500'>
                        {item?.installedMeter?.modelMeta?.brandName || "N/A"} -{" "}
                        {item?.installedMeter?.modelMeta?.modelName || "N/A"}
                      </div>
                    </td>
                  )}
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                    <span
                      className={classNames(
                        "bg-gray-100 text-gray-800",
                        item?.priority === "High"
                          ? "bg-orange-100 text-orange-800 dark:bg-orange-600 dark:text-orange-100"
                          : "",
                        item?.priority === "Low"
                          ? "bg-gray-100 text-gray-800 dark:bg-gray-600 dark:text-gray-50"
                          : "",
                        item?.priority === "Critical"
                          ? "bg-red-100 text-red-800 dark:bg-red-600 dark:text-red-100"
                          : "",
                        item?.priority === "Medium"
                          ? "bg-yellow-100 text-yellow-800 dark:bg-yello-600 dark:text-yellow-100"
                          : "",
                        !item?.priority
                          ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                          : "",
                        "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                      )}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 10 10'
                        fill='currentColor'
                        className='w-1.5 h-1.5'
                      >
                        <circle
                          fillRule='evenodd'
                          cx='5'
                          cy='5'
                          r='5'
                          clipRule='evenodd'
                        />
                      </svg>
                      <span>{item?.priority || "Unknown"}</span>
                    </span>
                  </td>
                  {[
                    "AssignedToInstaller",
                    "InProgress",
                    "Resolved",
                    "Completed",
                  ].includes(orderStatus) && (
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                      <span
                        className={classNames(
                          "bg-gray-100 text-gray-800",
                          item?.installationMeterSystemSyncStatus ===
                            "ForwardSynced"
                            ? "bg-blue-100 text-blue-800 dark:bg-blue-600 dark:text-blue-100"
                            : "",
                          item?.installationMeterSystemSyncStatus ===
                            "Completed"
                            ? "bg-green-100 text-green-800 dark:bg-green-600 dark:text-green-50"
                            : "",
                          item?.installationMeterSystemSyncStatus ===
                            "ReverseSynced"
                            ? "bg-orange-100 text-orange-800 dark:bg-orange-600 dark:text-orange-100"
                            : "",
                          item?.installationMeterSystemSyncStatus === "Pending"
                            ? "bg-yellow-100 text-yellow-800 dark:bg-yello-600 dark:text-yellow-100"
                            : "",
                          !item?.installationMeterSystemSyncStatus
                            ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                            : "",
                          "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                        )}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 10 10'
                          fill='currentColor'
                          className='w-1.5 h-1.5'
                        >
                          <circle
                            fillRule='evenodd'
                            cx='5'
                            cy='5'
                            r='5'
                            clipRule='evenodd'
                          />
                        </svg>
                        <span>
                          {item?.installationMeterSystemSyncStatus || "Unknown"}
                        </span>
                      </span>
                    </td>
                  )}
                  {[
                    "AssignedToSupplier",
                    "AssignedToInstaller",
                    "InProgress",
                    "Resolved",
                  ].includes(orderStatus) && (
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                      <div className='text-gray-900 dark:text-gray-100'>
                        {item?.assignedAt
                          ? moment(item?.assignedAt).format(dateFormat)
                          : "N/A"}
                      </div>
                      <div className='text-gray-500 dark:text-gray-400'>
                        {item?.estimatedResolutionDate
                          ? moment(item?.estimatedResolutionDate).format(
                              dateFormat
                            )
                          : "N/A"}
                      </div>
                    </td>
                  )}
                  {["Rejected"].includes(orderStatus) && (
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                      <div className='text-gray-900 dark:text-gray-100'>
                        {/* <span className="text-xs mr-2 font-light">from</span> */}
                        {item?.assignedAt
                          ? moment(item?.assignedAt).format(dateFormat)
                          : "N/A"}
                      </div>
                      <div className='text-gray-500 dark:text-gray-400'>
                        {/* <span className="text-xs mr-2 font-light">to</span> */}
                        {item?.rejectedAt
                          ? moment(item?.rejectedAt).format(dateFormat)
                          : "N/A"}
                      </div>
                    </td>
                  )}
                  {["Disapproved"].includes(orderStatus) && (
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                      <div className='text-gray-900 dark:text-gray-100'>
                        <span className='text-xs mr-2 font-light'>from</span>
                        {item?.assignedAt
                          ? moment(item?.assignedAt).format(dateFormat)
                          : "N/A"}
                      </div>
                      <div className='text-gray-500 dark:text-gray-400'>
                        <span className='text-xs mr-2 font-light'>to</span>
                        {item?.disapprovedAt
                          ? moment(item?.disapprovedAt).format(dateFormat)
                          : "N/A"}
                      </div>
                    </td>
                  )}
                  {["Completed", "Disapproved"].includes(orderStatus) && (
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                      <div className='text-gray-900 dark:text-gray-100'>
                        <span className='text-xs mr-2 font-light'>from</span>
                        {item?.assignedAt
                          ? moment(item?.assignedAt).format(dateFormat)
                          : "N/A"}
                      </div>
                      <div className='text-gray-500 dark:text-gray-400'>
                        <span className='text-xs mr-2 font-light'>to</span>
                        {item?.completedAt
                          ? moment(item?.completedAt).format(dateFormat)
                          : "N/A"}
                      </div>
                    </td>
                  )}
                  {[
                    "AssignedToSupplier",
                    "AssignedToInstaller",
                    "InProgress",
                    "Resolved",
                    "Completed",
                  ].includes(orderStatus) && (
                    <>
                      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                        <div className='flex items-center'>
                          <div className='h-10 w-10 flex-shrink-0'>
                            <Avatar
                              disabled={true}
                              alt={
                                [
                                  (item?.assigner?.lastName || "")?.trim(),
                                  (item?.assigner?.firstName || "")?.trim(),
                                ]
                                  .join(" ")
                                  .trim() || "N A"
                              }
                              src={item?.assigner?.profileImageUrl || ""}
                            />
                          </div>
                          <div className='ml-4'>
                            <div className='text-gray-900 dark:text-gray-100'>
                              {[
                                (item?.assigner?.lastName || "")?.trim(),
                                (item?.assigner?.firstName || "")?.trim(),
                              ]
                                .join(" ")
                                .trim() || "N A"}
                            </div>
                            <div className='text-gray-500 dark:text-gray-400'>
                              {item?.assigner?.phoneNumber || "N/A"}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                        {item.assigneeType ===
                        "MeterManufacturingContractor" ? (
                          <div className='flex items-center'>
                            <div className='h-10 w-10 flex-shrink-0'>
                              <Avatar
                                disabled={true}
                                alt={item?.meterContractor?.name || "N A"}
                              />
                            </div>
                            <div className='ml-4'>
                              <div className='text-gray-900 dark:text-gray-100'>
                                {item?.meterContractor?.name || "N/A"}
                              </div>
                              <div className='text-gray-500 dark:text-gray-400'>
                                Meter Manufacturing Contractor
                              </div>
                            </div>
                          </div>
                        ) : item.assigneeType === "FarmingOutContractor" ? (
                          <div className='flex items-center'>
                            <div className='h-10 w-10 flex-shrink-0'>
                              <Avatar
                                disabled={true}
                                alt={item?.farmingOutContractor?.name || "N A"}
                              />
                            </div>
                            <div className='ml-4'>
                              <div className='text-gray-900 dark:text-gray-100'>
                                {item?.farmingOutContractor?.name || "N/A"}
                              </div>
                              <div className='text-gray-500 dark:text-gray-400'>
                                Farming Out Contractor
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className='flex items-center'>
                            <div className='h-10 w-10 flex-shrink-0'>
                              <Avatar
                                disabled={true}
                                alt={
                                  [
                                    (item?.assignee?.lastName || "")?.trim(),
                                    (item?.assignee?.firstName || "")?.trim(),
                                  ]
                                    .join(" ")
                                    .trim() || "N A"
                                }
                                src={item?.assignee?.profileImageUrl || ""}
                              />
                            </div>
                            <div className='ml-4'>
                              <div className='text-gray-900 dark:text-gray-100'>
                                {[
                                  (item?.assignee?.lastName || "")?.trim(),
                                  (item?.assignee?.firstName || "")?.trim(),
                                ]
                                  .join(" ")
                                  .trim() || "N A"}
                              </div>
                              <div className='text-gray-500 dark:text-gray-400'>
                                {item?.assignee?.phoneNumber || "N/A"}
                              </div>
                            </div>
                          </div>
                        )}
                      </td>
                    </>
                  )}
                  {["Completed", "Disapproved"].includes(orderStatus) && (
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                      <div className='flex items-center'>
                        <div className='h-10 w-10 flex-shrink-0'>
                          <Avatar
                            disabled={true}
                            alt={
                              [
                                (orderStatus === "Disapproved"
                                  ? item?.disapprover?.lastName
                                  : item?.approver?.lastName || ""
                                )?.trim(),
                                (orderStatus === "Disapproved"
                                  ? item?.disapprover?.firstName
                                  : item?.approver?.firstName || ""
                                )?.trim(),
                              ]
                                .join(" ")
                                .trim() || "N A"
                            }
                            src={
                              orderStatus === "Disapproved"
                                ? item?.disapprover?.profileImageUrl
                                : item?.approver?.profileImageUrl || ""
                            }
                          />
                        </div>
                        <div className='ml-4'>
                          <div className='text-gray-900 dark:text-gray-100'>
                            {[
                              (orderStatus === "Disapproved"
                                ? item?.disapprover?.lastName
                                : item?.approver?.lastName || ""
                              )?.trim(),
                              (orderStatus === "Disapproved"
                                ? item?.disapprover?.firstName
                                : item?.approver?.firstName || ""
                              )?.trim(),
                            ]
                              .join(" ")
                              .trim() || "N A"}
                          </div>
                          <div className='text-gray-500 dark:text-gray-400'>
                            {orderStatus === "Disapproved"
                              ? item?.disapprover?.phoneNumber
                              : item?.approver?.phoneNumber || "N/A"}
                          </div>
                        </div>
                      </div>
                    </td>
                  )}
                  {!orderStatus && (
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                      <span
                        className={classNames(
                          "bg-gray-100 text-gray-800",
                          item?.status === "Acknowledged"
                            ? "bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50"
                            : "",
                          item?.status === "Expired"
                            ? "bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50"
                            : "",
                          item?.status === "Failed"
                            ? "bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50"
                            : "",
                          item?.status === "Posted"
                            ? "bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50"
                            : "",
                          item?.status === "Pending"
                            ? "bg-[#eec96b5a] text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50"
                            : "",
                          item?.status === "Success"
                            ? "bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50"
                            : "",
                          item?.status === "Reversed"
                            ? "bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50"
                            : "",
                          !item?.status
                            ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                            : "",
                          "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                        )}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 10 10'
                          fill='currentColor'
                          className='w-1.5 h-1.5'
                        >
                          <circle
                            fillRule='evenodd'
                            cx='5'
                            cy='5'
                            r='5'
                            clipRule='evenodd'
                          />
                        </svg>
                        <span>{item?.status || "Unknown"}</span>
                      </span>
                    </td>
                  )}
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                    <div className='space-x-1'>
                      <ActionButton
                        action='view'
                        onClick={dispatchAction(item?._id, "view")}
                      />
                      {[
                        "Pending",
                        "AssignedToSupplier",
                        "AssignedToInstaller",
                        "InProgress",
                      ].includes(orderStatus) && (
                        <>
                          {item?.status === "Pending" ? (
                            <ActionButton
                              action='assign'
                              onClick={dispatchAction(item?._id, "assign")}
                            />
                          ) : (
                            <ActionButton
                              action='reassign'
                              onClick={dispatchAction(item?._id, "assign")}
                            />
                          )}
                        </>
                      )}
                      {[
                        "Pending",
                        "AssignedToSupplier",
                        "AssignedToInstaller",
                      ].includes(orderStatus) && (
                        <ActionButton
                          action='cancel'
                          onClick={dispatchAction(item?._id, "cancel")}
                        />
                      )}
                      {["Resolved"].includes(orderStatus) && (
                        <>
                          <ActionButton
                            action='approve'
                            onClick={dispatchAction(item?._id, "approve")}
                          />
                          <ActionButton
                            action='disapprove'
                            onClick={dispatchAction(item?._id, "disapprove")}
                          />
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              )}
            />
          )}
        </div>
      </div>

      <InitiateReplacementServiceOrderContainer
        open={modal === "initiate"}
        setOpen={(val: boolean) => setModal(val ? "initiate" : undefined)}
        refetch={refetch}
      />

      <BulkInitiateReplacementServiceOrderContainer
        open={modal === "create-bulk"}
        setOpen={(val: boolean) => setModal(val ? "create-bulk" : undefined)}
        refetch={refetch}
      />

      {!!searchParams.id?.length && (
        <>
          {searchParams.orderStatus !== "BulkInitiation" && (
            <AssignReplacementServiceOrderContainer
              open={modal === "assign"}
              setOpen={(val: boolean) => setModal(val ? "assign" : "view")}
              refetch={refetch}
            />
          )}
          {searchParams.orderStatus !== "BulkInitiation" && (
            <ApproveReplacementServiceOrderContainer
              open={modal === "approve"}
              setOpen={(val: boolean) => setModal(val ? "approve" : "view")}
              refetch={refetch}
            />
          )}
          {searchParams.orderStatus !== "BulkInitiation" && (
            <DisapproveReplacementServiceOrderContainer
              open={modal === "disapprove"}
              setOpen={(val: boolean) => setModal(val ? "disapprove" : "view")}
              refetch={refetch}
            />
          )}
          {searchParams.orderStatus !== "BulkInitiation" && (
            <CancelReplacementServiceOrderContainer
              open={modal === "cancel"}
              setOpen={(val: boolean) => setModal(val ? "cancel" : "view")}
              refetch={refetch}
            />
          )}
          {searchParams.orderStatus !== "BulkInitiation" && (
            <ViewReplacementServiceOrderContainer
              open={modal === "view"}
              setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
            />
          )}
          {searchParams.orderStatus === "BulkInitiation" && (
            <ViewReplacementServiceOrderInitiationContainer
              open={modal === "view-order-initiation"}
              setOpen={(val: boolean) =>
                setModal(val ? "view-order-initiation" : undefined)
              }
            />
          )}
        </>
      )}
    </main>
  );
};

export default ReplacementServiceOrdersPage;
