import { FC, useRef, useState } from "react";
import { classNames, wrapClick } from "utils";
import { useReactToPrint } from "react-to-print";
import {
  EnvelopeIcon,
  GlobeAltIcon,
  PhoneIcon,
} from "@heroicons/react/20/solid";
import {
  Loader,
  ReplacementServiceOrderPrepaymentView,
  ReplacementServiceOrderResolutionView,
  ReplacementServiceOrderView,
  ServiceOrderHistoryView,
} from "components";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";
import lodash from "lodash";
import { currentConfigVar } from "apollo/cache/config";
import { GET_REPLACEMENT_SERVICE_ORDER } from "pages/replacement-service-orders/view";

interface ReplacementOrderDetailsContainerProps {
  id: string;
}

const orderTabs = [
  {
    name: "Order Details",
    href: "OrderDetails",
    activeStatues: [
      "AssignedToSupplier",
      "AssignedToInstaller",
      "Resolved",
      "Completed",
      "InProgress",
    ],
  },
  {
    name: "Order History",
    href: "OrderHistory",
    activeStatues: [
      "AssignedToSupplier",
      "AssignedToInstaller",
      "Resolved",
      "Completed",
      "InProgress",
    ],
  },
  {
    name: "Prepayment Details",
    href: "PrepaymentDetails",
    activeStatues: [
      "AssignedToInstaller",
      "Resolved",
      "Completed",
      "InProgress",
    ],
  },
  {
    name: "Resolution Details",
    href: "ResolutionDetails",
    activeStatues: ["Resolved", "Completed"],
  },
];

const ReplacementOrderDetailsContainer: FC<
  ReplacementOrderDetailsContainerProps
> = ({ id }) => {
  const { data, loading } = useQuery(GET_REPLACEMENT_SERVICE_ORDER, {
    variables: {
      id: id,
    },
    fetchPolicy: "network-only",
  });
  const printRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: data?.replacement?.code,
    bodyClass: "w-[1000px]",
  });
  const [orderTab, setOrderTab] = useState("OrderDetails");

  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };

  const { pollInterval } = useReactiveVar(currentConfigVar);

  return (
    <div className='flex-1 flex flex-col overflow-hidden'>
      <div ref={printRef} className='flex-1 flex flex-col overflow-hidden'>
        <div className='hidden print:flex flex-row items-center space-x-4 justify-center py-6 border-b border-gray-300'>
          <div>
            <img
              alt='ECG Logo'
              src={require("assets/logo-new.png")}
              className='h-32 w-32'
            />
          </div>
          <div className='space-y-1 text-gray-900'>
            <h1 className='text-xl text-primary-600 font-bold'>
              ELECTRICITY COMPANY OF GHANA LIMITED
            </h1>
            <div className='flex items-center space-x-6'>
              <div className='flex items-center space-x-2'>
                <EnvelopeIcon className='h-4 w-4' />
                <span>P.O BOX GP 521, ACCRA</span>
              </div>
              <div className='flex items-center space-x-2'>
                <PhoneIcon className='h-4 w-4' />
                <span>0302-611-611</span>
              </div>
              <div className='flex items-center space-x-2'>
                <GlobeAltIcon className='h-4 w-4' />
                <span>ecg@ecggh.com</span>
              </div>
            </div>
            <div className='flex items-center space-x-6'>
              <div className='flex items-center space-x-2'>
                <span className='font-semibold'>VAT REG:</span>
                <span>714V000395</span>
              </div>
              <div className='flex items-center space-x-2'>
                <span className='font-semibold'>Website:</span>
                <span>https://www.ecggh.com/</span>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : data?.replacement?.status === "Completed" ? (
          <div className='p-6 overflow-y-auto flex-1'>
            <div className='block'>
              <div className='border-b border-gray-200 bg-white px-6'>
                <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
                  {orderTabs.map((_orderTab) => (
                    <button
                      key={_orderTab.name}
                      onClick={wrapClick(__setOrderTab(_orderTab.href))}
                      className={classNames(
                        orderTab === _orderTab.href
                          ? "border-primary-500 text-primary-600"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                        "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                      )}
                      aria-current={
                        orderTab === _orderTab.href ? "page" : undefined
                      }
                    >
                      {_orderTab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
            <div className='flex-1 w-full min-h-[65vh] max-h-[65vh] flex flex-col overflow-y-auto  sm:p-6'>
              {orderTab === "OrderDetails" && (
                <ReplacementServiceOrderView
                  replacementServiceOrder={data?.replacementServiceOrder}
                />
              )}
              {orderTab === "OrderHistory" && (
                <ServiceOrderHistoryView
                  history={data?.replacementServiceOrder?.history}
                />
              )}
              {orderTab === "ResolutionDetails" && (
                <ReplacementServiceOrderResolutionView
                  resolution={{
                    ...data?.replacementServiceOrder?.resolution,
                    installedMeter:
                      data?.replacementServiceOrder?.installedMeter,
                  }}
                  result={data?.replacementServiceOrder?.result}
                />
              )}
              {orderTab === "PrepaymentDetails" && (
                <ReplacementServiceOrderPrepaymentView
                  serviceOrder={data?.replacementServiceOrder}
                />
              )}
            </div>
          </div>
        ) : (
          <div className='space-y-6 divide-y divide-gray-200 p-6 overflow-y-auto flex-1'>
            <div className='p-4'>
              <div className='flex justify-between items-center cursor-pointer'>
                <div>
                  <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
                    Replacement Service Order Information
                  </h3>
                  <p className='mt-1 text-xs text-gray-500'>
                    Details about replacement service order
                  </p>
                </div>
                <div />
              </div>
              <div className='mt-6'>
                <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
                  <div className=''>
                    <span className='text-xs font-light'>
                      Order Information
                    </span>
                    <div className='grid grid-cols-3 gap-6 mt-2'>
                      <div className=''>
                        <span className='block text-sm font-light text-gray-700'>
                          Code
                        </span>
                        <div className='mt-1 block w-full sm:text-sm'>
                          {data?.replacement?.code || "N/A"}
                        </div>
                      </div>
                      <div className=''>
                        <span className='block text-sm font-light text-gray-700'>
                          Priority
                        </span>
                        <div className='mt-1 block w-full sm:text-sm'>
                          {data?.replacement?.priority || "N/A"}
                        </div>
                      </div>
                      <div>
                        <span className='block text-sm font-light text-gray-700'>
                          Initiated At
                        </span>
                        <div className='mt-1 block w-full sm:text-sm'>
                          {data?.replacement?.createdAt
                            ? moment(data?.replacement?.createdAt).format(
                                "DD/MM/YYYY hh:mm A"
                              )
                            : "N/A"}
                        </div>
                      </div>
                      <div>
                        <span className='block text-sm font-light text-gray-700'>
                          Estimated Resolution Date
                        </span>
                        <div className='mt-1 block w-full sm:text-sm'>
                          {data?.replacement?.estimatedResolutionDate
                            ? moment(
                                data?.replacement?.estimatedResolutionDate
                              ).format("DD/MM/YYYY")
                            : "N/A"}
                        </div>
                      </div>
                      <div>
                        <span className='block text-sm font-light text-gray-700'>
                          Status
                        </span>
                        <div className='mt-1 block w-full sm:text-sm'>
                          {lodash.startCase(data?.replacement?.status) || "N/A"}
                        </div>
                      </div>
                      <div>
                        <span className='block text-sm font-light text-gray-700'>
                          Last Updated At
                        </span>
                        <div className='mt-1 block w-full sm:text-sm'>
                          {data?.replacement?.updatedAt
                            ? moment(data?.replacement?.updatedAt).format(
                                "DD/MM/YYYY hh:mm A"
                              )
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReplacementOrderDetailsContainer;
