import moment from 'moment';
import * as Yup from 'yup';

export const AssigneeInfoFormSchema = Yup.object().shape({
  assignee: Yup.object().required()
})


export const ExtraInfoFormSchema = Yup.object().shape({
  estimatedResolutionDate: Yup.date().min(moment().startOf("day").toDate()).required(),
  priority: Yup.string().oneOf(["Low", "Medium", "High", "Critical"]).required(),
  notes: Yup.string(),
})

export const AssignServiceOrderFormSchema = Yup.object().shape({
  assigneeInfo: AssigneeInfoFormSchema,
  extraInfo: ExtraInfoFormSchema
})