import { gql, useQuery } from "@apollo/client";
import {
  Modal,

  ShepRegularizationCustomerView,
  ShepRegularizationServiceView,
} from "components";
import { useEffect, useMemo, useState } from "react";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, classNames } from "utils";

const GET_SHEP_REGULARIZATION = gql`
  query GetShepRegularization($id: ID!) {
    shepRegularization: getShepRegularization(id: $id) {
      _id
      accountCode
      code
      createdAt
      customerCode
      customerData {
        organization {
          name
        }
        representative {
          fullName
          profileImageUrl
          phoneNumber
          emailAddress
          ghanaCardNumber
        }
      }
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      service {
        contractedDemand
        energyCertificateDocumentUrl
        energyCertificateNumber
        initialReadingDate
        installationDate
        initialReadingValue
        meterBrand {
          code
          name
        }
        meterImageUrl
        meterModel {
          code
          name
        }
        meterNumber
        serviceClass
        tariffClass {
          code
          name
        }
      }
    }
  }
`;

export default function ViewShepRegularizationContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [tab, setTab] = useState("Customer");
  const { data, loading } = useQuery(GET_SHEP_REGULARIZATION, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const tabs = useMemo(
    () => [
      { name: "Customer Details", href: "Customer" },
      { name: "Service Details", href: "Service" },
    ],
    [data?.reading]
  );

  const __setTab = (tab: string) => () => {
    setTab(tab);
  };

  useEffect(() => {
    setTab("Customer");
  }, [searchParams.id]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      hidePadding={true}
      title='SHEP Capture Information'
      description='Details of shep regularization are shown below'
      size='4xl'
    >
      <>
        <div className='block'>
          <div className='border-b border-gray-200 bg-white px-6'>
            <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
              {tabs.map((_tab) => (
                <button
                  key={_tab.name}
                  onClick={wrapClick(__setTab(_tab.href))}
                  className={classNames(
                    tab === _tab.href
                      ? "border-primary-500 text-primary-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                    "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={tab === _tab.href ? "page" : undefined}
                >
                  {_tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
        <div className='flex-1 w-full max-h-[65vh] overflow-y-auto  sm:p-6'>
          {tab === "Customer" && <ShepRegularizationCustomerView shepRegularization={data?.shepRegularization} />}
          {tab === "Service" && <ShepRegularizationServiceView shepRegularization={data?.shepRegularization} />}
         
        </div>
      </>
    </Modal>
  );
}
