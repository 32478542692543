import { gql, useQuery } from "@apollo/client";
import moment from "moment";

export const GET_INSPECTION_SERVICE_ORDERS = gql`
  query GetInspectionServiceOrders(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $status: InspectionServiceOrderStatus
    $category: InspectionServiceOrderCategory
    $priority: InspectionServiceOrderPriority
    $assigner: ID
    $approver: ID
    $assignee: ID
    $fromDate: Date
    $toDate: Date
    $dateField: String
    $serviceClasses: [ServiceClass]
  ) {
    rows: getInspectionServiceOrders(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
      category: $category
      priority: $priority
      assigner: $assigner
      approver: $approver
      assignee: $assignee
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      serviceClasses: $serviceClasses
    ) {
      _id
      code
      serviceRequestCode
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      contactPerson {
        fullName
        phoneNumber
      }
      location {
        address
        community
      }
      assigner {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      approver {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      disapprover {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      assignee {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      category
      status
      priority
      estimatedResolutionDate
      assignedAt
      startedAt
      resolvedAt
      completedAt
      rejectedAt
      disapprovedAt
      createdAt
      updatedAt
      result
    }
    count: getInspectionServiceOrdersCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
      category: $category
      priority: $priority
      assigner: $assigner
      approver: $approver
      assignee: $assignee
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      serviceClasses: $serviceClasses

    )
  }
`;

export const GET_INSPECTION_SERVICE_ORDER_SUMMARY = gql`
  query GetInspectionServiceOrderSummary(
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $category: InspectionServiceOrderCategory
    $priority: InspectionServiceOrderPriority
    $assigner: ID
    $approver: ID
    $assignee: ID
    $fromDate: Date
    $toDate: Date
    $dateField: String
    $serviceClasses: [ServiceClass]

  ) {
    summary: getInspectionServiceOrderSummary(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      category: $category
      priority: $priority
      assigner: $assigner
      approver: $approver
      assignee: $assignee
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      serviceClasses: $serviceClasses
    ) {
      Pending
      Assigned
      Resolved
      InProgress
      Completed
      Disapproved
    }
  }
`;
