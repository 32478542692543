import { useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { AssignInstallationServiceOrderForm, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_SERVICE_ORDER } from "./view";

const ASSIGN_SERVICE_ORDER = gql`
  mutation AssignInstallationServiceOrder(
    $id: ID!
    $assignee: ID!
    $assigneeType: InstallationServiceOrderAssigneeType!
    $installationMeter: ID
    $priority: InstallationServiceOrderPriority!
    $estimatedResolutionDate: Date!
    $notes: String
  ) {
    assignInstallationServiceOrder(
      id: $id
      assignee: $assignee
      assigneeType: $assigneeType
      installationMeter: $installationMeter
      priority: $priority
      estimatedResolutionDate: $estimatedResolutionDate
      notes: $notes
    ) {
      _id
    }
  }
`;

export default function AssignInstallationServiceOrderContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_SERVICE_ORDER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [assignInstallationServiceOrder] = useMutation(ASSIGN_SERVICE_ORDER);

  const form = useFormik({
    initialValues: {
      assigneeInfo: {
        isCompliant: "",
        assigneeType: "FieldAgent" as
          | "FieldAgent"
          | "FarmingOutContractor"
          | "MeterManufacturingContractor",
        assignee: null as any,
      },
      meterInfo: {
        meter: null as any,
      },
      extraInfo: {
        estimatedResolutionDate: "",
        priority: "Low",
        notes: "",
      },
    },
    onSubmit: async (values) => {
      await assignInstallationServiceOrder({
        variables: {
          id: searchParams.id,
          assignee: values.assigneeInfo?.assignee?._id,
          assigneeType: values.assigneeInfo.assigneeType,
          installationMeter: values.meterInfo?.meter?._id,
          ...values.extraInfo,
        },
      }).then(({ data }) => {
        if (data.assignInstallationServiceOrder._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Service Order Assigned Successfully",
            })
          );
          form.resetForm();
          refetch?.();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not assign Service Order",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      assigneeInfo: {
        isCompliant: data?.installationServiceOrder?.assigneeType || "",
        assigneeType:
          data?.installationServiceOrder?.assigneeType || "FieldAgent",
        assignee: data?.installationServiceOrder?.assignee || (null as any),
      },
      meterInfo: {
        meter:
          data?.installationServiceOrder?.installationMeter || (null as any),
      },
      extraInfo: {
        estimatedResolutionDate:
          data?.installationServiceOrder?.estimatedResolutionDate || "",
        priority: data?.installationServiceOrder?.priority || "Low",
        notes: "",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.installationServiceOrder, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      hideActions={true}
      loading={loading}
      title="Assign Service Order"
      size="5xl"
      description="Provide the details to assign service order"
    >
      {data?.installationServiceOrder?._id && (
        <AssignInstallationServiceOrderForm form={form} />
      )}
    </Modal>
  );
}
