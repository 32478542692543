import { DistrictCodes } from "apollo/data";
import lodash from "lodash";
import moment from "moment";
import * as Yup from "yup";
export const ServiceTypes = ["Postpaid", "Prepaid"] as const;
export type ServiceType = (typeof ServiceTypes)[number];
export const ServiceClasses = [
  "Residential",
  "NonResidential",
  "Industrial",
] as const;
export type ServiceClass = (typeof ServiceClasses)[number];

export interface IResolveInstallationServiceOrderFormSchema {
  serviceInfo: {
    poleNumber: string;
    meterNumber: string;
    modelMeta?: {
      phase: string;
    };
    code?: string;
    meterModel: {
      _id: string;
      code: string;
      make: string;
      model: string;
      phase: number;
      digits: number;
    } | null;
  };

  extraInfo: {
    readingDate: string;
    readingValue: number;
    notes: string;
    installationType: {
      _id: string;
      code: string;
      name: string;
      materials: {
        _id: string;
        code: string;
        name: string;
        quantityUnit: string;
      }[];
    } | null;
    appliances: {
      appliance: {
        _id: string;
        code: string;
        name: string;
        wattage: number;
      };
      quantity: number;
    }[];
    materials: {
      material: {
        _id: string;
        code: string;
        name: string;
        quantityUnit: string;
      };
      quantity: number;
    }[];
  };
}

export const ServiceInfoFormSchema = Yup.object().shape({
  poleNumber: Yup.string().notRequired(),
  // meterNumber: Yup.string().required(),
  // meterModel: Yup.object().shape({
  //   _id: Yup.string().required(),
  //   code: Yup.string().required(),
  //   model: Yup.string().required(),
  //   make: Yup.string().required(),
  //   digits: Yup.number().required(),
  // }).required(),
});

export const ExtraInfoFormSchema = Yup.object().shape({
  readingDate: Yup.date().max(moment().endOf("day").toDate()),
  readingValue: Yup.number().min(0),
  notes: Yup.string(),
});

export const ResolveInstallationServiceOrderFormSchema = Yup.object().shape({
  serviceInfo: ServiceInfoFormSchema.required(),
  extraInfo: ExtraInfoFormSchema.required(),
});
