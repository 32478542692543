import { withPermissions, wrapClick } from "utils";
import { gql, useQuery } from "@apollo/client";
import { ContractorView, Modal } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";

const GET_CONTRACTOR = gql`
  query GetContractor($id: ID!) {
    contractor: getMeterContractor(id: $id) {
      _id
      code
      name
      address
      ghanaPostAddress
      contactPerson {
        emailAddress
        phoneNumber
        fullName
      }
      createdAt
      updatedAt
      districts {
        _id
        meta {
          blocksCount
          customersCount
          itinerariesCount
          plotsCount
          propertiesCount
          roundsCount
          usersCount
        }
        code
        name
      }
    }
  }
`;

export default function ViewContractorContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_CONTRACTOR, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update",
      }),
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Contractor Information"
      description="Details of contractor are shown below"
    >
      <ContractorView contractor={data?.contractor} />
    </Modal>
  );
}
