export default [
  {
    value: "Postpaid",
    title: "Postpaid",
    description: "Last message sent an hour ago",
  },
  {
    value: "Prepaid",
    title: "Prepaid",
    description: "Last message sent 2 weeks ago",
  },
] as const;
