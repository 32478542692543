import { gql, useQuery } from "@apollo/client";


const GET_CONTRACTOR_USERS = gql`
  query GetContractorUsers(
    $page: Int
    $pageSize: Int
    $search: String
    $searchFields: [String!]
    $sort: String
    $contractor: ID
  ) {
    rows:getContractorUsers(
      page: $page
      pageSize: $pageSize
      search: $search
      searchFields: $searchFields
      sort: $sort
      contractor: $contractor
    ) {
        _id
        code
        username
        lastName
        firstName
        gender
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        createdAt
        updatedAt
    }
  }
`;


const GET_CONTRACTOR_USERS_COUNT = gql`
query GetContractorsCount($search: String, $searchFields: [String!]) {
  count: getContractorUsersCount(search: $search, searchFields: $searchFields)
}

`

export const useContractorUsers = (variables: any) => {
  const { data, ...rest } = useQuery(GET_CONTRACTOR_USERS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
  const { data: countData } = useQuery(GET_CONTRACTOR_USERS_COUNT, {
    variables,
  });
  const contractorUsers = data?.rows || [];
  const count = countData?.count || 0;
  return { contractorUsers, count, ...rest };
}