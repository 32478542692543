import { UserPlusIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import { FC } from "react";
import { wrapClick } from "utils";
import { MeterInfoFormSchema } from "./schema";
import { MeterPicker } from "containers";
import lodash from "lodash";

interface MeterInfoFormProps {
  handleNext: (values: any) => void;
  handlePrevious: () => void;
  initialValues: any;
  values: any;
  handleCancel: () => void;
}

const MeterInfoForm: FC<MeterInfoFormProps> = ({
  initialValues,
  handleNext,
  handlePrevious,
  handleCancel,
  values: parentValues,
}) => {
  const form = useFormik({
    initialValues,
    validationSchema: MeterInfoFormSchema,
    onSubmit: (values) => {
      handleNext({
        ...values,
      });
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        <div className="">
          {/* <span className="text-xs font-light">Select Meter</span> */}
          <div className="grid grid-cols-6 gap-6 mt-2">
            <div className="col-span-6 sm:col-span-2">
              <MeterPicker
                id="meter"
                label="Meter Number"
                placeholder="e.g. MR12345678"
                parentValues={parentValues}
                {...form}
              />
            </div>
          </div>
        </div>
        <div className="pt-6">
          <span className="text-xs font-light">Selected Meter</span>
          {form?.values?.meter ? (
            <div className="mt-2 border border-gray-300 border-dashed bg-gray-100 divide-y divide-dashed divide-gray-300 rounded-md">
              <div className="p-4">
                <span className="text-xs font-light">
                  Meter Model Information
                </span>
                <div className="grid grid-cols-4 gap-x-4 gap-y-6 mt-2">
                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Meter Number
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.meter?.code || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Meter Brand
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.meter?.model?.brandCode || "N/A"} -{" "}
                      {form?.values?.meter?.model?.brandName || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Meter Model
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.meter?.model?.code || "N/A"} -{" "}
                      {form?.values?.meter?.model?.name || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Meter Type
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.meter?.modelType || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-4">
                <span className="text-xs font-light">
                  Meter Model Specification Information
                </span>
                <div className="grid grid-cols-4 gap-x-4 gap-y-6 mt-2">
                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Meter Type
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.meter?.model?.type || "N/A"}
                    </div>
                  </div>

                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Communication Type
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {lodash.startCase(
                        form?.values?.meter?.model?.communicationType
                      ) || "N/A"}
                    </div>
                  </div>

                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Meter Compliance
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.meter?.model?.compliance || "N/A"}
                    </div>
                  </div>

                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Meter Phase
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.meter?.model?.phase || "N/A"}
                    </div>
                  </div>

                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Meter Mechanism
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.meter?.model?.mechanism || "N/A"}
                    </div>
                  </div>

                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Meter Digits
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.meter?.model?.digits || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Voltage
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.meter?.model?.voltage || "N/A"}V
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Current Rating
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.meter?.model?.currentRating || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex mt-2 w-full items-center justify-center">
              <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 py-24 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                <UserPlusIcon
                  className="mx-auto h-10 w-10 text-gray-400"
                  stroke="currentColor"
                  strokeWidth={1}
                  aria-hidden="true"
                />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No meter assigned
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Get started by selecting a meter above.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default MeterInfoForm;
