import { useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, UpdateCustomerForm } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_CUSTOMER } from "./view";
import { wrapClick } from "utils";
import * as Yup from "yup";

const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer(
    $id: ID!
    $representative: UpdateCustomerRepresentativeInput
    $organization: UpdateCustomerOrganizationInput
  ) {
    updateCustomer(
      id: $id
      representative: $representative
      organization: $organization
    ) {
      _id
    }
  }
`;

export default function UpdateCustomerContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_CUSTOMER, {
    variables: {
      getServicePointId: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    refetchQueries: ["GetServicePoints"],
  });

  const form = useFormik<{
    phoneNumber: string;
    fullName: String;
    ghanaCardNumber: string;
  }>({
    initialValues: {
      phoneNumber: "",
      fullName: "",
      ghanaCardNumber: "",
    },
    validationSchema: Yup.object().shape({
      ghanaCardNumber: Yup.string().required("Digital Address is Required"),
      phoneNumber: Yup.string().required("Phone Number is Required"),
      fullName: Yup.string().required("Full Name Letter is Required"),
    }),
    onSubmit: async (values) => {
      await updateCustomer({
        variables: {
          ...values,
          id: data?.customer?.customer?._id,
          representative: {
            fullName: values.fullName,
            phoneNumber: values.phoneNumber,
            hasGhanaCard: true,
            ghanaCardNumber: values.ghanaCardNumber,
          },
        },
      }).then(({ data }) => {
        if (data.updateCustomer._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Customer Edited Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create Customer",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (data?.customer?.customer) {
      form.setValues({
        fullName:
          data?.customer?.customer?.customerType === "Individual"
            ? data?.customer?.customer?.representative?.fullName
            : data?.customer?.customer?.organization?.name || "N/A",
        phoneNumber:
          data?.customer?.customer?.customerType === "Individual"
            ? data?.customer?.customer?.representative?.phoneNumber || "N/A"
            : data?.customer?.customer?.organization?.phoneNumber || "N/A",
        ghanaCardNumber:
          data?.customer?.customer?.customerType === "Individual"
            ? data?.customer?.customer?.representative?.ghanaCardNumber || "N/A"
            : data?.customer?.customer?.organization?.ghanaCardNumber || "N/A",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.customer, searchParams.id]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      // hideActions={true}
      loading={loading}
      size='4xl'
      title='Edit Customer Information'
      description='Provide the details to update customer'
      renderActions={() => (
        <>
          {/* {withPermissions(["*:*", "customers:*", "customers:update"])( */}
          <button
            type='button'
            disabled={form.isSubmitting || !form.isValid}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {form.isSubmitting ? "Editing ..." : "Edit"}
          </button>
          {/* )} */}
        </>
      )}
    >
      {data?.customer?._id && (
        <UpdateCustomerForm form={form} customer={data?.customer} />
      )}
    </Modal>
  );
}
