import { Outlet, Route } from "react-location";
import {
  DashboardPage,
  ForgotPasswordPage,
  SignInPage,
  AuthPage,
  InspectionServiceOrdersPage,
  InstallationServiceOrdersPage,
  InvestigationServiceOrdersPage,
  ReplacementServiceOrdersPage,
  TerminationServiceOrdersPage,
  ContractorsPage,
  ContractorUsersPage,
  MeterContractorsPage,
  MeterContractorUsersPage,
  ReadingAnomaliesPage,
  ServiceRegularizationsPage,
  ReplacementReasonsPage,
  RegularizationInstallationServiceOrdersPage,
  BacklogInstallationServiceOrdersPage,
  RegularizationInspectionServiceOrdersPage,
  ShepRegularizationsPage,
  FlatRateCustomersPage,
  ComplaintPage,
  TemplatesPage,
  PrepayemtErrorsPage,
} from "pages";
import {
  BoltSlashIcon,
  BriefcaseIcon,
  CalculatorIcon,
  ChartBarSquareIcon,
  CogIcon,
  ExclamationCircleIcon,
  LifebuoyIcon,
  UserCircleIcon,
  UsersIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/outline";
import { UserPermission } from "apollo/cache/auth";
import CalendarLayout from "layouts/calendar-wrapper";
import CustomersPage from "pages/customers";
import ComplaintsPage from "pages/complaints";

export type RouteProps = Omit<Route, "children"> & {
  withPermissions?: UserPermission[];
  navigation?: boolean;
  sidebar?: { label: string; icon: any };
  children?: RouteProps[];
};

const routes: RouteProps[] = [
  {
    path: "/",
    element: <CalendarLayout />,
    sidebar: {
      label: "Dashboard",
      icon: ChartBarSquareIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Dashboard",
      section: "Statistics",
    },
    children: [
      {
        id: "all",
        path: "/",
        element: <DashboardPage />,
        meta: {
          layout: "App",
          section: "General",
        },
      },
    ],
  },
  {
    path: "/customers",
    element: <CustomersPage />,
    sidebar: {
      label: "Service Points",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Service Points",
      section: "Customer Management",
    },
    withPermissions: ["*:*", "customers:*", "customers:manage"],
  },
  {
    path: "inspections",
    element: <InspectionServiceOrdersPage />,
    sidebar: {
      label: "Inspections",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Inspection Service Orders",
      section: "Service Orders",
    },
    withPermissions: [
      "*:*",
      "inspection-service-orders:*",
      "inspection-service-orders:manage",
    ],
  },
  {
    path: "regularization-inspections",
    element: <RegularizationInspectionServiceOrdersPage />,
    sidebar: {
      label: "Regularization Inspections",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Regularization Inspection Service Orders",
      section: "Service Orders",
    },
    withPermissions: [
      "*:*",
      "inspection-service-orders:*",
      "inspection-service-orders:manage",
    ],
  },
  {
    path: "installations",
    element: <InstallationServiceOrdersPage />,
    sidebar: {
      label: "Installations",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Installation Service Orders",
      section: "Service Orders",
    },
    withPermissions: [
      "*:*",
      "installation-service-orders:*",
      "installation-service-orders:manage",
    ],
  },

  {
    path: "regularization-installations",
    element: <RegularizationInstallationServiceOrdersPage />,
    sidebar: {
      label: "Regularization Installations",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Regularization Installation Service Orders",
      section: "Service Orders",
    },
    withPermissions: [
      "*:*",
      "installation-service-orders:*",
      "installation-service-orders:manage",
    ],
  },
  {
    path: "backlog-installations",
    element: <BacklogInstallationServiceOrdersPage />,
    sidebar: {
      label: "Backlog Installations",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Backlog Installation Service Orders",
      section: "Service Orders",
    },
    withPermissions: [
      "*:*",
      "installation-service-orders:*",
      "installation-service-orders:manage",
    ],
  },
  {
    path: "replacements",
    element: <ReplacementServiceOrdersPage />,
    sidebar: {
      label: "Replacements",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Replacement Service Orders",
      section: "Service Orders",
    },
    withPermissions: [
      "*:*",
      "replacement-service-orders:*",
      "replacement-service-orders:manage",
    ],
  },
  {
    path: "investigations",
    element: <InvestigationServiceOrdersPage />,
    sidebar: {
      label: "Investigations",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Investigation Service Orders",
      section: "Service Orders",
    },
    withPermissions: [
      "*:*",
      "investigation-service-orders:*",
      "investigation-service-orders:manage",
    ],
  },
  {
    path: "meter-removals",
    element: <TerminationServiceOrdersPage />,
    sidebar: {
      label: "Meter Removals",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Meter Removal Orders",
      section: "Service Orders",
    },

    withPermissions: [
      "*:*",
      "termination-service-orders:*",
      "termination-service-orders:manage",
    ],
  },

  {
    path: "reading-anomalies",
    element: <ReadingAnomaliesPage />,
    sidebar: {
      label: "Reading Anomalies",
      icon: ExclamationCircleIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Reading Anomalies",
      section: "Faults And Complaints",
    },
    withPermissions: [],
  },
  {
    path: "complaints",
    element: <Outlet />,
    sidebar: {
      label: "Tickets",
      icon: BoltSlashIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Tickets",
      section: "Faults And Complaints",
    },
    children: [
      {
        path: "/",
        element: <ComplaintsPage />,
        meta: {
          layout: "App",
          section: "Faults And Complaints",
        },
      },

      {
        path: ":complaint",
        element: <ComplaintPage />,
        meta: {
          layout: "App",
          breadcrumb: () => "Ticket",
          section: "Faults And Complaints",
        },
      },
    ],
    //TODO: Verify and enable permisions
    // withPermissions: [
    //   "*:*",
    //   "service-regularizations:*",
    //   "service-regularizations:manage",
    // ],
  },
  {
    path: "replacement-reasons",
    element: <ReplacementReasonsPage />,
    sidebar: {
      label: "Replacement Reasons",
      icon: CogIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Replacement Reasons",
      section: "Configurations",
    },
    withPermissions: [],
  },
  {
    path: "prepayment-errors",
    element: <PrepayemtErrorsPage />,
    sidebar: {
      label: "Prepayment Errors",
      icon: CogIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Prepayment Errors",
      section: "Configurations",
    },
    withPermissions: [],
  },
  {
    path: "templates",
    element: <TemplatesPage />,
    sidebar: {
      label: "Templates",
      icon: ClipboardDocumentIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Templates",
      section: "Configurations",
    },
    withPermissions: [],
  },

  {
    path: "contractors",
    element: <Outlet />,
    sidebar: {
      label: "Farming Out Contractors",
      icon: BriefcaseIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Farming Out Contractors",
      section: "Configurations",
    },
    withPermissions: ["*:*", "contractors:*", "contractors:manage"],
    children: [
      {
        path: "/",
        element: <ContractorsPage />,
        meta: {
          layout: "App",
          // breadcrumb: () => "Contractors",
          // section: "Configurations",
        },
        withPermissions: ["*:*", "contractors:*", "contractors:manage"],
      },
      {
        path: ":contractor",
        element: <ContractorUsersPage />,
        meta: {
          layout: "App",
          breadcrumb: () => "Farming Out Contractors Agents",
          section: "Configurations",
        },
        withPermissions: ["*:*", "contractors:*", "contractors:manage"],
      },
    ],
  },
  {
    path: "meter-contractors",
    element: <Outlet />,
    sidebar: {
      label: "Meter Contractors",
      icon: BriefcaseIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Meter Contractors",
      section: "Users & Permissions",
    },
    withPermissions: ["*:*", "meter-contractors:*", "meter-contractors:manage"],
    children: [
      {
        path: "/",
        element: <MeterContractorsPage />,
        meta: {
          layout: "App",
          // breadcrumb: () => "Contractors",
          section: "Users & Permissions",
        },
        withPermissions: [
          "*:*",
          "meter-contractors:*",
          "meter-contractors:manage",
        ],
      },
      {
        path: ":meterContractor",
        element: <MeterContractorUsersPage />,
        meta: {
          layout: "App",
          breadcrumb: () => "Meter Contractor's Users",
          section: "Users & Permissions",
        },
        withPermissions: [
          "*:*",
          "meter-contractors:*",
          "meter-contractors:manage",
        ],
      },
    ],
  },
  {
    path: "shep-regularizations",
    element: <CalendarLayout />,
    sidebar: {
      label: "SHEP Captures",
      icon: LifebuoyIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "SHEP Captures",
      section: "Customer Management",
    },
    children: [
      {
        path: "/",
        element: <ShepRegularizationsPage />,
        meta: {
          layout: "App",
          section: "Customer Management",
        },
      },
    ],
    withPermissions: ["*:*", "customers:*", "customers:manage"],
  },
  {
    path: "service-regularizations",
    element: <CalendarLayout />,
    sidebar: {
      label: "Regularizations",
      icon: UserCircleIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Regularizations",
      section: "Customer Management",
    },
    children: [
      {
        path: "/",
        element: <ServiceRegularizationsPage />,
        meta: {
          layout: "App",
          section: "Faults And Complaints",
        },
      },
      // {
      //   path: ":serviceRegularization",
      //   element: <ServiceRegularizationPage />,
      //   meta: {
      //     layout: "App",
      //     breadcrumb: () => "Service Regularization",
      //     section: "Faults And Complaints",
      //   },
      // },
    ],
    //TODO: Verify and enable permisions
    withPermissions: ["*:*", "customers:*", "customers:manage"],
  },
  {
    path: "flat-rate-customers",
    element: <FlatRateCustomersPage />,
    sidebar: {
      label: "Flat Rate Customers",
      icon: CalculatorIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Flat Rate Customers",
      section: "Customer Management",
    },
    withPermissions: ["*:*", "customers:*", "customers:manage"],
  },
  {
    path: "signin",
    element: <SignInPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Signin",
    },
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "ForgotPassword",
    },
  },
  {
    path: "auth",
    element: <AuthPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Auth",
    },
  },
];

export default routes;
