import { StepWizard } from "components";
import { FormikProps } from "formik";
import { FC, useMemo, useState } from "react";
import SummaryPage from "./summary";
import formSteps from "./form-steps";

interface InitiateServiceOrderFromAnomalyFormProps {
  form: FormikProps<{
    // customerInfo: {
    //   existingServicePoint: any;
    //   caimsServicePoint: any;
    // };
    assigneeInfo: {
      isCompliant: string;
      assigneeType:
      | "FieldAgent"
      | "FarmingOutContractor"
      | "MeterManufacturingContractor";
      assignee: any;
    };
    meterInfo: {
      meter: any;
    };
    extraInfo: {
      type: string;
      estimatedResolutionDate: string;
      replacementReason: string;
      priority: string;
      notes: string;
      debtAmount: number;
      creditAmount: number;
      oldMeterNumber: string;
      oldMeterNumberTemp: string;
      oldMeterPhase: string;
      oldMeterPhaseTemp: string;
      customerPhone: string;
      ghanaPostAddress: string;
    };
  }>;
}

const InitiateServiceOrderFromAnomalyForm: FC<InitiateServiceOrderFromAnomalyFormProps> = ({
  form,
}) => {
  const filteredFormSteps = useMemo(() => {
    return formSteps
      .filter((_step) => Object.keys(form.values).includes(_step.accessor))
      .filter((_step) =>
        form.values.assigneeInfo.assigneeType ===
          "MeterManufacturingContractor" && _step.accessor === "meterInfo"
          ? false
          : true
      )
      .map((_step, href) => ({ ..._step, href }));
  }, [form.values]);

  const steps = useMemo(
    () =>
      [
        ...filteredFormSteps,
        { name: "Preview", FormComponent: SummaryPage, accessor: "summary" },
      ].map((rawStep, href) => ({ ...rawStep, href })),
    [filteredFormSteps]
  );

  const [step, setStep] = useState(0);
  const [lastStep, setLastStep] = useState(0);

  const onCancel = () => {
    setStep(0);
    setLastStep(0);
    form.resetForm();
  };

  const handleNextStep = (_step: string) => (_values: any) => {
    // check if last step
    form.setFieldValue(_step, _values);
    setStep(step + 1);
    setLastStep(step === lastStep ? lastStep + 1 : lastStep);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleCancel = () => {
    form.resetForm();
    onCancel();
  };

  const renderForm = () => {
    const { FormComponent, accessor } = steps[step || 0];
    return (
      <FormComponent
        handleNext={handleNextStep(accessor)}
        initialValues={(form.values as any)[accessor]}
        values={form.values}
        // parentForm={form}
        handlePrevious={handlePreviousStep}
        handleCancel={handleCancel}
        handleStep={setStep}
        handleSubmit={form.handleSubmit}
        submitLoading={form.isSubmitting}
        steps={filteredFormSteps}
      />
    );
  };

  return (
    <div className='bg-white dark:bg-gray-900 shadow-xl flex flex-col flex-1 overflow-hidden relative min-h-[75vh]'>
      <div className='px-6 pt-6'>
        <StepWizard
          steps={steps}
          step={step}
          setStep={setStep}
          lastStep={lastStep}
        />
      </div>
      <div className='flex flex-1 overflow-hidden'>{renderForm()}</div>
    </div>
  );
};

export default InitiateServiceOrderFromAnomalyForm;
