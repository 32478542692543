import {
  EnvelopeIcon,
  GlobeAltIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import { FC, useState } from "react";
import { classNames, wrapClick, wrapImage } from "utils";
import parse from "html-react-parser";
import { Avatar } from "components";
import { useMutation, useReactiveVar } from "@apollo/client";
import { currentUserVar } from "apollo/cache/auth";
import { START_COMPLAINT_RESOLUTION } from "pages/complaints/view";
import toast from "react-hot-toast";
import { LocationGenerics } from "router/location";
import { useMatch, useSearch } from "react-location";

interface TicketGeneratedDetailsProps {
  complaint: any;
  refetch: () => void;
}

const orderTabs = [
  {
    name: "Customer Details",
    href: "CustomerDetails",
    activeStatus: ["Pending", "InProgress", "Closed", "Open", "Escalated"],
    showWhen: ["Pending", "InProgress", "Closed", "Open", "Escalated"],
  },
  {
    name: "Complaint Details",
    href: "OrderHistory",
    activeStatus: ["Pending", "InProgress", "Closed", "Open", "Escalated"],
    showWhen: ["Pending", "InProgress", "Closed", "Open", "Escalated"],
  },
  {
    name: "Extra Details",
    href: "ExtraDetails",
    activeStatus: ["Pending", "InProgress", "Closed", "Open", "Escalated"],
    showWhen: ["Pending", "InProgress", "Closed", "Open", "Escalated"],
  },
];

const TicketGeneratedDetailsContainer: FC<TicketGeneratedDetailsProps> = ({
  complaint,
  refetch,
}) => {
  const [orderTab, setOrderTab] = useState("OrderHistory");
  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };
  const currentUser = useReactiveVar(currentUserVar);
  const { params } = useMatch<LocationGenerics>();

  const [startResolution, { loading: starting }] = useMutation(
    START_COMPLAINT_RESOLUTION,
    {
      onCompleted: () => {
        refetch?.();
        // setOpen(false);
        toast(
          JSON.stringify({
            type: "success",
            title: "Ticket Resolution Started Successfully",
          })
        );
      },
    }
  );

  return (
    <div className='flex-1 flex flex-col overflow-hidden'>
      <div className='flex-1 flex flex-col overflow-hidden'>
        <div className='hidden print:flex flex-row items-center space-x-4 justify-center py-6 border-b border-gray-300'>
          <div>
            <img
              alt='ECG Logo'
              src={require("assets/logo-new.png")}
              className='h-32 w-32'
            />
          </div>
          <div className='space-y-1 text-gray-900'>
            <h1 className='text-xl text-primary-600 font-bold'>
              ELECTRICITY COMPANY OF GHANA LIMITED
            </h1>
            <div className='flex items-center space-x-6'>
              <div className='flex items-center space-x-2'>
                <EnvelopeIcon className='h-4 w-4' />
                <span>P.O BOX GP 521, ACCRA</span>
              </div>
              <div className='flex items-center space-x-2'>
                <PhoneIcon className='h-4 w-4' />
                <span>0302-611-611</span>
              </div>
              <div className='flex items-center space-x-2'>
                <GlobeAltIcon className='h-4 w-4' />
                <span>ecg@ecggh.com</span>
              </div>
            </div>
            <div className='flex items-center space-x-6'>
              <div className='flex items-center space-x-2'>
                <span className='font-semibold'>VAT REG:</span>
                <span>714V000395</span>
              </div>
              <div className='flex items-center space-x-2'>
                <span className='font-semibold'>Website:</span>
                <span>https://www.ecggh.com/</span>
              </div>
            </div>
          </div>
        </div>

        <div className=' p-6 overflow-y-auto flex-1'>
          <div className='block'>
            <div className='border-b border-gray-200 bg-white px-6'>
              <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
                {orderTabs.map((_orderTab) => (
                  <button
                    key={_orderTab.name}
                    onClick={wrapClick(__setOrderTab(_orderTab.href))}
                    className={classNames(
                      orderTab === _orderTab.href
                        ? "border-primary-500 text-primary-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                      "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                    )}
                    aria-current={
                      orderTab === _orderTab.href ? "page" : undefined
                    }
                  >
                    {_orderTab.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
          {orderTab === "CustomerDetails" && (
            <div className='pt-6'>
              <span className='text-xs font-light'>Customer Information</span>
              <div className='grid grid-cols-3 gap-6 mt-2'>
                <div className='row-span-3 flex flex-col space-y-3 items-center justify-center'>
                  <Avatar
                    alt={
                      complaint?.customer?.representative?.fullName ||
                      complaint?.customer?.organization?.name ||
                      "N A"
                    }
                    src={
                      complaint?.customer?.representative?.profileImageUrl || ""
                    }
                    size='xl'
                  />

                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      complaint?.customer?.status === "SuperAdmin"
                        ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50`
                        : "",
                      complaint?.customer?.status === "Admin"
                        ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50`
                        : "",
                      complaint?.customer?.status === "Supervisor"
                        ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                        : "",
                      complaint?.customer?.status === "Reader"
                        ? `bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50`
                        : "",
                      complaint?.customer?.status === "Queued"
                        ? `bg-gray-100 text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50`
                        : "",
                      complaint?.customer?.status === "Success"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                        : "",
                      complaint?.customer?.status === "Reversed"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                        : "",
                      !complaint?.customer?.status
                        ? "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-100"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 10 10'
                      fill='currentColor'
                      className='w-1.5 h-1.5'
                    >
                      <circle
                        fillRule='evenodd'
                        cx='5'
                        cy='5'
                        r='5'
                        clipRule='evenodd'
                      />
                    </svg>
                    <span>
                      {complaint?.customer?.status || "Active Contract"}
                    </span>
                  </span>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Customer Code
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {complaint?.customer?.code || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Ghana Card Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {complaint?.customer?.complaintType === "Individual"
                      ? complaint?.customer?.representative?.ghanaCardNumber ||
                        "N/A"
                      : complaint?.customer?.organization
                          ?.organizationRegistrationNumber || "N/A"}
                  </div>
                </div>
                <div className='col-span-2'>
                  <span className='block text-sm font-light text-gray-700'>
                    Full Name
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {complaint?.contactPerson?.fullName || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Phone Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {complaint?.contactPerson?.phoneNumber || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Email Address
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {complaint?.contactPerson?.emailAddress || "N/A"}
                  </div>
                </div>
              </div>
            </div>
          )}
          {orderTab === "OrderHistory" && (
            <div className=''>
              <div className='pt-6'>
                <span className='text-xs font-light'>Complaint Details</span>
                <div className='grid grid-cols-3 gap-6 mt-2'>
                  <div>
                    <span className='block text-sm font-light text-gray-700'>
                      Code
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {complaint?.code || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm font-light text-gray-700'>
                      Type
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {complaint?.type?.name || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm font-light text-gray-700'>
                      Status
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {complaint?.status || "N/A"}
                    </div>
                  </div>
                  <div className='col-span-3'>
                    <span className='block text-sm font-light text-gray-700'>
                      Description
                    </span>
                    <div className='mt-1 block w-full sm:text-sm prose'>
                      {parse(complaint?.description || "N/A")}
                    </div>
                  </div>
                  {complaint?.supportingDocumentUrls?.length > 0 && (
                    <div className='col-span-3'>
                      <span className='block text-sm font-light text-gray-700'>
                        Supporting Document
                      </span>
                      <div
                        className={classNames(
                          "grid gap-2",
                          complaint?.supportingDocumentUrls?.length > 1
                            ? " grid-cols-3"
                            : ""
                        )}
                      >
                        {complaint?.supportingDocumentUrls?.map(
                          (el: string, idx: number) => (
                            <div className='mt-2'>
                              {wrapImage(
                                <img
                                  src={el}
                                  alt={"supporting Document " + idx}
                                  className='w-full h-56 object-cover text-xs'
                                />,
                                !el
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='pt-6'>
                <span className='text-xs font-light'>
                  Contact Person Information
                </span>
                <div className='grid grid-cols-2 gap-6 mt-2'>
                  <div className='col-span-2'>
                    <span className='block text-sm font-light text-gray-700'>
                      Full Name
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {complaint?.contactPerson?.fullName || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm font-light text-gray-700'>
                      Phone Number
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {complaint?.contactPerson?.phoneNumber || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm font-light text-gray-700'>
                      Email Address
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {complaint?.contactPerson?.emailAddress || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {orderTab === "ExtraDetails" && (
            <div className='pt-6'>
              <span className='text-xs font-light'>Created By</span>
              <div className='grid grid-cols-2 gap-6 mt-2'>
                <div className='col-span-2'>
                  <span className='block text-sm font-light text-gray-700'>
                    Full Name
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {[
                      complaint?.createdBy?.firstName,
                      complaint?.createdBy?.lastName,
                    ].join(" ") || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Phone Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {complaint?.createdBy?.phoneNumber || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Email Address
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {complaint?.createdBy?.emailAddress || "N/A"}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200'>
        {complaint?.status === "Open" &&
          orderTab === "OrderHistory" &&
          complaint?.userRole?._id === currentUser.role._id && (
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={() =>
                startResolution({
                  variables: { startComplaintId:params?.complaint  },
                })
              }
            >
              {starting ? " Starting ..." : " Start Resolution"}
            </button>
          )}
      </div>
    </div>
  );
};

export default TicketGeneratedDetailsContainer;
