import { FC, useEffect, useState } from "react";
import { classNames, useDownloadFile, wrapClick } from "utils";
import { gql, useLazyQuery, useReactiveVar } from "@apollo/client";
import { useFormik } from "formik";
import _ from "lodash";
import toast from "react-hot-toast";
import { Modal, SearchSelectInput } from "components";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { ContractorPicker, DistrictPicker, RegionPicker } from "containers";
import CustomPicker from "components/layouts/calendar-header/custom-picker";
import DayPicker from "components/layouts/calendar-header/day-picker";
import QuarterPicker from "components/layouts/calendar-header/quarter-picker";
import WeekPicker from "components/layouts/calendar-header/week-picker";
import MonthPicker from "components/layouts/calendar-header/month-picker";
import { LocationGenerics } from "router/location";
import { useSearch } from "react-location";
import { currentUserVar } from "apollo/cache/auth";

export const GET_SERVICE_ORDER_EXPORT_URL = gql`
  query Query(
    $fromDate: Date
    $toDate: Date
    $dateField: String
    $search: String
    $searchFields: [String!]
    $district: ID
    $region: ID
    $status: ReplacementServiceOrderStatus
    $priority: ReplacementServiceOrderPriority
    $installationMeterSystemSyncStatus: InstallationMeterSystemSyncStatus
    $farmingOutContractor: ID
    $farmingOutContractorUser: ID
  ) {
    url: getReplacementServiceOrderExportUrl(
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      search: $search
      searchFields: $searchFields
      district: $district
      region: $region
      status: $status
      priority: $priority
      installationMeterSystemSyncStatus: $installationMeterSystemSyncStatus
      farmingOutContractor: $farmingOutContractor
      farmingOutContractorUser: $farmingOutContractorUser
    )
  }
`;

const mailingLists = [
  {
    id: "",
    title: "All Orders",
    description: "Last message sent 4 days ago",
  },
  {
    id: "Pending",
    title: "Pending Orders",
    description: "Last message sent an hour ago",
  },
  {
    id: "AssignedToSupplier",
    title: "Supplier Assigned Orders",
    description: "Last message sent an hour ago",
  },
  {
    id: "AssignedToInstaller",
    title: "Installer Assigned Orders",
    description: "Last message sent 2 weeks ago",
  },
  {
    id: "Rejected",
    title: "Rejected Orders",
    description: "Last message sent 2 weeks ago",
  },

  {
    id: "InProgress",
    title: "In Progress Orders",
    description: "Last message sent 4 days ago",
  },
  {
    id: "Resolved",
    title: "Resolved Orders",
    description: "Last message sent 4 days ago",
  },
  {
    id: "Disapproved",
    title: "Disapproved Orders",
    description: "Last message sent 4 days ago",
  },
  {
    id: "Completed",
    title: "Completed Orders",
    description: "Last message sent 4 days ago",
  },
];

const views = ["all-time", "day", "week", "month", "custom"] as const;

interface ExportDataContainerProps {
  entityType: string;
  open: boolean;
  setOpen: (val: boolean) => void;
  filter: any;
}

const ExportDataContainer: FC<ExportDataContainerProps> = ({
  open,
  setOpen,
  filter,
  entityType,
}) => {
  const searchParams = useSearch<LocationGenerics>();
  const currentUser = useReactiveVar(currentUserVar);
  const exportForm = useFormik({
    initialValues: {
      status: "",
      periodType: searchParams?.view || "all-time",
      district: currentUser.district?._id || "",
      region: "",

      installationMeterSystemSyncStatus: "",
      priority: "",
      farmingOutContractor: "",
      farmingOutContractorUser: "",
      fromDate: "",
      toDate: "",
      dateField: "",
      ...filter,
    },
    onSubmit: (values) => {
      console.log("data", values);
      createDataExport({
        variables: {
          status: values?.status || undefined,
          district: values?.district || undefined,
          region: values?.region || undefined,
          installationMeterSystemSyncStatus:
            values?.installationMeterSystemSyncStatus || undefined,
          priority: values?.priority || undefined,
          farmingOutContractor: values?.farmingOutContractor || undefined,
          farmingOutContractorUser:
            values?.farmingOutContractorUser || undefined,
          fromDate: values?.fromDate || undefined,
          toDate: values?.toDate || undefined,
          dateField: values?.dateField || undefined,
        },
        fetchPolicy: "no-cache",
      }).then(({ data }) => {
        if (data?.url) {
          downloadAction(`${data.url}`);
        } else {
          toast(
            JSON.stringify({ type: "error", title: "Could not export file" })
          );
        }
      });
    },
  });

  const [createDataExport, { loading }] = useLazyQuery(
    GET_SERVICE_ORDER_EXPORT_URL,
    {
      fetchPolicy: "no-cache",
    }
  );

  const { downloadAction, downloadLoading } = useDownloadFile({
    onError: () => {
      toast(
        JSON.stringify({
          type: "error",
          title: "An error occurred downloading file",
        })
      );
    },
    getFileName: () => new Date().toISOString() + "_NewServiceOrders.xlsx",
  });

  useEffect(() => {
    exportForm.setValues({
      status: "",
      periodType: searchParams?.view || "all-time",
      district: currentUser.district?._id || "",
      region: "",
      installationMeterSystemSyncStatus: "",
      priority: "",
      farmingOutContractor: "",
      farmingOutContractorUser: "",
      fromDate: "",
      toDate: "",
      dateField: "",
      ...filter,
    });
  }, [open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={`Export ${_.startCase(entityType) || "Data"}`}
      description='Select fields to export'
      size={"5xl"}
      renderActions={() => (
        <>
          <button
            type='button'
            disabled={loading || downloadLoading}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(() => exportForm.handleSubmit())}
          >
            {loading
              ? "Exporting data..."
              : downloadLoading
              ? "Downlading file..."
              : "Export Data"}
          </button>
        </>
      )}
    >
      <div>
        <RadioGroup
          value={exportForm.values.status}
          onChange={(value) => exportForm.setFieldValue("status", value)}
        >
          <RadioGroup.Label className='text-base font-semibold leading-6 text-gray-900'>
            Select an export type
          </RadioGroup.Label>

          <div className='mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-4 sm:gap-4'>
            {mailingLists.map((mailingList) => (
              <RadioGroup.Option
                key={mailingList.id}
                value={mailingList.id}
                className={({ active }) =>
                  classNames(
                    active
                      ? "border-primary-600 ring-2 ring-primary-600"
                      : "border-gray-300",
                    "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
                  )
                }
              >
                {({ checked, active }) => (
                  <>
                    <span className='flex flex-1'>
                      <span className='flex flex-col'>
                        <RadioGroup.Label
                          as='span'
                          className='block text-sm font-medium text-gray-900'
                        >
                          {mailingList.title}
                        </RadioGroup.Label>
                        {/* <RadioGroup.Description
                          as="span"
                          className="mt-1 flex items-center text-sm text-gray-500"
                        >
                          {mailingList.description}
                        </RadioGroup.Description> */}
                        {/* <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                      {mailingList.users}
                    </RadioGroup.Description> */}
                      </span>
                    </span>
                    <CheckCircleIcon
                      className={classNames(
                        !checked ? "invisible" : "",
                        "h-5 w-5 text-primary-600"
                      )}
                      aria-hidden='true'
                    />
                    <span
                      className={classNames(
                        active ? "border" : "border-2",
                        checked ? "border-primary-600" : "border-transparent",
                        "pointer-events-none absolute -inset-px rounded-lg"
                      )}
                      aria-hidden='true'
                    />
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>

      <div className='border-b border-white/10 mt-8'>
        <h2 className='text-base font-semibold leading-6 text-gray-900'>
          Select filters to apply
        </h2>
        <p className='mt-1 text-sm leading-6 text-gray-600'>
          Select filters to filter down the data exported
        </p>

        <div className='mt-4 grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6'>
          {!currentUser.region && (
            <div className='sm:col-span-2'>
              <RegionPicker id='region' rawId={true} {...exportForm} />
            </div>
          )}
          {exportForm.values.region && (
            <div className='sm:col-span-2'>
              <DistrictPicker id='district' rawId={true} {...exportForm} />
            </div>
          )}

          <div className='sm:col-span-2'>
            <ContractorPicker
              id='farmingOutContractor'
              label={"Contractor"}
              placeholder='Select Contractor'
              filter={{ district: exportForm?.values?.district }}
              {...exportForm}
              rawId={true}
            />
          </div>

          <div className='sm:col-span-2 sm:col-start-1'>
            <SearchSelectInput
              id='priority'
              options={[
                { label: { title: "Low" }, value: "Low" },
                { label: { title: "Medium" }, value: "Medium" },
                { label: { title: "High" }, value: "High" },
                { label: { title: "Critical" }, value: "Critical" },
              ]}
              label={"Priority"}
              placeholder='Select Priority'
              {...exportForm}
              position='top'
            />
          </div>
          <div className='sm:col-span-2'>
            <SearchSelectInput
              id='installationMeterSystemSyncStatus'
              options={[
                { label: { title: "Pending" }, value: "Pending" },
                { label: { title: "Attempted" }, value: "Attempted" },
                {
                  label: { title: "Forward Synced" },
                  value: "ForwardSynced",
                },
                {
                  label: { title: "Reverse Synced" },
                  value: "ReverseSynced",
                },
                { label: { title: "Completed" }, value: "Completed" },
              ]}
              label={"Sync Status"}
              placeholder='Select Sync Status'
              {...exportForm}
              position='top'
            />
          </div>
          <div className='sm:col-span-2 sm:col-start-1'>
            <SearchSelectInput
              id='periodType'
              options={views.map((view) => ({
                label: { title: _.upperFirst(view) },
                value: view,
              }))}
              label={"Period Type"}
              placeholder='Select Period Type'
              {...exportForm}
              position='top'
            />
          </div>
          {exportForm?.values?.periodType &&
            exportForm?.values?.periodType !== "all-time" && (
              <div className='sm:col-span-2'>
                <label className='block text-sm font-medium text-gray-700'>
                  Select Period
                </label>
                <div className='mt-1'>
                  {exportForm?.values?.periodType === "day" && (
                    <DayPicker
                      fromState={[
                        exportForm.values.fromDate,
                        (value) => {
                          exportForm.setFieldValue("fromDate", value);
                        },
                      ]}
                      toState={[
                        exportForm.values.toDate,
                        (value) => {
                          exportForm.setFieldValue("toDate", value);
                        },
                      ]}
                    />
                  )}
                  {exportForm?.values?.periodType === "week" && (
                    <WeekPicker
                      fromState={[
                        exportForm.values.fromDate,
                        (value) => {
                          exportForm.setFieldValue("fromDate", value);
                        },
                      ]}
                      toState={[
                        exportForm.values.toDate,
                        (value) => {
                          exportForm.setFieldValue("toDate", value);
                        },
                      ]}
                    />
                  )}
                  {exportForm?.values?.periodType === "month" && (
                    <MonthPicker
                      fromState={[
                        exportForm.values.fromDate,
                        (value) => {
                          exportForm.setFieldValue("fromDate", value);
                        },
                      ]}
                      toState={[
                        exportForm.values.toDate,
                        (value) => {
                          exportForm.setFieldValue("toDate", value);
                        },
                      ]}
                    />
                  )}
                  {exportForm?.values?.periodType === "quarter" && (
                    <QuarterPicker
                      fromState={[
                        exportForm.values.fromDate,
                        (value) => {
                          exportForm.setFieldValue("fromDate", value);
                        },
                      ]}
                      toState={[
                        exportForm.values.toDate,
                        (value) => {
                          exportForm.setFieldValue("toDate", value);
                        },
                      ]}
                    />
                  )}
                  {exportForm?.values?.periodType === "custom" && (
                    <CustomPicker
                      fromState={[
                        exportForm.values.fromDate,
                        (value) => {
                          exportForm.setFieldValue("fromDate", value);
                        },
                      ]}
                      toState={[
                        exportForm.values.toDate,
                        (value) => {
                          exportForm.setFieldValue("toDate", value);
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
    </Modal>
  );
};

export default ExportDataContainer;
