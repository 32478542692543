import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { Modal } from "components";
import BulkUploadReplacements from "components/forms/initiate-replacement-service-order-bulk";
import toast from "react-hot-toast";
import { useState } from "react";
import { classNames, validators, wrapClick } from "utils";
import * as yup from "yup";
import lodash from "lodash";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import moment from "moment";

const ASSIGN_SERVICE_ORDER = gql`
  mutation CreateReplacementServiceOrderInitiation(
    $initiations: [ReplacementServiceOrderInitiationDataInput!]!
  ) {
    createReplacementServiceOrderInitiation(initiations: $initiations) {
      _id
    }
  }
`;

export default function BulkInitiateReplacementServiceOrderContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [, setIsValid] = useState(false);
  const [initiateReplacementServiceOrder, { loading }] =
    useMutation(ASSIGN_SERVICE_ORDER);

  const form = useFormik<{
    initiations: {
      servicePointCode: string;
      phoneNumber: string;
      debtAmount: string;
      creditAmount: string;
      ghanaPostAddress: string;
      estimatedResolutionDate: string;
      replacementReason: string;
      oldMeterNumber: string;
      oldMeterPhase: string;
    }[];
  }>({
    initialValues: {
      initiations: [],
    },
    validateOnChange: true,
    validationSchema: yup.object().shape({
      initiations: yup
        .array()
        .of(
          yup.object().shape({
            servicePointCode: yup
              .string()
              .required("Service Point Number is Required")
              .matches(/^2\d{8}$/, "Invalid Service Point Number"),
            phoneNumber: yup
              .string()
              .required("Customer Phone is Required")
              .matches(/^0[0-9]{9}$/, "Phone Numbers must be exactly 10 digits and start with '0'"),
            debtAmount: yup.number().required("Debt Amount is Required"),
            creditAmount: yup.number().required("Credit Amount is Required"),
            ghanaPostAddress: yup
              .string()
              .matches(
                validators.GhanaPostRegex,
                "Kindly enter a valid GhanaPost Address"
              )
              .required("GhanaPost Address is Required"),
            estimatedResolutionDate: yup
              .string()
              .test(
                "is-valid-date",
                "Invalid Date, Date must be in the format YYYY-MM-DD or DD/MM/YYYY or DD-MM-YYYY or DD-MM-YY",
                (value) =>
                  moment(
                    value,
                    ["YYYY-MM-DD", "DD/MM/YYYY", "DD-MM-YYYY", "DD-MM-YY"],
                    true
                  ).isValid()
              )
              .required("Resolution Date is Required"),
            replacementReason: yup.string().notRequired(),
            oldMeterNumber: yup
              .string()
              .required("Old Meter Number is Required"),
            oldMeterPhase: yup.string().required("Old Meter Phase is Required"),
          })
        )
        .min(1, "The document should contain at least a record in it"),
    }),
    onSubmit: async (values) => {
      await initiateReplacementServiceOrder({
        variables: {
          initiations: values.initiations?.map((initiation) => ({
            ...initiation,
            estimatedResolutionDate: moment(
              initiation.estimatedResolutionDate,
              [
                "DD/MM/YYYY",
                "YYYY/MM/DD",
                "DD/MM/YY",
                "YY/MM/DD",
                "DD-MM-YYYY",
                "YYYY-MM-DD",
                "DD MMM, YYYY",
                "Do MMM, YYYY",
              ]
            ).format("YYYY-MM-DD"),
          })),
        },
      }).then(({ data }) => {
        if (data.createReplacementServiceOrderInitiation?._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Service Order Initiated Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not initiate Service Order",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Initiate Replacement Service Order (Bulk Upload)'
      size='7xl'
      description='Provide the details to initiate replacement service order'
      renderActions={() => (
        <>
          <button
            type='button'
            disabled={!form.isValid}
            className='w-full inline-flex justify-center rounded-md disabled:cursor-not-allowed border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Upload ..." : "Upload"}
          </button>
        </>
      )}
    >
      {/* {JSON.stringify(form.errors, null, 2)} */}
      {!form.isValid && (
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={classNames(
                  "flex w-full justify-between rounded-t-lg bg-red-100 px-4 py-2 text-left text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75",
                  open ? "" : "mb-2"
                )}
              >
                <span>Errors Detected</span>
                <ChevronUpIcon
                  className={`${!open ? "rotate-180 transform" : ""
                    } h-5 w-5 text-red-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className='px-4 pt-4 pb-2 mb-2 text-sm text-gray-500 rounded-b-lg bg-red-100'>
                <div>
                  {lodash.isArray(form.errors?.initiations) ? (
                    form?.errors?.initiations?.map((row, rowIndex) => (
                      <div className='text-red-700'>
                        {row && (
                          <span className='font-bold'>Row: {rowIndex + 1}</span>
                        )}
                        {lodash.isString(row) ? (
                          <span>{row}</span>
                        ) : (
                          <div className='flex flex-col'>
                            {row?.servicePointCode ? (
                              <li>{row?.servicePointCode}</li>
                            ) : null}
                            {row?.phoneNumber ? (
                              <li>{row?.phoneNumber}</li>
                            ) : null}
                            {row?.debtAmount ? (
                              <li>{row?.debtAmount}</li>
                            ) : null}
                            {row?.creditAmount ? (
                              <li>{row?.creditAmount}</li>
                            ) : null}
                            {row?.ghanaPostAddress ? (
                              <li>{row?.ghanaPostAddress}</li>
                            ) : null}
                            {row?.estimatedResolutionDate ? (
                              <li>{row?.estimatedResolutionDate}</li>
                            ) : null}
                            {row?.oldMeterNumber ? (
                              <li>{row?.oldMeterNumber}</li>
                            ) : null}
                            {row?.oldMeterPhase ? (
                              <li>{row?.oldMeterPhase}</li>
                            ) : null}
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <span className='font-bold italic'>
                      {form?.errors?.initiations}
                    </span>
                  )}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}

      <BulkUploadReplacements
        setValid={setIsValid}
        form={form}
        setShow={setOpen}
      />
    </Modal>
  );
}
