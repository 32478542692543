// import { useLazyQuery } from "@apollo/client";
import { SearchSelectInput, TextArea } from "components/core"
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik"
import { FC, useEffect } from "react"
import { wrapClick, classNames } from "utils";
// import { Switch } from "@headlessui/react";
// import { GET_READERS } from "pages/readers";
import { ExtraInfoFormSchema, IResolveInvestigationServiceOrderFormSchema } from "./schema";
import _ from "lodash"
import { gql, useQuery } from "@apollo/client";
import lodash from "lodash";

interface MaterialsInfoFormProps {
  handleNext: (values: IResolveInvestigationServiceOrderFormSchema["extraInfo"]) => void
  handlePrevious: () => void;
  initialValues: IResolveInvestigationServiceOrderFormSchema["extraInfo"];
  values: IResolveInvestigationServiceOrderFormSchema;
  handleCancel: () => void;
}

const GET_APPLIANCES = gql`
  query GetAllAppliances {
    appliances: getAppliances(sort: "name") {
      _id
      code
      name
      wattage
      createdAt
      updatedAt
    }
  }
`;

const GET_MATERIALS = gql`
  query GetAllMaterials {
    materials: getMaterials(sort: "name") {
      _id
      code
      name
      quantityUnit
      createdAt
      updatedAt
    }
  }
`;

const GET_INSTALLATION_TYPE = gql`
  query GetAllInstallationTypes {
    installationTypes: getInstallationTypes {
      _id
      code
      name
      materials {
        _id
        code
        name
        quantityUnit
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

const MaterialsInfoForm: FC<MaterialsInfoFormProps> = ({ initialValues, handleNext, handlePrevious, handleCancel }) => {

  const form = useFormik<IResolveInvestigationServiceOrderFormSchema["extraInfo"]>({
    initialValues,
    validationSchema: ExtraInfoFormSchema,
    onSubmit: (values) => {
      handleNext(values)
    },
    onReset: () => {
      handleCancel?.();
    }
  })

  const { data: dataAppliances } = useQuery(GET_APPLIANCES);
  const { data: dataMaterials } = useQuery(GET_MATERIALS);
  const { data: dataInstallationTypes } = useQuery(GET_INSTALLATION_TYPE);

  const removeItem = (field: string, id: number) => {
    form.setFieldValue(field, [
      ..._.get(form.values, field)?.filter((val: string, _id: number) => _id !== id),
    ])
  }

  useEffect(() => {
    if (form.values.installationType) {
      form.setFieldValue(
        "materials",
        form.values.installationType.materials
          .map((material) => ({
            material: lodash.omit(material, "__typename"),
            quantity: lodash.keyBy(form.values?.materials, (_material) => _material.material._id)[material._id]?.quantity || 1
          }))
      )
    }
    else {
      form.setFieldValue("materials", [])
    }
  }, [form.values.installationType])

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        <div className="">
          {/* {JSON.stringify(form.errors, null, 2)} */}
          <span className="text-xs font-light">Installation Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">

            <div className="col-start-1">
              <SearchSelectInput
                id="installationType"
                label="Installation Type"
                options={lodash.chain(dataInstallationTypes?.installationTypes)?.map(installationType => ({ label: { title: installationType.name }, value: installationType })).value()}
                placeholder="e.g. 1-Pole Extension"
                required={true}
                {...form}
              />
            </div>
          </div>
        </div>
        <div className="pt-6">
          <span className="text-xs font-light">Meterials Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="col-span-3">
              <span className="block text-sm font-medium text-gray-700">
                Installation Materials *
              </span>
              <div className="mt-1 w-full ">
                <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
                  <thead className="bg-gray-50">
                    <tr className="divide-x divide-gray-200">
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[77.5%]"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900  text-left"
                      >
                        Quantity
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-right"
                      >
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {_.times(form.values.materials?.length + 1, (idx) => {
                      const id = `materials.${idx}`;
                      const isLast = (form.values.materials?.length || 0) === idx

                      return (
                        <tr key={idx} className="divide-x divide-gray-200">
                          <td className="p-0">
                            <SearchSelectInput
                              label={""}
                              labelHidden={true}
                              key={idx}
                              id={`${id}.material`}
                              options={lodash.chain(dataMaterials?.materials)?.map(material => ({ label: { title: material.name }, value: material })).value()}
                              placeholder='eg. 1 Phase'
                              required={true}
                              className="border-0 rounded-none"
                              {...form}
                            />
                          </td>
                          <td className="p-0">
                            <input
                              key={idx}
                              type={"text"}
                              id={`${id}.quantity`}
                              name={`${id}.quantity`}
                              value={_.get(form.values, `${id}.quantity`, "")}
                              onChange={form.handleChange}
                              onBlur={form.handleBlur}
                              placeholder={"eg. 2"}
                              step={1}
                              min={0}
                              className={classNames(
                                _.get(form.errors, `${id}.quantity`) && _.get(form.touched, `${id}.quantity`) ? "focus:ring-red-500 focus:border-red-500 border-red-600 border" : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                                "block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px] border-0 focus:border text-left"
                              )}
                            />
                          </td>
                          <td className="p-0 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400 text-right w-[38px] h-[38px] items-center justify-center flex">
                            {!isLast && (
                              <button
                                type="button"
                                onClick={wrapClick(() => removeItem("materials", idx))}
                                className="inline-flex items-center rounded-full border border-transparent p-1 text-red-500 hover:bg-red-300 hover:text-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                              >
                                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-6">
          <span className="text-xs font-light">Appliances Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="col-span-3">
              <span className="block text-sm font-medium text-gray-700">
                Appliances At Premise *
              </span>
              <div className="mt-1 w-full ">
                <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
                  <thead className="bg-gray-50">
                    <tr className="divide-x divide-gray-200">
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[60%]"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                      >
                        Wattage
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-left"
                      >
                        Quantity
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-right"
                      >
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {_.times(form.values.appliances?.length + 1, (idx) => {
                      const id = `appliances.${idx}`;
                      const isLast = (form.values.appliances?.length || 0) === idx

                      if (!isLast && !_.get(form.values, `${id}.quantity`) && !_.get(form.touched, `${id}.quantity`)) {
                        form.setFieldValue(`${id}.quantity`, 1)
                      }

                      return (
                        <tr key={idx} className="divide-x divide-gray-200">
                          <td className="p-0">
                            <SearchSelectInput
                              label={""}
                              labelHidden={true}
                              key={idx}
                              id={`${id}.appliance`}
                              options={lodash.chain(dataAppliances?.appliances)?.map(appliance => ({ label: { title: appliance.name }, value: appliance })).value()}
                              placeholder='eg. Fan'
                              required={true}
                              className="border-0 rounded-none"
                              {...form}
                            />
                          </td>
                          <td className="p-0">
                            <input
                              key={idx}
                              type={"number"}
                              id={`${id}.appliance.wattage`}
                              name={`${id}.appliance.wattage`}
                              value={_.get(form.values, `${id}.appliance.wattage`, "")}
                              onChange={form.handleChange}
                              onBlur={form.handleBlur}
                              disabled={idx < form.values.appliances.length}
                              className={classNames(
                                "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                                idx < form.values.appliances.length ? "cursor-not-allowed bg-gray-100" : "",
                                "block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px] border-0 focus:border"
                              )}
                            />
                          </td>
                          <td className="p-0">
                            <input
                              key={idx}
                              type={"number"}
                              id={`${id}.quantity`}
                              name={`${id}.quantity`}
                              value={_.get(form.values, `${id}.quantity`, "")}
                              onChange={form.handleChange}
                              onBlur={form.handleBlur}
                              placeholder={"eg. 34"}
                              step={1}
                              min={0}
                              className={classNames(
                                _.get(form.errors, `${id}.quantity`) && _.get(form.touched, `${id}.quantity`) ? "focus:ring-red-500 focus:border-red-500 border-red-600 border" : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                                "block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px] border-0 focus:border"
                              )}
                            />
                          </td>
                          <td className="p-0 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400 text-right w-[38px] h-[38px] items-center justify-center flex">
                            {!isLast && (
                              <button
                                type="button"
                                onClick={wrapClick(() => removeItem("appliances", idx))}
                                className="inline-flex items-center rounded-full border border-transparent p-1 text-red-500 hover:bg-red-300 hover:text-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                              >
                                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-6">
          <span className="text-xs font-light">Extra Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="col-span-3">
              <TextArea
                id="notes"
                label="Notes"
                placeholder="eg. Under the house"
                required={true}
                rows={5}
                {...form}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default MaterialsInfoForm
