import { Avatar } from "components/core";
import lodash from "lodash";

const AssigneeInfoSummary = ({ data }: any) => (
  <div className="border-2 border-dashed border-gray-300 divide-y-2 divide-gray-300 divide-dashed space-y-6 rounded-md p-3">
    <div className='grid grid-cols-3 gap-6'>
      <div>
        <span className="block text-sm font-light text-gray-700">
          Assignee Type
        </span>
        <div className="mt-1 block w-full sm:text-sm">
          {lodash.startCase(data?.assigneeType) || "N/A"}
        </div>
      </div>
    </div>
    <div className="pt-6">
      {data?.assigneeType === "FieldAgent" ? (
        <div className='grid grid-cols-3 gap-6'>
          <div className='row-span-3 flex flex-col space-y-3 items-center justify-center'>
            <Avatar
              alt={data?.assignee?.fullName || "N A"}
              src={data?.assignee?.profileImageUrl || ""}
              size="xl"
            />
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Staff Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.assignee?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Ghana Card Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.assignee?.ghanaCardNumber || "N/A"}
            </div>
          </div>
          <div className=''>
            <span className="block text-sm font-light text-gray-700">
              First Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.assignee?.firstName || "N/A"}
            </div>
          </div>
          <div className=''>
            <span className="block text-sm font-light text-gray-700">
              Last Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.assignee?.lastName || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Phone Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.assignee?.phoneNumber || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Email Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.assignee?.emailAddress || "N/A"}
            </div>
          </div>
        </div>
      ) : (
        <div className='grid grid-cols-3 gap-6'>
          <div className='row-span-3 flex flex-col space-y-3 items-center justify-center'>
            <Avatar
              alt={data?.assignee?.name || "N A"}
              size="xl"
            />
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Contractor Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.assignee?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Contractor Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.assignee?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Contractor Type
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {lodash.startCase(data?.assignee?.type) || "N/A"}
            </div>
          </div>
          <div className=''>
            <span className="block text-sm font-light text-gray-700">
              Contact Person
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.assignee?.contactPerson?.fullName || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Phone Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.assignee?.contactPerson?.phoneNumber || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Email Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.assignee?.contactPerson?.emailAddress || "N/A"}
            </div>
          </div>
        </div>

      )}
    </div>
  </div>
)

export default AssigneeInfoSummary;