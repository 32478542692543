import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { InitiateServiceOrderFromAnomalyForm, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import lodash from "lodash";

const ASSIGN_SERVICE_ORDER = gql`
  mutation InitiateReplacementServiceOrder(
    $servicePoint: ID!
    $assigneeType: ReplacementServiceOrderAssigneeType!
    $assignee: ID!
    $installationMeter: ID
    $priority: ReplacementServiceOrderPriority!
    $estimatedResolutionDate: Date!
    $notes: String
    $debtAmount: NonNegativeFloat
    $creditAmount: NonNegativeFloat
    $oldMeterNumber: String
    $oldMeterPhase: String
    $customerPhone: String
    $ghanaPostAddress: String
    $replacementReason: String
  ) {
    initiateReplacementServiceOrder(
      servicePoint: $servicePoint
      assigneeType: $assigneeType
      assignee: $assignee
      installationMeter: $installationMeter
      priority: $priority
      estimatedResolutionDate: $estimatedResolutionDate
      notes: $notes
      creditAmount: $creditAmount
      debtAmount: $debtAmount
      oldMeterNumber: $oldMeterNumber
      oldMeterPhase: $oldMeterPhase
      phoneNumber: $customerPhone
      ghanaPostAddress: $ghanaPostAddress
      replacementReason: $replacementReason
    ) {
      _id
    }
  }
`;

export default function InitiateReplacementServiceOrderContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const searchParams = useSearch<LocationGenerics>();
    const [initiateReplacementServiceOrder] = useMutation(ASSIGN_SERVICE_ORDER);

    const form = useFormik({
        initialValues: {
            assigneeInfo: {
                isCompliant: "",
                assigneeType: "FieldAgent" as
                    | "FieldAgent"
                    | "FarmingOutContractor"
                    | "MeterManufacturingContractor",
                assignee: null as any,
            },
            meterInfo: {
                meter: null as any,
            },
            extraInfo: {
                type: "",
                replacementReason: "",
                estimatedResolutionDate: "",
                priority: "Low",
                notes: "",
                debtAmount: 0,
                creditAmount: 0,
                oldMeterNumber: "",
                oldMeterPhase: "",
                oldMeterNumberTemp: "",
                oldMeterPhaseTemp: "",
                customerPhone: "",
                ghanaPostAddress: "",
            },
        },
        onSubmit: async (values) => {
            await initiateReplacementServiceOrder({
                variables: {
                    id: searchParams?.id,
                    servicePoint: searchParams?.servicePoint || "",
                    assigneeType: values.assigneeInfo?.assigneeType,
                    assignee: values.assigneeInfo?.assignee?._id,
                    ...(values.meterInfo?.meter
                        ? { installationMeter: values.meterInfo?.meter?._id }
                        : {}),
                    ...lodash.omit(values.extraInfo, [
                        "oldMeterNumberTemp",
                        "oldMeterPhaseTemp",
                    ]),
                },
            }).then(({ data }) => {
                if (data.initiateReplacementServiceOrder._id) {
                    toast(
                        JSON.stringify({
                            type: "success",
                            title: "Service Order Initiated Successfully",
                        })
                    );
                    refetch?.();
                    setOpen(false);
                } else {
                    toast(
                        JSON.stringify({
                            type: "error",
                            title: "Could not initiate Service Order",
                        })
                    );
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            hideActions={true}
            title='Initiate Replacement Service Order'
            size='5xl'
            description='Provide the details to initiate replacement service order'
        >
            <InitiateServiceOrderFromAnomalyForm form={form} />
        </Modal>
    );
}
