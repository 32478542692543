import { gql, useQuery } from "@apollo/client";

const GET_CONTRACTORS = gql`
  query GetContractors(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $district: ID
  ) {
    rows: getContractors(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      district: $district
    ) {
      _id
      code
      name
      type
      address
      ghanaPostAddress
      contactPerson {
        emailAddress
        fullName
        phoneNumber
      }
      createdAt
      updatedAt
    }
  }
`;

const GET_CONTRACTORS_COUNT = gql`
  query GetContractorsCount(
    $search: String
    $searchFields: [String!]
    $district: ID
    $type: ContractorType
    $region: ID
  ) {
    count: getContractorsCount(
      search: $search
      searchFields: $searchFields
      district: $district
      type: $type
      region: $region
    )
  }
`;

export const useContractors = (variables: any) => {
  const { data, ...rest } = useQuery(GET_CONTRACTORS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
  const { data: countData } = useQuery(GET_CONTRACTORS_COUNT, {
    variables,
  });
  const contractors = data?.rows || [];
  const count = countData?.count || 0;
  return { contractors, count, ...rest };
};
