import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import { SelectInput } from "components/core";
import { FormikProps, useFormik } from "formik";
import lodash from "lodash";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { classNames, wrapClick } from "utils";
import { read, utils } from "xlsx";

export interface BulkUploadReplacementsProps {
  form: FormikProps<{
    initiations: {
      servicePointCode: string;
      phoneNumber: string;
      debtAmount: string;
      creditAmount: string;
      ghanaPostAddress: string;
      estimatedResolutionDate: string;
      replacementReason: string;
      oldMeterNumber: string;
      oldMeterPhase: string;
    }[];
  }>;
  setShow: any;
  setValid: Dispatch<SetStateAction<boolean>>;
}

const BulkUploadReplacements: FC<BulkUploadReplacementsProps> = ({
  form: parentForm,
  setValid,
}) => {
  // const [isLoading, setIsLoading] = useState(false)

  const [columns, setColumns] = useState<any>([]);
  const [showSelectors, setShowSelectors] = useState(false);
  const [showFinal, setShowFinal] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);

  const form = useFormik<{
    servicePointCode: string;
    phoneNumber: string;
    debtAmount: string;
    creditAmount: string;
    ghanaPostAddress: string;
    estimatedResolutionDate: string;
    replacementReason: string;
    oldMeterNumber: string;
    oldMeterPhase: string;
  }>({
    onSubmit: (values) => {
      setShowFinal(true);
    },
    initialValues: {
      servicePointCode: "Service Point Number",
      phoneNumber: "Customer Phone",
      debtAmount: "Debt Amount",
      creditAmount: "Credit Amount",
      ghanaPostAddress: "Digital Address",
      estimatedResolutionDate: "Resolution Date",
      replacementReason: "Reason",
      oldMeterNumber: "Old Meter Number",
      oldMeterPhase: "Old Meter Phase",
    },
    onReset: (values) => { },
  });

  const processData = (dataString: any) => {
    // console.log(dataString)
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );
    // console.log("HEADERS", dataStringLines)
    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length === headers.length) {
        const obj: any = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    setData(list);
    const initiations = list.map((el) => {
      const item = lodash.keys(form.values)?.map((column) => {
        const obj: any = {};
        const keyValue = lodash.get(form.values, column);
        obj[column] = el[keyValue];
        return obj;
      });
      return lodash.merge({}, ...item);
    });
    parentForm.setFieldValue("initiations", initiations);
    setColumns(headers);
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    // Do something with the files
    const file = acceptedFiles[0];
    // console.log("---FILE---", file)
    // setMembers(file)
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt?.target?.result;
      const wb = read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = utils.sheet_to_csv(ws, {});
      processData(data);
      setShowSelectors(true);
    };
    reader.readAsBinaryString(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    // accept: ".csv, .xlsx, .xls, .json",
    accept: {
      "text/csv": [".csv"],
      "text/xlsx": [".xlsx"],
      "text/xls": [".xls"],
      "text/json": [".json"],
    },
  });

  useEffect(() => {
    const initiations = data.map((el) => {
      const item = lodash.keys(form.values)?.map((column) => {
        const obj: any = {};
        const keyValue = lodash.get(form.values, column);
        obj[column] = el[keyValue];
        return obj;
      });
      return lodash.merge({}, ...item);
    });
    parentForm.setFieldValue("initiations", initiations);
    return () => { };
  }, [form?.values]);

  return (
    <div className='inline-block align-bottom bg-white text-left h-[85vh] overflow-y-scroll transform transition-all'>
      {!showSelectors && (
        <div className='grid grid-cols-3 gap-6 h-full'>
          <button
            type='button'
            {...getRootProps()}
            className='relative block w-full h-full rounded-lg border-2 col-span-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
          >
            <svg
              className='mx-auto h-12 w-12 text-gray-400'
              stroke='currentColor'
              fill='none'
              viewBox='0 0 48 48'
              aria-hidden='true'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6'
              />
            </svg>
            <h3 className='mt-2 text-sm font-medium text-gray-900'>
              No file selected
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Kindly select a file by clicking the button above.
            </p>
            <input
              {...getInputProps()}
              id='members'
              name='members'
              type='file'
              className='sr-only'
            />
          </button>
          <div className='bg-primary-100 text-primary-700 rounded-lg flex justify-center items-center flex-col h-full'>
            <DocumentArrowDownIcon className='h-12 w-12' />
            <h2 className='text-xl'>Download a sample Excel file</h2>
            <a
              href='https://subs-production.s3.us-west-2.amazonaws.com/templates/meter-replacement-template.xlsx'
              target='_blank'
              download
              rel='noreferrer'
            >
              Click Here to download
            </a>
          </div>
        </div>
      )}
      <>
        {showSelectors && (
          <div className='w-full  border border-primary-400 rounded-md bg-primary-50 border-dashed my-2 py-4'>
            <div className='flex justify-end px-4'>
              <button
                {...getRootProps()}
                type='button'
                className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm'
              >
                Upload new file
                <input
                  {...getInputProps()}
                  id='members'
                  name='members'
                  type='file'
                  className='sr-only'
                />
              </button>
              <button
                onClick={wrapClick(form.handleSubmit)}
                className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              >
                Show preview
              </button>
            </div>

            <div className='w-full flex justify-between p-3 items-end flex-1'>
              <div className='grid grid-cols-4 gap-4 w-full'>
                <div>
                  <SelectInput
                    label='Service Point Number (SPN)'
                    defaultValue='Service Point Number'
                    id={"servicePointCode"}
                    {...form}
                    options={columns
                      ?.filter((_c: any[]) => _c.length > 0)
                      ?.map((column: any, idx: number) => ({
                        label: column,
                        value: column,
                      }))}
                  />
                </div>
                <div>
                  <SelectInput
                    label='Customer Phone'
                    defaultValue='Customer Phone'
                    id={"phoneNumber"}
                    {...form}
                    options={columns
                      ?.filter((_c: any[]) => _c.length > 0)
                      ?.map((column: any, idx: number) => ({
                        label: column,
                        value: column,
                      }))}
                  />
                </div>
                <div>
                  <SelectInput
                    label='Debt Amount'
                    defaultValue='Debt Amount'
                    id={"debtAmount"}
                    {...form}
                    options={columns
                      ?.filter((_c: any[]) => _c.length > 0)
                      ?.map((column: any, idx: number) => ({
                        label: column,
                        value: column,
                      }))}
                  />
                </div>
                <div>
                  <SelectInput
                    label='Credit Amount'
                    defaultValue='Credit Amount'
                    id={"creditAmount"}
                    {...form}
                    options={columns
                      ?.filter((_c: any[]) => _c.length > 0)
                      ?.map((column: any, idx: number) => ({
                        label: column,
                        value: column,
                      }))}
                  />
                </div>
                <div>
                  <SelectInput
                    label='Digital Address'
                    defaultValue='Digital  Address'
                    id={"ghanaPostAddress"}
                    {...form}
                    options={columns
                      ?.filter((_c: any[]) => _c.length > 0)
                      ?.map((column: any, idx: number) => ({
                        label: column,
                        value: column,
                      }))}
                  />
                </div>
                <div>
                  <SelectInput
                    label='Resolution Date'
                    defaultValue='Resolution Date'
                    id={"estimatedResolutionDate"}
                    {...form}
                    options={columns
                      ?.filter((_c: any[]) => _c.length > 0)
                      ?.map((column: any, idx: number) => ({
                        label: column,
                        value: column,
                      }))}
                  />
                </div>
                <div>
                  <SelectInput
                    label='Reason'
                    defaultValue='Reason'
                    id={"replacementReason"}
                    {...form}
                    options={columns
                      ?.filter((_c: any[]) => _c.length > 0)
                      ?.map((column: any, idx: number) => ({
                        label: column,
                        value: column,
                      }))}
                  />
                </div>
                <div>
                  <SelectInput
                    label='Old Meter Number'
                    defaultValue='Old Meter Number'
                    id={"oldMeterNumber"}
                    {...form}
                    options={columns
                      ?.filter((_c: any[]) => _c.length > 0)
                      ?.map((column: any, idx: number) => ({
                        label: column,
                        value: column,
                      }))}
                  />
                </div>
                <div>
                  <SelectInput
                    label='Old Meter Phase'
                    defaultValue='old Meter Phase'
                    id={"oldMeterPhase"}
                    {...form}
                    options={columns
                      ?.filter((_c: any[]) => _c.length > 0)
                      ?.map((column: any, idx: number) => ({
                        label: column,
                        value: column,
                      }))}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {showFinal && (
          <table className='min-w-full divide-y divide-gray-200 overflow-x-scroll'>
            <thead className='bg-gray-50 sticky top-0 z-10'>
              <tr>
                {[
                  "#",
                  "Service Point Number",
                  "Customer Phone",
                  "Debt Amount",
                  "Credit Amount",
                  "Digital Address",
                  "Resolution Date",
                  "Reason",
                  "old Meter Number",
                  "Old Meter Phase",
                ]?.map((column: any, idx: number) => (
                  <th
                    scope='col'
                    className={classNames(
                      idx === 0 ? "sticky left-0 bg-gray-50" : "",
                      "px-6 py-3 mt-0 text-left text-xs font-medium whitespace-nowrap text-gray-500 uppercase tracking-wider"
                    )}
                  >
                    {column}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200'>
              {data?.map((member, key) => {
                return (
                  <tr key={key}>
                    <td className='sticky left-0 bg-white px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                      {key + 1}
                    </td>
                    {lodash.keys(form.values)?.map((column) => {
                      const keyValue = lodash.get(form.values, column);
                      return (
                        <td
                          className={classNames(
                            "px-6 py-4 whitespace-nowrap text-sm ",
                            "text-gray-500"
                          )}
                        >
                          {member[keyValue] || "N/A"}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </>
    </div>
  );
};

export default BulkUploadReplacements;
