import { FC, useMemo, useRef } from "react";
import { wrapClick } from "utils";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import formSteps from "components/forms/create-service-request/form-steps";
import { ICreateServiceRequestFormSchema } from "components/forms/create-service-request/schema";
import _ from "lodash";
import { useReactToPrint } from "react-to-print";
import {
  EnvelopeIcon,
  GlobeAltIcon,
  PhoneIcon,
} from "@heroicons/react/20/solid";

const GET_SERVICE_REQUEST = gql`
  query GetServiceRequestDetails($id: ID!) {
    serviceRequest: getServiceRequest(
      id: $id
    ) {
      _id
      code
      category
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      request {
        customer {
          customerType
         
          organization {
            name
            taxIdentificationNumber
            organizationRegistrationNumber
            organizationRegistrationDate
            organizationRegistrationDocumentUrl
            certificateOfIncorporationDocumentUrl
          }
          representative {
            title
            fullName
            nationality
            dateOfBirth
            gender
            phoneNumber
            emailAddress
            profileImageUrl
            hasGhanaCard
            ghanaCardNumber
            ghanaCardIssueDate
            ghanaCardExpiryDate
            ghanaCardFrontImageUrl
            ghanaCardBackImageUrl
            identityCardType
            identityCardNumber
            identityCardIssueDate
            identityCardExpiryDate
            identityCardFrontImageUrl
            identityCardBackImageUrl
          }
        }
        property {
          owner {
            fullName
            phoneNumber
          }
          region {
            _id
            code
            name
          }
          district {
            _id
            code
            name
          }
          ghanaPostAddress
          community
          streetName
          houseNumber
          structureNumber
          landmark
          premiseType {
            _id
            code
            name
          }
          premiseCategory {
            _id
            code
            name
          }
          activity {
            _id
            code
            name
          }
          subActivity {
            _id
            code
            name
          }
          geoLocation {
            type
            coordinates
          }
          sitePlanDocumentUrl
        }
        service {
          serviceType
          serviceClass
          serviceQuantity
          energyCertificateNumber
          energyCertificateDocumentUrl
        }
      }
      type
      status
      createdAt
      updatedAt
    }
  }
`;

interface ServiceRequestDetailsContainerProps {
  values: any;
  id: string;
  code: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

const ServiceRequestDetailsContainer: FC<
  ServiceRequestDetailsContainerProps
> = ({ id, code }) => {
  const currentConfig = useReactiveVar(currentConfigVar);
  const { data, loading } = useQuery(GET_SERVICE_REQUEST, {
    variables: {
      id,
    },
  });
  const values = useMemo<ICreateServiceRequestFormSchema>(
    () => ({
      requestInfo: {
        existingServicePoint: null,
        category: data?.serviceRequest?.category,
        type: data?.serviceRequest?.type,
        quantity: data?.serviceRequest?.request?.service?.serviceQuantity,
        isExistingCustomer: data?.serviceRequest?.request?.isExistingCustomer,
        existingCustomer: data?.serviceRequest?.request?.existingCustomer,
        isExistingProperty: data?.serviceRequest?.request?.isExistingProperty,
        existingProperty: data?.serviceRequest?.request?.existingProperty,
      },
      customerInfo: {
        ...data?.serviceRequest?.request?.customer,
      },
      identityInfo: data?.serviceRequest?.request?.customer,
      propertyInfo: {
        ...data?.serviceRequest?.request?.property,
        geoLocation: {
          longitude:
            data?.serviceRequest?.request?.property?.geoLocation
              ?.coordinates?.[0] || 0,
          latitude:
            data?.serviceRequest?.request?.property?.geoLocation
              ?.coordinates?.[1] || 0,
        },
      },
      serviceInfo: data?.serviceRequest?.request?.service,
    }),
    [data?.serviceRequest]
  );
  const printRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: code,
    bodyClass: "w-[1000px]",
  });

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div ref={printRef} className="flex-1 flex flex-col overflow-hidden">
        <div className="hidden print:flex flex-row items-center space-x-4 justify-center py-6 border-b border-gray-300">
          <div>
            <img
              alt="ECG Logo"
              src={require("assets/logo-new.png")}
              className="h-32 w-32"
            />
          </div>
          <div className="space-y-1 text-gray-900">
            <h1 className="text-xl text-primary-600 font-bold">
              ELECTRICITY COMPANY OF GHANA LIMITED
            </h1>
            <div className="flex items-center space-x-6">
              <div className="flex items-center space-x-2">
                <EnvelopeIcon className="h-4 w-4" />
                <span>P.O BOX GP 521, ACCRA</span>
              </div>
              <div className="flex items-center space-x-2">
                <PhoneIcon className="h-4 w-4" />
                <span>0302-611-611</span>
              </div>
              <div className="flex items-center space-x-2">
                <GlobeAltIcon className="h-4 w-4" />
                <span>ecg@ecggh.com</span>
              </div>
            </div>
            <div className="flex items-center space-x-6">
              <div className="flex items-center space-x-2">
                <span className="font-semibold">VAT REG:</span>
                <span>714V000395</span>
              </div>
              <div className="flex items-center space-x-2">
                <span className="font-semibold">Website:</span>
                <span>https://www.ecggh.com/</span>
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-6 divide-y divide-gray-200 p-6 overflow-y-auto flex-1">
          {formSteps.map(({ SummaryComponent, ...summary }, idx) => (
            <div key={idx} className={idx === 0 ? " p-4" : " p-4 pt-6"}>
              <>
                <div className="flex justify-between items-center cursor-pointer">
                  <div>
                    <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
                      {summary?.name}
                    </h3>
                    <p className="mt-1 text-xs text-gray-500">
                      {summary?.description}
                    </p>
                  </div>
                  <div />
                </div>
                <div className="mt-6">
                  <SummaryComponent
                    data={_.get(values, summary?.accessor)}
                    values={values}
                    config={currentConfig}
                  />
                </div>
              </>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(handlePrint)}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Print
        </button>
        {/* <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Previous
        </button> */}
      </div>
    </div>
  );
};

export default ServiceRequestDetailsContainer;
