import { ReactElement } from "react";
import { ReactLocation, MakeGenerics } from "react-location";
import { parseSearch, stringifySearch } from "utils/jsurl";

export type LocationGenerics = MakeGenerics<{
  Search: {
    email?: string;
    token?: string;
    redirect?: string;
    fromDate?: string;
    toDate?: string;
    dateField: string;
    page?: number;
    pageSize?: number;
    search?: string;
    minSearch?: number;
    sort?: string;
    modal?:
    | "create"
    | "update"
    | "view"
    | "configure"
    | "calculate"
    | "assign"
    | "schedule"
    | "send"
    | "escalate"
    | "resolve"
    | "approve"
    | "disapprove"
    | "reject"
    | "reassign" 
    | "export"
    | "import"
    | "cancel"
    | "investigate"
    | "viewComplaint";
    id?: string;
    region?: string;
    district?: string;
    block?: string;
    round?: string;
    plot?: string;
    itinerary?: string;
    property?: string;
    role?: "SuperAdmin" | "Admin" | "Supervisor" | "Reader";
    month?: number;
    year?: number;
    tariffClass?: string;
    customerType?: string;
    serviceType?: string;
    serviceClass?: string;
    view?: "day" | "week" | "month" | "quarter" | "year" | "custom";
    orderStatus?: string;
    orderType?: string;
    priority?: string;
    category?: string;
    assignee?: string;
    assigner?: string;
    approver?: string;
    orderInitiationStatus?: string;
    prepaidSyncStatus?: string;
    servicePoint?: string;
    brand?: string;
    model?: string;
    location?: string;
  };
  Params: {
    region?: string;
    district?: string;
    block?: string;
    round?: string;
    plot?: string;
    itinerary?: string;
    tariffClass?: string;
    readingSet?: string;
    serviceRequest?: string;
    contractor?: string;
    meterContractor?: string;
    complaint?: string;
  };
  RouteMeta: {
    layout?: "App" | "Auth";
    breadcrumb: (params: LocationGenerics["Params"]) => ReactElement;
    section?: "General" | "Structure" | "Configuration" | "Users & Profiles";
  };
}>;

const location = new ReactLocation({
  parseSearch,
  stringifySearch,
});

export default location;
