import moment from 'moment';
import * as Yup from 'yup';

export const AssigneeInfoFormSchema = Yup.object().shape({
  assigneeType: Yup.string().required(),
  isCompliant: Yup.string().notRequired(),
  assignee: Yup.object().required()
})

export const MeterInfoFormSchema = Yup.object().shape({
  meter: Yup.object().required()
})

export const ExtraInfoFormSchema = Yup.object().shape({
  estimatedResolutionDate: Yup.date().min(moment().startOf("day").toDate()).required(),
  priority: Yup.string().oneOf(["Low", "Medium", "High", "Critical"]).required(),
  notes: Yup.string().notRequired(),
})

export const AssignServiceOrderFormSchema = Yup.object().shape({
  assigneeInfo: AssigneeInfoFormSchema,
  meterInfo: MeterInfoFormSchema,
  extraInfo: ExtraInfoFormSchema
})