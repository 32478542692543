import { useState } from "react";
import { classNames, useDownloadFile, wrapClick } from "utils";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  Modal,
  OrderInitiationInitiationsView,
  OrderInitiationView,
} from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { Action } from "components/buttons/action-button";
import lodash from "lodash";
import toast from "react-hot-toast";

export const GET_SERVICE_ORDER = gql`
  query GetReplacementServiceOrderInitiation($id: ID!) {
    workOrder: getReplacementServiceOrderInitiation(id: $id) {
      _id
      code
      status
      initiations {
        _id
        status
        statusReason
        creditAmount
        debtAmount
        estimatedResolutionDate
        ghanaPostAddress
        phoneNumber
        replacementReason
        servicePointCode
      }
      meta {
        totalInitiations
        totalFailedInitiations
        totalSuccessInitiations
      }
      createdBy {
        _id
        code
        lastName
        firstName
        emailAddress
        phoneNumber
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_SERVICE_ORDER_EXPORT_URL = gql`
query Query($id: ID!) {
  getReplacementServiceOrderInitiationExportUrl(id: $id)
}
`;

const orderTabs = [
  {
    name: "Work Order Details",
    href: "WorkOrderDetails",
  },
  {
    name: "Successful Initiations",
    href: "SuccessfulInitiations",
  },
  {
    name: "Failed Initiations",
    href: "FailedInitiations",
  },
];

export default function ViewReplacementServiceOrderInitiationContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("WorkOrderDetails");
  const { data, loading } = useQuery(GET_SERVICE_ORDER, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });
  const [createDataExport, { loading: gettingUrl }] = useLazyQuery(
    GET_SERVICE_ORDER_EXPORT_URL,
    {
      fetchPolicy: "no-cache",
    }
  );
  const dispatchAction =
    (action: Exclude<Action, "expand" | "goto" | "clone">) => () => {
      navigate({
        search: (old) => ({
          ...old,
          modal: action,
        }),
      });
    };
  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };

  const { downloadAction, downloadLoading } = useDownloadFile({
    onError: () => {
      toast(
        JSON.stringify({
          type: "error",
          title: "An error occurred downloading file",
        })
      );
    },
    getFileName: () => new Date().toISOString() + "_FailedInitiations.xlsx",
  });

  const handleDownloadItem = () => {
    createDataExport({
      variables: {
        id: data?.workOrder?._id,
      },
      fetchPolicy: "no-cache",
    }).then(({ data }) => {
      if (data.getReplacementServiceOrderInitiationExportUrl) {
        downloadAction(data.getReplacementServiceOrderInitiationExportUrl);
      } else {
        toast(
          JSON.stringify({ type: "error", title: "Could not export file" })
        );
      }
    });
  };



  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      hidePadding={true}
      title="Bulk Replacement Information"
      size="6xl"
      description="Details of replacement upload are shown below"
      renderActions={() => (
        <>
          {orderTab === "FailedInitiations" && (
            <button
              type="button"
              onClick={wrapClick(handleDownloadItem)}
              disabled={gettingUrl || downloadLoading}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Download Failed Initiations
            </button>
          )}
        </>
      )}
    >
      {data?.workOrder?._id && (
        <div className="flex-1 flex flex-col overflow-hidden">
          <div className="block">
            <div className="border-b border-gray-200 bg-white px-6">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {orderTabs.map((_orderTab) => (
                  <button
                    key={_orderTab.name}
                    onClick={wrapClick(__setOrderTab(_orderTab.href))}
                    className={classNames(
                      orderTab === _orderTab.href
                        ? "border-primary-500 text-primary-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                      "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                    )}
                    aria-current={
                      orderTab === _orderTab.href ? "page" : undefined
                    }
                  >
                    {_orderTab.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
          <div className="flex flex-col w-full h-[75vh] overflow-y-auto">
            {orderTab === "WorkOrderDetails" && (
              <OrderInitiationView workOrder={data?.workOrder} />
            )}
            {orderTab === "SuccessfulInitiations" && (
              <OrderInitiationInitiationsView
                initiations={lodash.filter(data?.workOrder?.initiations, [
                  "status",
                  "Success",
                ])}
                type="Successful"
              />
            )}
            {orderTab === "FailedInitiations" && (
              <OrderInitiationInitiationsView
                initiations={lodash.filter(data?.workOrder?.initiations, [
                  "status",
                  "Failed",
                ])}
                type="Failed"
              />
            )}
          </div>
        </div>
      )}
    </Modal>
  );
}
