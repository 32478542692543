import { FC, useRef } from 'react'
import { wrapClick } from 'utils';
import { useReactToPrint } from 'react-to-print';
import { EnvelopeIcon, GlobeAltIcon, PhoneIcon } from '@heroicons/react/20/solid';
import numeral from 'numeral';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import moment from 'moment';
import lodash from 'lodash';

const GET_INVOICE = gql`
  query GetInvoice($id: ID!) {
    invoice: getInvoice(id: $id) {
      _id
      code
      type
      invoiceTo {
        fullName
        phoneNumber
        accountNumber
      }
      items {
        title
        unitCost
        quantity
        cost
      }
      invoiceItem
      invoiceItemType
      amount
      status
      paidAt
      createdAt
      updatedAt
    }
  }
`;

interface ApplicationChargeDetailsContainerProps {
  values: any;
  id: string;
  code: string;
  status: string;
  createdAt: string;
  updatedAt: string;
};

const ApplicationChargeDetailsContainer: FC<ApplicationChargeDetailsContainerProps> = ({ values, code }) => {
  const printRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: code,
    bodyClass: "w-[1000px]",
  });

  const { pollInterval } = useReactiveVar(currentConfigVar);

  const { data, loading } = useQuery(GET_INVOICE, {
    variables: {
      id: values?.applicationFee?._id
    },
    fetchPolicy: "network-only"
  });

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div ref={printRef} className="flex-1 flex flex-col overflow-hidden">
        <div className='hidden print:flex flex-row items-center space-x-4 justify-center py-6 border-b border-gray-300'>
          <div>
            <img
              alt="ECG Logo"
              src={require("assets/logo.png")}
              className="h-32 w-32"
            />
          </div>
          <div className='space-y-1 text-gray-900'>
            <h1 className='text-xl text-primary-600 font-bold'>ELECTRICITY COMPANY OF GHANA LIMITED</h1>
            <div className='flex items-center space-x-6'>
              <div className='flex items-center space-x-2'>
                <EnvelopeIcon className='h-4 w-4' />
                <span>P.O BOX GP 521, ACCRA</span>
              </div>
              <div className='flex items-center space-x-2'>
                <PhoneIcon className='h-4 w-4' />
                <span>0302-611-611</span>
              </div>
              <div className='flex items-center space-x-2'>
                <GlobeAltIcon className='h-4 w-4' />
                <span>ecg@ecggh.com</span>
              </div>
            </div>
            <div className='flex items-center space-x-6'>
              <div className='flex items-center space-x-2'>
                <span className='font-semibold'>VAT REG:</span>
                <span>714V000395</span>
              </div>
              <div className='flex items-center space-x-2'>
                <span className='font-semibold'>Website:</span>
                <span>https://www.ecggh.com/</span>
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-6 divide-y divide-gray-200 p-6 overflow-y-auto flex-1">
          <div className='p-4'>
            <div className="flex justify-between items-center cursor-pointer">
              <div>
                <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">Application Charge Information</h3>
                <p className="mt-1 text-xs text-gray-500">Details about application charge</p>
              </div>
              <div />
            </div>
            <div className="mt-6">
              <div className="px-4 py-4 shadow-sm ring-1 ring-gray-900/5 sm:rounded-lg sm:px-4 sm:pb-6 xl:px-8 xl:pb-10 xl:pt-8 relative">
                <h2 className="text-base font-semibold leading-6 text-gray-900">Invoice #{data?.invoice?.code || "N/A"}</h2>
                {data?.invoice?.status === "Paid" && (
                  <img
                    alt="ECG Logo"
                    src={require("assets/paid-stamp.png")}
                    className="h-32 w-32 absolute top-8 right-8"
                  />
                )}
                <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                  <div className="sm:pr-4">
                    <dt className="inline text-gray-500">Issued on</dt>{' '}
                    <dd className="inline text-gray-700">
                      <time dateTime="2023-23-01">{data?.invoice?.createdAt ? moment(data?.invoice?.createdAt).format("MMMM DD, YYYY") : "N/A"}</time>
                    </dd>
                  </div>
                  {data?.invoice?.status === "Paid" ? (
                    <div className="mt-2 sm:mt-0 sm:pl-4">
                      <dt className="inline text-gray-500">Paid on</dt>{' '}
                      <dd className="inline text-gray-700">
                        <time dateTime="2023-31-01">{data?.invoice?.paidAt ? moment(data?.invoice?.paidAt).format("MMMM DD, YYYY") : "N/A"}</time>
                      </dd>
                    </div>
                  ) : (
                    <div className="mt-2 sm:mt-0 sm:pl-4">
                      <dt className="inline text-gray-500">Due on</dt>{' '}
                      <dd className="inline text-gray-700">
                        <time dateTime="2023-31-01">{data?.invoice?.createdAt ? moment(data?.invoice?.createdAt).add(15, "days").format("MMMM DD, YYYY") : "N/A"}</time>
                      </dd>
                    </div>
                  )}
                  <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                    <dt className="font-semibold text-gray-900">From</dt>
                    <dd className="mt-2 text-gray-500">
                      <span className="font-medium text-gray-900">Electricity Company of Ghana Limited</span>
                      <br />
                      P.O BOX GP 521, ACCRA
                      <br />
                      ecg@ecggh.com
                    </dd>
                  </div>
                  <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                    <dt className="font-semibold text-gray-900">To</dt>
                    <dd className="mt-2 text-gray-500">
                      <span className="font-medium text-gray-900">{data?.invoice?.invoiceTo?.fullName}</span>
                      <br />
                      {data?.invoice?.invoiceTo?.phoneNumber}
                      <br />
                      Account Number: {data?.invoice?.invoiceTo?.accountNumber}
                    </dd>
                  </div>
                </dl>
                <table className="mt-16 pt-16 w-full whitespace-nowrap text-left text-sm leading-6 border-t border-gray-900/5">
                  <colgroup>
                    <col className="w-full" />
                    <col />
                  </colgroup>
                  <thead className="border-b border-gray-200 text-gray-900">
                    <tr>
                      <th scope="col" className="px-0 py-3 font-semibold">
                        Item
                      </th>
                      <th scope="col" className="px-0 py-3 pl-8 text-right font-semibold">
                        Unit Price
                      </th>
                      <th scope="col" className="px-0 py-3 pl-8 text-right font-semibold">
                        Quantity
                      </th>
                      <th scope="col" className="px-0 py-3 pl-8 text-right font-semibold">
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.invoice?.items?.map((item: any, key: number) => (
                      <tr key={key} className="border-b border-gray-100">
                        <td className="max-w-0 px-0 py-5 align-top">
                          <div className="truncate font-medium text-gray-900">
                            {item?.title || "N/A"}
                          </div>
                        </td>
                        <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                          GHS {numeral(item?.unitCost).format("0,0.00")}
                        </td>
                        <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                          {numeral(item?.quantity).format("0,0")}
                        </td>
                        <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                          GHS {numeral(item?.cost).format("0,0.00")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th
                        scope="row"
                        colSpan={3}
                        className="px-0 pb-0 pt-6 text-right font-normal text-gray-700"
                      >
                        Subtotal
                      </th>
                      <td className="pb-0 pl-8 pr-0 pt-6 text-right tabular-nums text-gray-900">
                        GHS {numeral(data?.invoice?.amount).format("0,0.00")}
                      </td>
                    </tr>
                    {/* <tr>
                      <th
                        scope="row"
                        colSpan={3}
                        className="pt-4 text-right font-normal text-gray-700"
                      >
                        Tax
                      </th>
                      <td className="pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-900">
                        GHS {numeral(data?.invoice?.amount).format("0,0.00")}
                      </td>
                    </tr> */}
                    <tr>
                      <th
                        scope="row"
                        colSpan={3}
                        className="pt-4 text-right font-semibold text-gray-900"
                      >
                        Total
                      </th>
                      <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900">
                        GHS {numeral(data?.invoice?.amount).format("0,0.00")}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(handlePrint)}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Print
        </button>
        {/* <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Previous
        </button> */}
      </div>
    </div>
  )
}

export default ApplicationChargeDetailsContainer
