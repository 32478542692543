import { FC } from "react";
import { Avatar, TextArea } from "components/core";
import moment from "moment";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import _ from "lodash";
import { FormikProps } from "formik";

type InitiateInvestigationServiceOrderFormProps = {
  form: FormikProps<any>;
  customer: any;
};
const InitiateInvestigationServiceOrderForm: FC<
  InitiateInvestigationServiceOrderFormProps
> = ({ customer, form }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div className=''>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
              Customer Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>Details about customer</p>
          </div>
          <div />
        </div>
        <div className='mt-6'>
          <div className='border mt-2 border-gray-300 border-dashed bg-gray-100 divide-y divide-dashed divide-gray-300 rounded-md'>
            {customer?.customerType === "Individual" && (
              <div className=''>
                <div className='grid grid-cols-4 gap-6 mt-2'>
                  <div className='row-span-2 items-center justify-center flex flex-col'>
                    <Avatar
                      alt={customer?.customerRepresentative.fullName || "N A"}
                      src={
                        customer?.customerRepresentative.profileImageUrl || ""
                      }
                      size='lg'
                    />
                  </div>
                  <div className=''>
                    <span className='block text-sm font-light text-gray-700'>
                      Title
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {customer?.customerRepresentative?.title || "N/A"}
                    </div>
                  </div>
                  <div className='col-span-2'>
                    <span className='block text-sm font-light text-gray-700'>
                      Full Name
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {customer?.customerRepresentative?.fullName || "N/A"}
                    </div>
                  </div>
                  <div className=''>
                    <span className='block text-sm font-light text-gray-700'>
                      Nationality
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {customer?.customerRepresentative?.nationality || "N/A"}
                    </div>
                  </div>
                  <div className=''>
                    <span className='block text-sm font-light text-gray-700'>
                      Date of Birth
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {moment(
                        customer?.customerRepresentative?.dateOfBirth
                      ).format(dateFormat)}
                    </div>
                  </div>
                  <div className=''>
                    <span className='block text-sm font-light text-gray-700'>
                      Gender
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {customer?.customerRepresentative?.gender || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {customer?.customerType === "Organization" && (
              <div className=''>
                <span className='text-xs font-light'>
                  Organization Information
                </span>
                <div className='grid grid-cols-4 gap-6 mt-2'>
                  <div className='col-span-2'>
                    <span className='block text-sm font-light text-gray-700'>
                      Name
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {customer?.customerOrganization?.name || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {customer?.customer?.customerType === "Individual" && (
              <div className='pt-6'>
                <span className='text-xs font-light'>Contact Information</span>
                <div className='grid grid-cols-4 gap-6 mt-2'>
                  <div className=''>
                    <span className='block text-sm font-light text-gray-700'>
                      Phone Number
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {customer?.customerRepresentative?.phoneNumber || "N/A"}
                    </div>
                  </div>
                  <div className='col-span-2'>
                    <span className='block text-sm font-light text-gray-700'>
                      Email Address
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {customer?.customerRepresentative?.emailAddress || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {customer?.customerType === "Organization" && (
              <div className='pt-6'>
                <span className='text-xs font-light'>
                  Representative Information
                </span>
                <div className='grid grid-cols-4 gap-6 mt-2'>
                  <div className='col-start-1'>
                    <span className='block text-sm font-light text-gray-700'>
                      Title
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {customer?.customerRepresentative?.title || "N/A"}
                    </div>
                  </div>
                  <div className='col-span-2'>
                    <span className='block text-sm font-light text-gray-700'>
                      Full Name
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {customer?.customerRepresentative?.fullName || "N/A"}
                    </div>
                  </div>
                  <div className=''>
                    <span className='block text-sm font-light text-gray-700'>
                      Nationality
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {customer?.customerRepresentative?.nationality || "N/A"}
                    </div>
                  </div>
                  <div className=''>
                    <span className='block text-sm font-light text-gray-700'>
                      Phone Number
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {customer?.customerRepresentative?.phoneNumber || "N/A"}
                    </div>
                  </div>
                  <div className=''>
                    <span className='block text-sm font-light text-gray-700'>
                      Email Address
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {customer?.customerRepresentative?.emailAddress || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className='grid grid-cols-4 gap-x-4 gap-y-6  p-4'>
              <div className=''>
                <span className='block text-sm font-light text-gray-700'>
                  Account Number
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {customer?.accountCode || "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Customer Number
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {customer?.customerCode || "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Property Number
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {customer?.propertyCode || "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Service Point Number
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {customer?.code || "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Geographical Code
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {customer?.geoCode || "N/A"}
                </div>
              </div>
              <div className='col-span-2'>
                <span className='block text-sm font-light text-gray-700'>
                  Property Address
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {customer?.property?.address || "N/A"}
                </div>
              </div>
            </div>
            <div className='grid grid-cols-4 gap-x-4 gap-y-6  p-4'>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Meter Number
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {customer?.meter?.code || "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Meter Model
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {customer?.meter?.model?.code || "N/A"} -{" "}
                  {customer?.meter?.model?.name || "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Meter Make
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {customer?.meter?.brand?.code || "N/A"} -{" "}
                  {customer?.meter?.brand?.name || "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Meter Type
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {customer?.meter?.modelType || "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {JSON.stringify(customer, null, 2)} */}
      {/* Service Information for Meter */}

      <div className=''>
        <div className='grid grid-cols-2 gap-6 mt-4'>
          <div className='col-span-2'>
            <TextArea
              id='notes'
              label='Notes'
              rows={4}
              placeholder='e.g. Investigation notes ...'
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitiateInvestigationServiceOrderForm;
