import { FC } from "react";
import { Avatar } from "components/core";
import { classNames } from "utils";
import { Application, UserPermission } from "apollo/cache/auth";

interface UserViewProps {
  user: {
    code: string;
    lastName: string;
    firstName: string;
    ghanaCardNumber: string;
    phoneNumber: string;
    emailAddress: string;
    gender: string;
    profileImageUrl: string;
    regionCode: string;
    regionName: string;
    districtCode: string;
    districtName: string;
    role: {
      code: string;
      name: string;
      type: string;
      expiresAt: string;
      description: string;
      applications: Application[];
      permissions: UserPermission[];
      status: string;
    };
    meta: {
      isPasswordSet: boolean;
      isBlocked: boolean;
      isDeleted: boolean;
      lastLoginAt: Date;
    };
  };
}

const UserView: FC<UserViewProps> = ({ user }) => {
  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div>
        <span className='text-xs font-light'>Staff Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div className='row-span-3 flex flex-col space-y-3 items-center justify-center'>
            <Avatar
              alt={
                [
                  (user?.lastName || "")?.trim(),
                  (user?.firstName || "")?.trim(),
                ]
                  .join(" ")
                  .trim() || "N A"
              }
              src={user?.profileImageUrl || ""}
              size='xl'
            />

            <span
              className={classNames(
                user?.role?.name === "SuperAdmin"
                  ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50`
                  : "",
                user?.role?.name === "Admin"
                  ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50`
                  : "",
                user?.role?.name === "Supervisor"
                  ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                  : "",
                user?.role?.name === "Reader"
                  ? `bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50`
                  : "",
                user?.role?.name === "Queued"
                  ? `bg-gray-100 text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50`
                  : "",
                user?.role?.name === "Success"
                  ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                  : "",
                user?.role?.name === "Reversed"
                  ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                  : "",
                !user?.role?.name
                  ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                  : "",
                "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
              )}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 10 10'
                fill='currentColor'
                className='w-1.5 h-1.5'
              >
                <circle
                  fillRule='evenodd'
                  cx='5'
                  cy='5'
                  r='5'
                  clipRule='evenodd'
                />
              </svg>
              <span>{user?.role?.name || "Unknown"}</span>
            </span>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Staff ID
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {user?.code || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Gender
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {user?.gender || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Last Name
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {user?.lastName || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Other Names
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {user?.firstName || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Region
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {user?.regionName || "N/A"} ({user?.regionCode || "N/A"})
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              District
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {user?.districtName || "N/A"} ({user?.districtCode || "N/A"})
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Contact Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Email Address
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {user?.emailAddress || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Phone Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {user?.phoneNumber || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Ghana Card
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {user?.ghanaCardNumber || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserView;
