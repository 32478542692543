import { FC } from "react";
import moment from "moment";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { Avatar } from "components/core";
import { classNames } from "utils";
import lodash from "lodash";

interface InstallationServiceOrderViewProps {
  installationServiceOrder: {
    assigneeType: string;
    contractorAssigner: any;
    installationMeterSystemSyncStatus: string;
    meterContractor: any;
    farmingOutContractor: any;
    farmingOutContractorUser: any;
    servicePoint: any;
    servicePointCode: string;
    requestedMeterPhase: string;
    installationType: {
      code: string;
      name: string;
    };
    installationMeter: {
      modelMeta: {
        brandName: string;
        modelName: string;
        phase: string;
      };
      code: string;
    };
    request: {
      inspection: any;
      type: string;
      response: {
        servicePoint: {
          code: string;
        };
      };
      resolution: {
        service: {
          meterPhase: string;
        };
      };
    };

    category: string | undefined;
    code: string;
    status: string;
    type: string;
    priority: string;
    region: {
      code: string;
      name: string;
    };
    district: {
      code: string;
      name: string;
    };
    contactPerson: {
      fullName: string;
      phoneNumber: string;
    };
    location: {
      address: string;
      community: string;
    };
    assigner: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    assignee: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    approver: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    disapprover: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    estimatedResolutionDate: string;
    createdAt: string;
    updatedAt: string;
  };
}

function Owner({
  owner,
  type,
}: {
  owner: any;
  type: "field-agents" | "staff-accounts";
}) {
  return (
    <div
      className={classNames(
        "border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 hover:border-gray-400 dark:hover:border-gray-500",
        "focus:outline-none relative rounded-lg border p-2 shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
      )}
    >
      <div className='flex-shrink-0'>
        <Avatar
          alt={
            [
              (owner?.lastName || "")?.trim(),
              (owner?.firstName || owner?.name || "")?.trim(),
            ]
              .join(" ")
              .trim() || "N A"
          }
          src={owner?.profileImageUrl || ""}
        />
      </div>
      <div className='flex-1 min-w-0 text-left'>
        <p className='text-sm font-medium text-gray-900 dark:text-white truncate'>
          {[
            (owner?.lastName || "")?.trim(),
            (owner?.firstName || owner?.name || "")?.trim(),
          ]
            .join(" ")
            .trim() || "N A"}
        </p>
        <p className='text-sm text-gray-500 dark:text-gray-400 truncate'>
          {owner?.phoneNumber || owner?.code || "N/A"}
        </p>
      </div>
    </div>
  );
}

const InstallationServiceOrderView: FC<InstallationServiceOrderViewProps> = ({
  installationServiceOrder,
}) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div className=''>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-4 font-normal text-gray-900 dark:text-gray-100'>
              Request Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>
              Details about initial reading and meter details from prepayment
              system
            </p>
          </div>
          <div />
        </div>
        <div className='mt-4 space-y-3'>
          <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
            <div className='grid grid-cols-3 gap-4'>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Code
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {installationServiceOrder?.code || "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Date
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {installationServiceOrder?.createdAt
                    ? moment(installationServiceOrder.createdAt).format(
                        dateFormat
                      )
                    : "N/A"}
                </div>
              </div>
              <div className=''>
                <span className='block text-sm font-light text-gray-700'>
                  Estimated Resolution Date
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {installationServiceOrder?.estimatedResolutionDate
                    ? moment(
                        installationServiceOrder.estimatedResolutionDate
                      ).format(dateFormat)
                    : "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Status
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {lodash.startCase(installationServiceOrder?.status) || "N/A"}
                </div>
              </div>
              {[
                "AssignedToInstaller",
                "InProgress",
                "Resolved",
                "Completed",
              ].includes(installationServiceOrder.status) && (
                <div className=''>
                  <span className='block text-sm font-light text-gray-700'>
                    Prepayment Sync Status
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {installationServiceOrder?.installationMeterSystemSyncStatus ||
                      "N/A"}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
            <div className='grid grid-cols-3 gap-4'>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Tariff Class
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {installationServiceOrder?.servicePoint?.tariffClassCode ||
                    "N/A"}{" "}
                  (
                  {installationServiceOrder?.servicePoint?.tariffClassName ||
                    "N/A"}
                  )
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Requested Meter Phase
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {installationServiceOrder?.requestedMeterPhase
                    ?.toLowerCase?.()
                    ?.replace("phase", "")
                    ?.trim?.() || "N/A"}{" "}
                  Phase
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='pt-6'>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-4 font-normal text-gray-900 dark:text-gray-100'>
              Customer Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>
              Details about initial reading and meter details from prepayment
              system
            </p>
          </div>
          <div />
        </div>
        <div className='mt-4 space-y-3'>
          <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
            <div className='grid grid-cols-3 gap-4'>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Service Point Number
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {installationServiceOrder?.servicePointCode || "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Full Name
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {installationServiceOrder?.contactPerson?.fullName || "N/A"}
                </div>
              </div>

              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Phone Number
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {installationServiceOrder?.contactPerson?.phoneNumber ||
                    "N/A"}
                </div>
              </div>
            </div>
          </div>
          <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
            <div className='grid grid-cols-3 gap-4'>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Region
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {installationServiceOrder?.region?.name || "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  District
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {installationServiceOrder?.district?.name || "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Community
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {installationServiceOrder?.location?.community || "N/A"}
                </div>
              </div>

              <div className=''>
                <span className='block text-sm font-light text-gray-700'>
                  Address
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {installationServiceOrder?.location?.address || "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {["AssignedToInstaller", "InProgress", "Resolved", "Completed"].includes(
        installationServiceOrder?.status
      ) && (
        <div className='pt-6'>
          <div className='flex justify-between items-center cursor-pointer'>
            <div>
              <h3 className='text-md leading-4 font-normal text-gray-900 dark:text-gray-100'>
                Assignment Information
              </h3>
              <p className='mt-1 text-xs text-gray-500'>
                Details about initial reading and meter details from prepayment
                system
              </p>
            </div>
            <div />
          </div>
          <div className='mt-4 space-y-3'>
            <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
              <div className='grid grid-cols-3 gap-4'>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Meter Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {installationServiceOrder?.installationMeter?.code || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Meter Make & Model
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {installationServiceOrder?.installationMeter?.modelMeta
                      ?.brandName || "N/A"}{" "}
                    -
                    {installationServiceOrder?.installationMeter?.modelMeta
                      ?.modelName || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Meter Phase
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {installationServiceOrder?.installationMeter?.modelMeta
                      ?.phase || "N/A"}
                  </div>
                </div>
              </div>
            </div>

            <div className='grid grid-cols-3 gap-6 mt-2'>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Assigned By
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  <Owner
                    owner={installationServiceOrder?.assigner}
                    type='staff-accounts'
                  />
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Assignee (
                  {installationServiceOrder?.assigneeType ===
                  "MeterManufacturingContractor"
                    ? "Meter Supplier"
                    : lodash.startCase(installationServiceOrder?.assigneeType)}
                  )
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  <Owner
                    owner={
                      installationServiceOrder?.assignee ||
                      installationServiceOrder?.meterContractor ||
                      installationServiceOrder?.farmingOutContractor
                    }
                    type='field-agents'
                  />
                </div>
              </div>
              {installationServiceOrder?.assigneeType ===
                "MeterManufacturingContractor" &&
                installationServiceOrder.farmingOutContractor && (
                  <>
                    <div>
                      <span className='block text-sm font-light text-gray-700'>
                        Farming Out Contractor
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        <Owner
                          owner={installationServiceOrder?.farmingOutContractor}
                          type='field-agents'
                        />
                      </div>
                    </div>
                    {/* <div>
                      <span className="block text-sm font-light text-gray-700">
                        Farming Out Agent
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        <Owner
                          owner={
                            installationServiceOrder?.farmingOutContractorUser
                          }
                          type="field-agents"
                        />
                      </div>
                    </div> */}
                  </>
                )}
            </div>
            <div className='grid grid-cols-3 gap-6 mt-2'>
              {installationServiceOrder?.status == "Disapproved" && (
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Disapprover
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    <Owner
                      owner={installationServiceOrder?.disapprover}
                      type='staff-accounts'
                    />
                  </div>
                </div>
              )}
              {installationServiceOrder?.status == "Completed" && (
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Approver
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    <Owner
                      owner={installationServiceOrder?.approver}
                      type='staff-accounts'
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InstallationServiceOrderView;
