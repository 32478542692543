import { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { Modal, ContractorUserView } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";

export const GET_CONTRACTOR_USER = gql`
  query GetContractorUser($id: ID!) {
    user: getContractorUser(id: $id) {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      gender
      profileImageUrl
      meta {
        isPasswordSet
        isBlocked
        isDeleted
        lastLoginAt
      }
      createdAt
      updatedAt
    }
  }
`;

interface ViewContractorUserContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
}

const ViewContractorUserContainer: FC<ViewContractorUserContainerProps> = ({
  open,
  setOpen,
}) => {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_CONTRACTOR_USER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update",
      }),
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="User Information"
      description="Details of user are shown below"
    >
      {data?.user?._id && <ContractorUserView user={data?.user} />}
    </Modal>
  );
};

export default ViewContractorUserContainer;
