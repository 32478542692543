import { gql, useQuery, useReactiveVar } from "@apollo/client";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  IdentificationIcon,
} from "@heroicons/react/24/outline";
import { currentConfigVar } from "apollo/cache/config";
import moment from "moment";
import numeral from "numeral";
import { FC } from "react";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { Disclosure } from "@headlessui/react";
import { Avatar } from "components";

const OVERVIEW_SUMMARY = gql`
  query GetDailyReadersSummary(
    $readingDate: Date!
    $region: ID
    $district: ID
    $month: Date
    $year: Date
  ) {
    readerStats: getDailyReadersSummary(
      readingDate: $readingDate
      region: $region
      district: $district
      month: $month
      year: $year
    ) {
      _id
      reader {
        _id
        code
        emailAddress
        firstName
        lastName
        phoneNumber
        profileImageUrl
      }
      customersReadCount
      successCustomersReadCount
      exceptionCustomersReadCount
      anomalyCustomersReadCount
      noTraceCustomersReadCount
      totalBilled
      totalConsumption
      propertiesCount
      itinerariesCount
      startDate
      endDate
    }
  }
`;

const ReadingOverviewContainer: FC = () => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();

  const { data } = useQuery(OVERVIEW_SUMMARY, {
    variables: {
      region: searchParams?.region || undefined,
      district: searchParams?.district || undefined,
      month: moment(searchParams?.fromDate || new Date()).month() + 1,
      year: moment(searchParams?.fromDate || new Date()).year(),
      readingDate: moment(searchParams?.fromDate || new Date()).toDate(),
    },
  });

  return (
    <div>
      <h3 className="text-lg font-medium leading-6 text-gray-900">
        Readers Overview
      </h3>
      <div className="overflow-hidden bg-white shadow sm:rounded-md mt-5">
        <dl role="list" className="divide-y divide-gray-200">
          {data?.readerStats?.map((readerStat: any) => (
            <Disclosure as="div" key={readerStat._id}>
              {({ open }) => (
                <>
                  <dt className="flex">
                    <Disclosure.Button className="flex hover:bg-gray-50 flex-1">
                      <div className="flex flex-1 items-center px-4 py-4 sm:px-6">
                        <div className="flex min-w-0 flex-1 items-center">
                          <div className="flex-shrink-0">
                            <Avatar
                              alt={[
                                readerStat?.reader?.firstName || "N",
                                readerStat?.reader?.lastName || "A",
                              ].join(" ")}
                              src={readerStat?.reader?.profileImageUrl}
                            />
                          </div>
                          <div className="min-w-0 flex-1 px-4 flex space-x-6 text-left">
                            <div className="w-48">
                              <p className="truncate text-sm font-medium text-primary-600">
                                {[
                                  readerStat?.reader?.firstName || "",
                                  readerStat?.reader?.lastName || "",
                                ]
                                  .join(" ")
                                  .trim()}
                              </p>
                              <p className="mt-2 flex items-center text-sm text-gray-500">
                                <IdentificationIcon
                                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span className="truncate">
                                  {readerStat?.reader?.code}
                                </span>
                              </p>
                            </div>
                            {readerStat?.startDate ? (
                              <div className="flex-1 flex flex-col justify-between">
                                <div className="text-sm text-gray-900 text-right">
                                  Started at{" "}
                                  <time dateTime={readerStat?.startDate}>
                                    {moment(readerStat?.startDate).format(
                                      "DD/MM/YYYY hh:mm a"
                                    )}
                                  </time>
                                </div>
                                <div className="text-sm text-gray-500 text-right">
                                  Last reading at{" "}
                                  <time dateTime={readerStat?.endDate}>
                                    {moment(readerStat?.endDate).format(
                                      "DD/MM/YYYY hh:mm a"
                                    )}
                                  </time>
                                </div>
                              </div>
                            ) : (
                              <div className="flex-1 flex flex-col justify-center">
                                <div className="text-sm text-gray-900 text-right">
                                  Not Started
                                </div>
                              </div>
                            )}
                            <div className="flex-1 flex flex-col justify-between">
                              <div className="text-sm text-gray-900 text-right">
                                {numeral(readerStat?.totalConsumption).format(
                                  "0,0"
                                )}{" "}
                                KWh Read
                              </div>
                              <div className="text-sm text-gray-500 text-right">
                                GHc{" "}
                                {numeral(readerStat?.totalBilled).format(
                                  "0,0.00"
                                )}{" "}
                                Billed
                              </div>
                            </div>
                            <div className="flex-1 flex flex-col justify-between">
                              <div className="text-sm text-gray-900 text-right">
                                {numeral(readerStat?.itinerariesCount).format(
                                  "0,0"
                                )}{" "}
                                Itineraries
                              </div>
                              <div className="text-sm text-gray-500 text-right">
                                {numeral(readerStat?.propertiesCount).format(
                                  "0,0"
                                )}{" "}
                                Properties
                              </div>
                            </div>
                            <div className="flex-1 flex flex-col justify-between">
                              <div className="text-sm text-gray-900 text-right">
                                {numeral(
                                  readerStat?.successCustomersReadCount
                                ).format("0,0")}{" "}
                                Meters Billed
                              </div>
                              <div className="text-sm text-gray-500 text-right">
                                {numeral(readerStat?.customersReadCount).format(
                                  "0,0"
                                )}{" "}
                                Meters Read
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          {open ? (
                            <ChevronUpIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          ) : (
                            <ChevronDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          )}
                        </div>
                      </div>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="p-8 bg-slate-100">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-5">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Success Readings
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {numeral(
                            readerStat?.successCustomersReadCount
                          ).format("0,0")}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Exception Readings
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {numeral(
                            readerStat?.exceptionCustomersReadCount
                          ).format("0,0")}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Anomaly Readings
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {numeral(
                            readerStat?.anomalyCustomersReadCount
                          ).format("0,0")}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          No Trace Readings
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {numeral(
                            readerStat?.noTraceCustomersReadCount
                          ).format("0,0")}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Unlisted Meters
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {numeral(readerStat?.uncapturedMetersCount).format(
                            "0,0"
                          )}
                        </dd>
                      </div>
                      {/* <div className="sm:col-span-5">
                        <dt className="text-sm font-medium text-gray-500">Reading Sets</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <ReaderItinerariesOverviewContainer
                            reader={readerStat?.reader?._id}
                          />
                        </dd>
                      </div> */}
                    </dl>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default ReadingOverviewContainer;
