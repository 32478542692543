import { useState } from "react";
import { classNames, wrapClick } from "utils";
import { gql, useQuery } from "@apollo/client";
import {
  InspectionServiceOrderResolutionView,
  Modal,
  InspectionServiceOrderHistoryView,
  InspectionServiceOrderView,
} from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { Action } from "components/buttons/action-button";

export const GET_INSPECTION_SERVICE_ORDER = gql`
  query GetInspectionServiceOrder($id: ID!) {
    inspectionServiceOrder: getInspectionServiceOrder(id: $id) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      contactPerson {
        fullName
        phoneNumber
      }
      location {
        address
        community
      }
      assigner {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      approver {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      disapprover {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      assignee {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      resolution {
        installationType {
          _id
          code
          name
        }
        property {
          ghanaPostAddress
          propertyImageUrls
          closestPoleNumber
          transformerPhase
          currentTransformerRatio
          meterHeight
          premiseType {
            _id
            code
            name
          }
          premiseCategory {
            _id
            code
            name
          }
          activity {
            _id
            code
            name
          }
          subActivity {
            _id
            code
            name
          }
          transformerNumber
          meterLocation
        }
        service {
          serviceType
          serviceClass
          tariffClass {
            _id
            code
            name
          }
          contractedDemand
          meterPhase
        }
        appliances {
          appliance {
            _id
            code
            name
            wattage
          }
          quantity
        }
        materials {
          material {
            _id
            code
            name
            quantityUnit
          }
          quantity
        }
        notes
      }
      category
      status
      priority
      history {
        actor {
          ... on Contractor {
            _id
            name
            code
          }
          ... on ContractorUser {
            _id
            firstName
            lastName
            username
            code
          }
          ... on MeterContractor {
            _id
            name
            code
          }
          ... on MeterContractorUser {
            _id
            firstName
            lastName
            code
          }
          ... on User {
            _id
            firstName
            lastName
            code
          }
        }
        assignee {
          ... on Contractor {
            _id
            name
            code
          }
          ... on ContractorUser {
            _id
            firstName
            lastName
            username
            code
          }
          ... on MeterContractor {
            _id
            name
            code
          }
          ... on MeterContractorUser {
            _id
            firstName
            lastName
            code
          }
          ... on User {
            _id
            firstName
            lastName
            code
          }
        }
        actorType
        assigneeType
        timestamp
        action
        notes
      }
      notes
      estimatedResolutionDate
      assignedAt
      startedAt
      resolvedAt
      completedAt
      rejectedAt
      createdAt
      updatedAt
      result
    }
  }
`;

const orderTabs = [
  {
    name: "Order Details",
    href: "OrderDetails",
    activeStatues: [
      "Pending",
      "Assigned",
      "Resolved",
      "Completed",
      "InProgress",
    ],
  },
  {
    name: "Order History",
    href: "OrderHistory",
    activeStatues: [
      "Pending",
      "Assigned",
      "Resolved",
      "Completed",
      "InProgress",
    ],
  },
  {
    name: "Resolution Details",
    href: "ResolutionDetails",
    activeStatues: ["Resolved", "Completed"],
  },
];

export default function ViewInspectionServiceOrderContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("OrderDetails");
  const { data, loading } = useQuery(GET_INSPECTION_SERVICE_ORDER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });
  const dispatchAction =
    (action: Exclude<Action, "expand" | "goto" | "clone">) => () => {
      navigate({
        search: (old) => ({
          ...old,
          modal: action,
        }),
      });
    };
  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen?.(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      loading={loading}
      hidePadding
      title='Service Order Information'
      size='4xl'
      description='Details of reading set are shown below'
      renderActions={() => (
        <>
          {["Pending", "Assigned", "InProgress"].includes(
            data?.inspectionServiceOrder?.status
          ) && (
            <>
              {!data?.inspectionServiceOrder?.assignee ? (
                <button
                  type='button'
                  className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
                  onClick={wrapClick(dispatchAction("assign"))}
                >
                  Assign
                </button>
              ) : (
                <button
                  type='button'
                  className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
                  onClick={wrapClick(dispatchAction("reassign"))}
                >
                  Reassign
                </button>
              )}
            </>
          )}
          {/* {["InProgress"].includes(data?.inspectionServiceOrder?.status) && (
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(dispatchAction("resolve"))}
            >
              Resolve
            </button>
          )} */}
          {["Resolved"].includes(data?.inspectionServiceOrder?.status) && (
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(dispatchAction("approve"))}
            >
              Approve
            </button>
          )}
          {["Resolved"].includes(data?.inspectionServiceOrder?.status) && (
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(dispatchAction("disapprove"))}
            >
              Disapprove
            </button>
          )}
        </>
      )}
    >
      {data?.inspectionServiceOrder?._id && (
        <>
          {["Assigned", "InProgress", "Resolved", "Completed"].includes(
            data?.inspectionServiceOrder?.status
          ) && (
            <div className='block'>
              <div className='border-b border-gray-200 bg-white px-6'>
                <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
                  {orderTabs.map((_orderTab) => (
                    <button
                      key={_orderTab.name}
                      disabled={
                        !_orderTab.activeStatues.includes(
                          data?.inspectionServiceOrder?.status
                        )
                      }
                      onClick={wrapClick(__setOrderTab(_orderTab.href))}
                      className={classNames(
                        orderTab === _orderTab.href
                          ? "border-primary-500 text-primary-600"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                        "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                      )}
                      aria-current={
                        orderTab === _orderTab.href ? "page" : undefined
                      }
                    >
                      {_orderTab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          )}
          <div className='flex-1 w-full min-h-[65vh] max-h-[65vh] overflow-y-auto  sm:p-6'>
            {orderTab === "OrderDetails" && (
              <InspectionServiceOrderView
                inspectionServiceOrder={data?.inspectionServiceOrder}
              />
            )}
            {orderTab === "OrderHistory" && (
              <InspectionServiceOrderHistoryView
                inspectionServiceOrder={data?.inspectionServiceOrder}
              />
            )}
            {orderTab === "ResolutionDetails" && (
              <InspectionServiceOrderResolutionView
                region={data?.inspectionServiceOrder?.region}
                district={data?.inspectionServiceOrder?.district}
                resolution={data?.inspectionServiceOrder?.resolution}
                result={data?.inspectionServiceOrder?.result || ""}
              />
            )}
          </div>
        </>
      )}
    </Modal>
  );
}
