import { gql } from "@apollo/client";

export const GET_REPLACEMENT_SERVICE_ORDERS = gql`
  query GetReplacementServiceOrders(
  $page: Int
  $pageSize: Int
  $sort: String
  $search: String
  $searchFields: [String!]
  $region: ID
  $district: ID
  $status: ReplacementServiceOrderStatus
  $priority: ReplacementServiceOrderPriority
  $fromDate: Date
  $toDate: Date
  $dateField: String
) {
  rows: getReplacementServiceOrders(
    page: $page
    pageSize: $pageSize
    sort: $sort
    search: $search
    searchFields: $searchFields
    region: $region
    district: $district
    status: $status
    priority: $priority
    fromDate: $fromDate
    toDate: $toDate
    dateField: $dateField
  ) {
    _id
    code
    region {
      _id
      code
      name
    }
    district {
      _id
      code
      name
    }
    contactPerson {
      fullName
      phoneNumber
    }
    location {
      address
      community
    }
    meterContractor {
      _id
      code
      name
    }
    farmingOutContractor {
      _id
      code
      name
    }
    assigneeType
    assigner {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      profileImageUrl
    }
    approver {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      profileImageUrl
    }
    disapprover {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      profileImageUrl
    }
    assignee {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      profileImageUrl
    }
    status
    priority
    estimatedResolutionDate
    servicePoint {
      _id
      code
      geoCode
      tariffClass {
        _id
        code
        name
      }
    }
    servicePointCode
    replacementType
    disapprovedAt
    meter {
      _id
      code
      modelMeta {
        brandCode
        brandName
        systemCode
        systemName
        modelCode
        modelName
        phase
      }
    }
    installationMeter {
      _id
      code
      modelMeta {
        brandCode
        brandName
        systemCode
        systemName
        modelCode
        modelName
      }
    }
    installationMeterSystemCode
    installationMeterSystemSyncStatus
    assignedAt
    startedAt
    resolvedAt
    completedAt
    rejectedAt
    disapprovedAt
    createdAt
    updatedAt
    oldMeterNumber
    replacementReason
  }
  count: getReplacementServiceOrdersCount(
    search: $search
    searchFields: $searchFields
    region: $region
    district: $district
    status: $status
    priority: $priority
    fromDate: $fromDate
    toDate: $toDate
    dateField: $dateField
  )
}

`;

export const GET_REPLACEMENT_SERVICE_ORDER_INITIATIONS = gql`
  query GetReplacementServiceOrderInitiations(
  $page: Int
  $pageSize: Int
  $search: String
  $searchFields: [String!]
  $sort: String
  $region: ID
  $district: ID
  $status: ReplacementServiceOrderInitiationStatus
  $fromDate: Date
  $toDate: Date
) {
  rows: getReplacementServiceOrderInitiations(
    page: $page
    pageSize: $pageSize
    search: $search
    searchFields: $searchFields
    sort: $sort
    region: $region
    district: $district
    status: $status
    fromDate: $fromDate
    toDate: $toDate
  ) {
    _id
    code
    status
    createdAt
    updatedAt
    initiations {
      _id
      servicePointCode
      estimatedResolutionDate
      replacementReason
      debtAmount
      creditAmount
      phoneNumber
      ghanaPostAddress
      status
      statusReason
    }
    contractor {
      _id
      code
      name
    }
    region {
      _id
      code
      name
    }
    meta {
      totalFailedInitiations
      totalInitiations
      totalSuccessInitiations
    }
    createdBy {
      _id
      code
      lastName
      firstName
      emailAddress
      phoneNumber
    }
    district {
      _id
      code
      name
    }
  }
  count: getReplacementServiceOrderInitiationsCount(
    search: $search
    searchFields: $searchFields
    region: $region
    district: $district
    status: $status
    fromDate: $fromDate
    toDate: $toDate
  )
}

`;
