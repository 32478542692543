import { FC } from "react";
import numeral from "numeral";
import _ from "lodash";

const ServiceInfoSummary: FC<{
  data: any;
}> = ({ data }) => (
  <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
    <div className=''>
      <span className='text-xs font-light'>Service Information</span>
      <div className='grid grid-cols-3 gap-6 mt-2'>
        <div>
          <span className='block text-sm font-light text-gray-700'>
            Activity
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.activity?.name || "N/A"}
          </div>
        </div>
        <div>
          <span className='block text-sm font-light text-gray-700'>
            Sub Activity
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.subActivity?.name || "N/A"}
          </div>
        </div>
        <div>
          <span className='block text-sm font-light text-gray-700'>
            Service Class
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.serviceClass ? _.startCase(data.serviceClass) : "N/A"}
          </div>
        </div>
      </div>
    </div>
    <div className='pt-6'>
      <span className='text-xs font-light'>Meter Information</span>
      <div className='grid grid-cols-3 gap-6 mt-2'>
        <div>
          <span className='block text-sm font-light text-gray-700'>
            Meter Model
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.meterModel || "N/A"}
          </div>
        </div>
        <div>
          <span className='block text-sm font-light text-gray-700'>
            Meter Phase
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.meterPhase || "N/A"}
          </div>
        </div>
        <div>
          <span className='block text-sm font-light text-gray-700'>
            Meter Status
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.meterStatus || "N/A"}
          </div>
        </div>
      </div>
    </div>
    {/* <div className='pt-6'>
      <span className='text-xs font-light'>Demand Information</span>
      <div className='grid grid-cols-3 gap-6 mt-2'>
        <div>
          <span className='block text-sm font-light text-gray-700'>
            Tariff Class
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {_.startCase(data?.tariffClass?.name) || "N/A"}
          </div>
        </div>
      </div>
    </div> */}
  </div>
);

export default ServiceInfoSummary;
