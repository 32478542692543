import moment from "moment";
import * as Yup from "yup";
export const ServiceTypes = ["Postpaid", "Prepaid"] as const;
export type ServiceType = (typeof ServiceTypes)[number];
export const ServiceClasses = [
  "Residential",
  "NonResidential",
  "Industrial",
] as const;
export type ServiceClass = (typeof ServiceClasses)[number];

export interface IResolveReplacementServiceOrderFormSchema {
  serviceInfo: {
    poleNumber: string;
    meterNumber: string;
    meterModel: {
      _id: string;
      code: string;
      make: string;
      model: string;
      digits: number;
    } | null;
  };
  extraInfo: {
    previousReading: {
      readingDate: string;
      readingValue: number;
    };
    currentReading: {
      readingDate: string;
      readingValue: number;
    };
    notes: string;
  };
}

export const ServiceInfoFormSchema = Yup.object().shape({
  poleNumber: Yup.string().notRequired(),
});

export const ExtraInfoFormSchema = Yup.object().shape({
  previousReading: Yup.object().shape({
    readingDate: Yup.date().max(moment().endOf("day").toDate()).required("Enter Reading Date"),
    readingValue: Yup.number().min(0).required("Enter Reading Value"),
  }),
  currentReading: Yup.object().shape({
    readingDate: Yup.date().max(moment().endOf("day").toDate()).required("Enter Reading Date"),
    readingValue: Yup.number().min(0).required("Enter Reading Value"),
  }),
  notes: Yup.string(),
});

export const ResolveReplacementServiceOrderFormSchema = Yup.object().shape({
  serviceInfo: ServiceInfoFormSchema.required(),
  extraInfo: ExtraInfoFormSchema.required(),
});
