import { OfficeHeader, Shimmers, TableComponent } from "components";
import { FC, useEffect, useMemo } from "react";
import { useMatch, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, useUrlState, classNames, useTableData } from "utils";
import config from "config";
import ViewUserContainer from "./view";
import { Avatar } from "components/core";
import ActionButton, { Action } from "components/buttons/action-button";
import { useMeterContractorUsers } from "./hooks";


const MeterContractorUsersPage: FC = () => {
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      contractor: params.meterContractor,
      searchFields: ["code", "lastName", "firstName", "ghanaCardNumber"],
      sort: searchParams.sort || "",
    }),
    [searchParams]
  );

  const { contractorUsers, count, loading, networkStatus, refetch } = useMeterContractorUsers(filter);
  const records = useTableData({ rows: contractorUsers, count });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <OfficeHeader />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"users"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            // renderExport={({ exportOpen, setExportOpen }) => (
            //   <ExportDataContainer
            //     open={exportOpen}
            //     setOpen={setExportOpen}
            //     entityType="User"
            //     filter={filter}
            //   />
            // )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  User
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Contact
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Ghana Card | Gender
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Role
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <Avatar
                        disabled={true}
                        alt={
                          [
                            (item?.lastName || "")?.trim(),
                            (item?.firstName || "")?.trim(),
                          ]
                            .join(" ")
                            .trim() || "N A"
                        }
                        src={item?.profileImageUrl || ""}
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-gray-900 dark:text-gray-100">
                        {[
                          (item?.lastName || "")?.trim(),
                          (item?.firstName || "")?.trim(),
                        ]
                          .join(" ")
                          .trim() || "N A"}
                      </div>
                      <div className="text-gray-500 dark:text-gray-400">
                        {item?.code || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.phoneNumber || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.emailAddress || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.ghanaCardNumber || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.gender || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <span
                    className={classNames(
                      item?.role === "SuperAdmin"
                        ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50`
                        : "",
                      item?.role === "Admin"
                        ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50`
                        : "",
                      item?.role === "Supervisor"
                        ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                        : "",
                      item?.role === "Reader"
                        ? `bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50`
                        : "",
                      item?.role === "Queued"
                        ? `bg-gray-100 text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50`
                        : "",
                      item?.role === "Success"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                        : "",
                      item?.role === "Reversed"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                        : "",
                      !item?.role
                        ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="w-1.5 h-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{item?.role || "Unknown"}</span>
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, "view")}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {params.meterContractor && (
        <>
          {!!searchParams.id?.length && (
            <>
              <ViewUserContainer
                open={modal === "view"}
                setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
              />
            </>
          )}
        </>
      )}
    </main>
  );
};

export default MeterContractorUsersPage;
