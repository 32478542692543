import { gql, useQuery } from '@apollo/client'
import { FC } from 'react';
import { SearchSelectInput } from 'components/core';

interface PremiseCategoryPickerContainerProps {
  filter?: {
    premiseType?: string;
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  rawId?: boolean;
}

const GET_PREMISE_CATEGORIES = gql`
  query GetPremiseCategoriesSelect($premiseType: ID) {
    premiseCategories: getPremiseCategories(premiseType: $premiseType sort: "name") {
      _id
      code
      name
    }
  }
`;

const PremiseCategoryPickerContainer: FC<PremiseCategoryPickerContainerProps> = ({ filter, id, label, rawId, ...form }) => {
  const { loading, data } = useQuery(GET_PREMISE_CATEGORIES, {
    variables: {
      ...(filter ? filter : {})
    },
    notifyOnNetworkStatusChange: false
  })

  return (
    <SearchSelectInput
      id={id ?? "premiseCategory"}
      label={label ?? "Premise Category"}
      placeholder="Select Premise Category"
      optionsLoading={loading}
      options={(data?.premiseCategories ?? [])?.map((premiseCategory: any) => ({
        label: {
          title: premiseCategory.name as string
        },
        value: rawId ? premiseCategory._id : premiseCategory
      }))}
      {...form}
      disabled={form.disabled}
    />
  )
}

export default PremiseCategoryPickerContainer