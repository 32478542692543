import { Avatar } from "components/core";
import lodash from "lodash";

const MeterInfoSummary = ({ data }: any) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3">
    <div className="divide-y-2 divide-gray-300 divide-dashed space-y-6">
      <div>
        <div className="grid grid-cols-4 gap-4">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.meter?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Brand
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.meter?.model?.brandCode || "N/A"} -{" "}
              {data?.meter?.model?.brandName || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Model
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.meter?.model?.code || "N/A"} -{" "}
              {data?.meter?.model?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Type
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.meter?.modelType || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <div className="grid grid-cols-4 gap-4">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Type
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.meter?.model?.type || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Communication Type
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {lodash.startCase(data?.meter?.model?.communicationType) || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Compliance
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.meter?.model?.compliance || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Phase
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.meter?.model?.phase || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Mechanism
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.meter?.model?.mechanism || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Digits
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.meter?.model?.digits || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Voltage
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.meter?.model?.voltage || "N/A"}V
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Current Rating
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.meter?.model?.currentRating || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MeterInfoSummary;
