import { FC } from "react";
import moment from "moment";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { Avatar } from "components/core";
import { classNames, wrapImage } from "utils";
import _ from "lodash";

interface RegularizationInspectionServiceOrderViewProps {
  inspectionServiceOrder: {
    regularizedMeterCode: string | undefined;
    regularization: any;
    servicePointCode: string;
    propertyImageUrls: any;
    servicePoint: any;
    completedAt: string;
    code: string;
    status: string;
    type: string;
    priority: string;
    region: {
      code: string;
      name: string;
    };
    district: {
      code: string;
      name: string;
    };
    contactPerson: {
      fullName: string;
      phoneNumber: string;
    };
    location: {
      address: string;
      community: string;
    };
    assigner: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    assignee: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    approver: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    disapprover: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    estimatedResolutionDate: string;
    createdAt: string;
    updatedAt: string;
  };
}

function Owner({
  owner,
  type,
}: {
  owner: any;
  type: "field-agents" | "staff-accounts";
}) {
  return (
    <div
      className={classNames(
        "border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 hover:border-gray-400 dark:hover:border-gray-500",
        "focus:outline-none relative rounded-lg border px-3 py-3 shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
      )}
    >
      <div className='flex-shrink-0'>
        <Avatar
          alt={
            [(owner?.lastName || "")?.trim(), (owner?.firstName || "")?.trim()]
              .join(" ")
              .trim() || "N A"
          }
          src={owner?.profileImageUrl || ""}
        />
      </div>
      <div className='flex-1 min-w-0 text-left'>
        <p className='text-sm font-medium text-gray-900 dark:text-white truncate'>
          {[(owner?.lastName || "")?.trim(), (owner?.firstName || "")?.trim()]
            .join(" ")
            .trim() || "N A"}
        </p>
        <p className='text-sm text-gray-500 dark:text-gray-400 truncate'>
          {owner?.phoneNumber || "N/A"}
        </p>
      </div>
    </div>
  );
}

const RegularizationInspectionServiceOrderView: FC<
  RegularizationInspectionServiceOrderViewProps
> = ({ inspectionServiceOrder }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div className=''>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-4 font-normal text-gray-900 dark:text-gray-100'>
              Order Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>
              Details about service order to attend to
            </p>
          </div>
          <div />
        </div>
        <div className='mt-4 space-y-3'>
          <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
            <div className='grid grid-cols-3 gap-6'>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Code
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {inspectionServiceOrder?.code || "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Date
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {inspectionServiceOrder?.createdAt
                    ? moment(inspectionServiceOrder.createdAt).format(
                        dateFormat
                      )
                    : "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Status
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {_.startCase(inspectionServiceOrder?.status) || "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Priority
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {_.startCase(inspectionServiceOrder?.priority) || "N/A"}
                </div>
              </div>
              <div className=''>
                <span className='block text-sm font-light text-gray-700'>
                  Estimated Resolution Date
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {inspectionServiceOrder?.estimatedResolutionDate
                    ? moment(
                        inspectionServiceOrder.estimatedResolutionDate
                      ).format(dateFormat)
                    : "N/A"}
                </div>
              </div>
              <div className=''>
                <span className='block text-sm font-light text-gray-700'>
                  Completion Date
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {inspectionServiceOrder?.completedAt
                    ? moment(inspectionServiceOrder.completedAt).format(
                        dateFormat
                      )
                    : "N/A"}
                </div>
              </div>
            </div>
          </div>
          <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
            <div className='grid grid-cols-3 gap-6'>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Regularization Code
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {inspectionServiceOrder?.regularization?.code || "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Regularization Date
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {inspectionServiceOrder?.regularization?.createdAt
                    ? moment(
                        inspectionServiceOrder?.regularization.createdAt
                      ).format(dateFormat)
                    : "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Regularized Meter Number
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {_.startCase(inspectionServiceOrder?.regularizedMeterCode) ||
                    "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Neighbourhood Class
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {_.startCase(
                    inspectionServiceOrder?.regularization?.billing
                      ?.neighbourhoodClass
                  ) || "N/A"}
                </div>
              </div>
              <div className=''>
                <span className='block text-sm font-light text-gray-700'>
                  Number Of Rooms
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {_.startCase(
                    inspectionServiceOrder?.regularization?.billing
                      ?.numberOfRooms
                  ) || "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='pt-6'>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-4 font-normal text-gray-900 dark:text-gray-100'>
              Customer Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>
              Details about initial reading and meter details from prepayment
              system
            </p>
          </div>
          <div />
        </div>
        <div className='mt-4 space-y-3'>
          <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
            <div className='grid grid-cols-3 gap-4'>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Service Point Number
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {inspectionServiceOrder?.servicePointCode || "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Full Name
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {inspectionServiceOrder?.contactPerson?.fullName || "N/A"}
                </div>
              </div>

              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Phone Number
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {inspectionServiceOrder?.contactPerson?.phoneNumber || "N/A"}
                </div>
              </div>
            </div>
          </div>
          <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
            <div className='grid grid-cols-3 gap-4'>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Region
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {inspectionServiceOrder?.region?.name || "N/A"}
                </div>
              </div>
              <div className='row-span-4 col-span-2'>
                <span className='block text-sm font-light text-gray-700'>
                  Premise Images
                </span>
                <div className='mt-2 grid grid-cols-1 gap-3'>
                  {wrapImage(
                    <img
                      src={
                        inspectionServiceOrder?.regularization?.billing
                          ?.meterImageUrl
                      }
                      alt={"back"}
                      className='w-full h-48 text-xs object-cover object-center rounded'
                    />
                  )}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  District
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {inspectionServiceOrder?.district?.name || "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Community
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {inspectionServiceOrder?.location?.community || "N/A"}
                </div>
              </div>

              <div className=''>
                <span className='block text-sm font-light text-gray-700'>
                  Address
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {inspectionServiceOrder?.location?.address || "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {inspectionServiceOrder?.status !== "Pending" && (
        <div className='pt-6'>
          <div className='flex justify-between items-center cursor-pointer'>
            <div>
              <h3 className='text-md leading-4 font-normal text-gray-900 dark:text-gray-100'>
                Assignment Information
              </h3>
              <p className='mt-1 text-xs text-gray-500'>
                Details about actors for the service order
              </p>
            </div>
            <div />
          </div>
          <div className='mt-4 space-y-3'>
            <div className='grid grid-cols-3 gap-6 mt-2'>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Assigner
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  <Owner
                    owner={inspectionServiceOrder?.assigner}
                    type='staff-accounts'
                  />
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Assignee
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  <Owner
                    owner={inspectionServiceOrder?.assignee}
                    type='field-agents'
                  />
                </div>
              </div>
              {inspectionServiceOrder?.status == "Disapproved" && (
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Disapprover
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    <Owner
                      owner={inspectionServiceOrder?.disapprover}
                      type='staff-accounts'
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegularizationInspectionServiceOrderView;
