import ExtraInfoForm from "./extra-info-form";
import CustomerInfoForm from "./customer-info-form";
import CustomerInfoSummary from "./customer-info-summary";
import AssigneeInfoForm from "./assignee-info-form";
import AssigneeInfoSummary from "./assignee-info-summary";
import ExtraInfoSummary from "./extra-info-summary";
import MeterInfoForm from "./meter-info-form";
import MeterInfoSummary from "./meter-info-summary";

const formSteps = [
  {
    name: "Customer",
    description: "Information about customer for which.",
    accessor: "customerInfo",
    FormComponent: CustomerInfoForm,
    SummaryComponent: CustomerInfoSummary,
  },
  {
    name: "Assignee",
    description: "Information about agent to attend to service order.",
    accessor: "assigneeInfo",
    FormComponent: AssigneeInfoForm,
    SummaryComponent: AssigneeInfoSummary,
  },
  {
    name: "Meter",
    description: "Information about meter to be installed.",
    accessor: "meterInfo",
    FormComponent: MeterInfoForm,
    SummaryComponent: MeterInfoSummary,
  },
  {
    name: "Extra Info",
    description: "Information about wallet to be credited.",
    accessor: "extraInfo",
    FormComponent: ExtraInfoForm,
    SummaryComponent: ExtraInfoSummary,
  },
];

export default formSteps;
