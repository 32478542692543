import { useLazyQuery, gql } from "@apollo/client";
import { ArrowTopRightOnSquareIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { Avatar, SelectInput, TextInput } from "components/core"
import { useFormik } from "formik"
import { FC, useState } from "react"
import { Link } from "react-location";
import { classNames, wrapClick } from "utils";
import { ApproverInfoFormSchema } from "./schema";

interface ApproverInfoFormProps {
  handleNext: (values: any) => void
  handlePrevious: () => void;
  initialValues: any;
  values: any;
  handleCancel: () => void;
}
const GET_USERS = gql`
  query GetApprovers($page: Int $pageSize: Int $sort: String $search: String $searchFields: [String!] $region: ID $district: ID) {
    getUsers(page: $page pageSize: $pageSize sort: $sort search: $search searchFields: $searchFields region: $region district: $district) {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        gender
        profileImageUrl
        role {
          _id
          code
          name
        }
        createdAt
        updatedAt
    }
  }
`

function Owner({ owner, onClick, isActive }: { owner: any, onClick: any, isActive: boolean }) {

  return (
    <button
      onClick={onClick}
      className={classNames(
        isActive ? "border-primary-500 dark:border-primary-600 bg-white dark:bg-primary-800 hover:border-primary-700 dark:hover:border-primary-500" : "border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 hover:border-gray-400 dark:hover:border-gray-500",
        "focus:outline-none relative rounded-lg border px-3 py-3 shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
      )}
    >
      <div className="flex-shrink-0">
        <Avatar
          alt={[(owner?.lastName || "")?.trim(), (owner?.firstName || "")?.trim()].join(" ").trim() || "N A"}
          src={owner?.profileImageUrl || ""}
        />
      </div>
      <div className="flex-1 min-w-0 text-left">
        <p className="text-sm font-medium text-gray-900 dark:text-white truncate">{[(owner?.lastName || "")?.trim(), (owner?.firstName || "")?.trim()].join(" ").trim() || "N A"}</p>
        <p className="text-sm text-gray-500 dark:text-gray-400 truncate">{owner?.phoneNumber || "N/A"}</p>
      </div>
    </button>
  )
}

const ApproverInfoForm: FC<ApproverInfoFormProps> = ({ initialValues, handleNext, handlePrevious, handleCancel }) => {

  const form = useFormik({
    initialValues,
    validationSchema: ApproverInfoFormSchema,
    onSubmit: (values) => {
      handleNext(values)
    },
    onReset: () => {
      handleCancel?.();
    }
  })
  const [getUsers, { loading, data }] = useLazyQuery(GET_USERS)

  const approverSearchForm = useFormik({
    initialValues: {
      userIdentifier: "fullName",
      emailAddress: "",
      fullName: "",
      ghanaCardNumber: "",
      phoneNumber: "",
      code: "",
    },
    onSubmit: (values) => {
      console.log(values.userIdentifier === "fullName" ? ["lastName", "firstName"] : [values.userIdentifier]);
      getUsers({
        variables: {
          page: 1,
          pageSize: 9,
          search: (values as any)[values.userIdentifier],
          searchFields: values.userIdentifier === "fullName" ? ["lastName", "firstName"] : [values.userIdentifier],
        }
      })
    }
  })

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">

        <div>
          <span className='text-xs font-light'>Current Approver</span>
          {form?.values?.approver ? (
            <div className='grid grid-cols-3 gap-6 mt-2'>
              <div className='row-span-3 flex flex-col space-y-3 items-center justify-center'>
                <Avatar
                  alt={form?.values?.approver?.fullName || "N A"}
                  src={form?.values?.approver?.profileImageUrl || ""}
                  size="xl"
                />
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Staff Code
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {form?.values?.approver?.code || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Ghana Card Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {form?.values?.approver?.ghanaCardNumber || "N/A"}
                </div>
              </div>
              <div className=''>
                <span className="block text-sm font-light text-gray-700">
                  First Name
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {form?.values?.approver?.firstName || "N/A"}
                </div>
              </div>
              <div className=''>
                <span className="block text-sm font-light text-gray-700">
                  Last Name
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {form?.values?.approver?.lastName || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Phone Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {form?.values?.approver?.phoneNumber || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Email Address
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {form?.values?.approver?.emailAddress || "N/A"}
                </div>
              </div>
            </div>
          ) : (

            <div className='flex  mt-2 w-full items-center justify-center'>

              <div
                className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <UserPlusIcon
                  className="mx-auto h-10 w-10 text-gray-400"
                  stroke="currentColor"
                  strokeWidth={1}
                  aria-hidden="true"
                />
                <h3 className="mt-2 text-sm font-medium text-gray-900">No staff assigned</h3>
                <p className="mt-1 text-sm text-gray-500">Get started by searching for a staff below.</p>
              </div>
            </div>
          )}
        </div>
        <div>
          <span className='text-xs font-light'>Select Approver</span>
          <form onSubmit={approverSearchForm.handleSubmit} className='grid grid-cols-6 gap-6 mt-2'>
            <div className="col-span-3 sm:col-span-2">
              <SelectInput
                id="userIdentifier"
                label="Find Staff By"
                options={[
                  { label: "Email Address", value: "emailAddress" },
                  { label: "Full Name", value: "fullName" },
                  { label: "Ghana Card Number", value: "ghanaCardNumber" },
                  { label: "Phone Number", value: "phoneNumber" },
                  { label: "Staff Code", value: "code" },
                ]}
                placeholder='e.g. Mensah'
                {...approverSearchForm}
              />
            </div>

            {approverSearchForm.values?.userIdentifier === "fullName" && (
              <div className="col-span-6 sm:col-span-3">
                <TextInput
                  id="fullName"
                  label="Full Name (Last Name, Other Names)"
                  type="text"
                  placeholder='e.g. Mensah John'
                  {...approverSearchForm}
                />
              </div>
            )}

            {approverSearchForm.values?.userIdentifier === "emailAddress" && (
              <div className="col-span-6 sm:col-span-3">
                <TextInput
                  id="emailAddress"
                  label="Email Address"
                  type="email"
                  placeholder='e.g. user@ecg.com.gh'
                  {...approverSearchForm}
                />
              </div>
            )}

            {approverSearchForm.values?.userIdentifier === "phoneNumber" && (
              <div className="col-span-6 sm:col-span-3">
                <TextInput
                  id="phoneNumber"
                  label="Phone Number"
                  type="text"
                  placeholder='e.g. 0200000000'
                  {...approverSearchForm}
                />
              </div>
            )}

            {approverSearchForm.values?.userIdentifier === "ghanaCardNumber" && (
              <div className="col-span-6 sm:col-span-3">
                <TextInput
                  id="ghanaCardNumber"
                  label="Ghana Card Number"
                  type="text"
                  placeholder='e.g. GHA-725667795-8'
                  {...approverSearchForm}
                />
              </div>
            )}

            {approverSearchForm.values?.userIdentifier === "code" && (
              <div className="col-span-6 sm:col-span-3">
                <TextInput
                  id="code"
                  label="Staff Code"
                  type="text"
                  placeholder='e.g. MR12345678'
                  {...approverSearchForm}
                />
              </div>
            )}
            <div className="col-span-6 sm:col-span-1 items-end justify-end flex">
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                {loading ? "Searching..." : "Search"}
              </button>
            </div>
          </form>
          <div className='grid grid-cols-3 gap-6 mt-6 pt-6 border-t border-gray-200'>
            {data?.getUsers?.map?.((user: any) => (
              <Owner
                key={user._id}
                isActive={user._id === form.values?.approver?._id}
                onClick={wrapClick(() => form.setFieldValue("approver", user))}
                owner={user}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default ApproverInfoForm