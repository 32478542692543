import { FC } from "react";
import _ from "lodash";
import numeral from "numeral";

const RecoveryDataInfoSummary: FC<{ data: any }> = ({ data }) => (
  <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
    <div className=''>
      <span className='text-xs font-light'>Recovery Information</span>
      <div className='grid grid-cols-3 gap-6 mt-2'>
        <div>
          <span className='block text-sm font-light text-gray-700'>
            Meter Condition
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.meterCondition || "N/A"}
          </div>
        </div>

        <div>
          <span className='block text-sm font-light text-gray-700'>
            Remaining Balance
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.remainingBalance
              ? `${numeral(data?.remainingBalance || 0).format("0,0.00")} ${
                  data?.remainingBalanceType || ""
                }`
              : "N/A"}
          </div>
        </div>
        <div>
          <span className='block text-sm font-light text-gray-700'>
            Debt Amount (GHS)
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {numeral(data?.debtAmount || 0).format("0,0.00")}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default RecoveryDataInfoSummary;
