import ServiceInfoForm from "./service-info-form";
import PropertyInfoForm from "./property-info-form";
import MaterialsInfoForm from "./materials-info-form";
import ServiceInfoSummary from "./service-info-summary";
import MaterialsInfoSummary from "./materials-info-summary";
import PropertyInfoSummary from "./property-info-summary";

const formSteps = [
  {
    name: "Property Info",
    description: "Information about property.",
    accessor: "propertyInfo",
    FormComponent: PropertyInfoForm,
    SummaryComponent: PropertyInfoSummary,
  },
  {
    name: "Service Info",
    description: "Information about service",
    accessor: "serviceInfo",
    FormComponent: ServiceInfoForm,
    SummaryComponent: ServiceInfoSummary,
  },
  {
    name: "Installation Info",
    description: "Information about installation",
    accessor: "extraInfo",
    FormComponent: MaterialsInfoForm,
    SummaryComponent: MaterialsInfoSummary,
  },
]

export default formSteps;
