import { SearchSelectInput, SelectInput, TextInput, UploadArea } from 'components/core'
import { useFormik } from 'formik'
import { FC } from 'react'
import { wrapClick, classNames } from 'utils';
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { IResolveInspectionServiceOrderFormSchema, ServiceInfoFormSchema } from './schema';
import { TariffClassPicker } from 'containers';
import { ServiceClasses, ServiceTypes } from 'apollo/data';

interface ServiceInfoFormProps {
  handleNext: (values: IResolveInspectionServiceOrderFormSchema["serviceInfo"]) => void
  handlePrevious: () => void;
  initialValues: IResolveInspectionServiceOrderFormSchema["serviceInfo"];
  values: IResolveInspectionServiceOrderFormSchema;
  handleCancel: () => void;
}

const ServiceInfoForm: FC<ServiceInfoFormProps> = ({ initialValues, handleNext, handlePrevious, handleCancel, values }) => {

  const form = useFormik<IResolveInspectionServiceOrderFormSchema["serviceInfo"]>({
    initialValues,
    validationSchema: ServiceInfoFormSchema,
    onSubmit: (values) => {
      handleNext(values)
    },
    onReset: () => {
      handleCancel?.();
    }
  })

  return (
    <form onSubmit={form.handleSubmit} className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        <div>
          <span className='text-xs font-light'>Service Information</span>
          <div className='grid grid-cols-3 gap-6 mt-2'>

            <div className='col-span-3'>
              <RadioGroup value={form.values.serviceType} onChange={form.handleChange("serviceType")}>
                <RadioGroup.Label className="text-sm font-medium text-gray-700">Service Type</RadioGroup.Label>
                <div className="mt-1 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                  {ServiceTypes.map((serviceType) => (
                    <RadioGroup.Option
                      key={serviceType.value}
                      value={serviceType.value}
                      className={({ checked, active }) =>
                        classNames(
                          checked ? 'border-transparent' : 'border-gray-300',
                          active ? 'border-primary-500 ring-2 ring-primary-500' : '',
                          'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                        )
                      }
                    >
                      {({ checked, active }) => (
                        <>
                          <span className="flex flex-1">
                            <span className="flex flex-col">
                              <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                {serviceType.title}
                              </RadioGroup.Label>
                              <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                                {serviceType.description}
                              </RadioGroup.Description>
                            </span>
                          </span>
                          <CheckCircleIcon
                            className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-primary-600')}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              active ? 'border' : 'border-2',
                              checked ? 'border-primary-500' : 'border-transparent',
                              'pointer-events-none absolute -inset-px rounded-lg'
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
            <div className='col-span-3'>
              <RadioGroup value={form.values.serviceClass} onChange={form.handleChange("serviceClass")}>
                <RadioGroup.Label className="text-sm font-medium text-gray-700">Service Class</RadioGroup.Label>
                <div className="mt-1 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                  {ServiceClasses.map((serviceClass) => (
                    <RadioGroup.Option
                      key={serviceClass.value}
                      value={serviceClass.value}
                      className={({ checked, active }) =>
                        classNames(
                          checked ? 'border-transparent' : 'border-gray-300',
                          active ? 'border-primary-500 ring-2 ring-primary-500' : '',
                          'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                        )
                      }
                    >
                      {({ checked, active }) => (
                        <>
                          <span className="flex flex-1">
                            <span className="flex flex-col">
                              <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                {serviceClass.title}
                              </RadioGroup.Label>
                              <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                                {serviceClass.description}
                              </RadioGroup.Description>
                            </span>
                          </span>
                          <CheckCircleIcon
                            className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-primary-600')}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              active ? 'border' : 'border-2',
                              checked ? 'border-primary-500' : 'border-transparent',
                              'pointer-events-none absolute -inset-px rounded-lg'
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
          </div>
        </div>
        <div className="pt-6">
          <span className='text-xs font-light'>Demand Information</span>
          <div className='grid grid-cols-3 gap-6 mt-2'>
            <div>
              <TariffClassPicker
                id="tariffClass"
                label="Tariff Class"
                placeholder="e.g. Non-Residential"
                required={true}
                position='top'
                {...form}
              />
            </div>
            
            <div>
              <TextInput
                id="contractedDemand"
                label="Contracted Demand"
                type="number"
                placeholder='e.g. Enoch Nana Nyankah'
                {...form}
              />
            </div>

            <div className="">
              <SelectInput
                id="meterPhase"
                label="Load Type"
                options={[
                  { label: "1 Phase", value: 1 },
                  { label: "3 Phase", value: 3 },
                ]}
                placeholder='eg. 1 Phase'
                required={true}
                {...form}
              />
            </div>
          </div>

        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </form>
  )
}

export default ServiceInfoForm
