import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { currentConfigVar } from 'apollo/cache/config'
import moment from 'moment'
import numeral from 'numeral'
import { FC } from 'react'
import { useSearch } from 'react-location'
import { LocationGenerics } from 'router/location'
import { classNames } from 'utils'

const statsMap = [
  { name: 'Total Meters Read', accessor: 'totalCustomers' },
  { name: 'Total Billed (GHS)', accessor: 'totalBilled' },
  { name: 'Total Consumption (KWh)', accessor: 'totalConsumption' },
  { name: 'Total Meter Readers', accessor: 'totalFieldAgents' },
  { name: 'Total Pending Readings', accessor: 'totalPending' },
  { name: 'Total Successful Readings', accessor: 'totalSuccess' },
  { name: 'Total Anomalies Detected', accessor: 'totalAnomalies' },
  { name: 'Total Exceptions Detected', accessor: 'totalExceptions' },
  { name: 'Total No Trace Meters', accessor: 'totalNoTraces' },
  { name: 'Total Unlisted Meters', accessor: 'totalUncaptured' },
]

const DATE_FORMAT = "YYYY-MM-DD";

const OVERVIEW_SUMMARY = gql`
query GetSummaryReadingOverview($region: ID, $district: ID, $fromDate: Date, $toDate: Date) {
  summary: getSummaryReadingOverview(region: $region, district: $district, fromDate: $fromDate, toDate: $toDate) {
    totalAnomalies
    totalConsumption
    totalCustomers
    totalBilled
    totalFieldAgents
    totalPending
    totalSuccess
    totalExceptions
    totalNoTraces
    totalUncaptured
  }
}
`

const ReadingOverviewContainer: FC = () => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>()

  const { data } = useQuery(OVERVIEW_SUMMARY, {
    variables: {
      region: searchParams?.region || undefined,
      district: searchParams?.district || undefined,
      fromDate: moment(searchParams?.fromDate, DATE_FORMAT).startOf("day").toISOString(),
      toDate: moment(searchParams?.toDate, DATE_FORMAT).endOf("day").toISOString()
    },
  })

  return (
    <div>
      <h3 className="text-lg font-medium leading-6 text-gray-900">Reading Overview</h3>
      <dl className="mt-5 grid grid-cols-1 overflow-hidden rounded-lg bg-white md:grid-cols-5 shadow">
        {statsMap.map((item, index) => (
          <div key={item.name} className={classNames(
            (index !== 0 && index !== 5)? "border-l border-gray-200" : "",
            (index >= 5) ? "border-t border-gray-200" : "",
            "px-4 py-5 sm:p-6"
          )}>
            <dt className="text-sm font-normal text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-blue-600">
                {numeral(data?.summary?.[item.accessor] || 0).format(item.accessor === "totalBilled" ? "0,0.00" : "0,0")}
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}

export default ReadingOverviewContainer