import { useFormik } from "formik"
import { FC, useEffect } from "react"
import { wrapClick } from "utils";
import { IResolveInspectionServiceOrderFormSchema, PropertyInfoFormSchema } from "./schema";
import { RegionPicker, DistrictPicker, BlockPicker, RoundPicker, PlotPicker, ItineraryPicker, ActivityPicker, PremiseCategoryPicker, PremiseTypePicker, SubActivityPicker } from "containers";
import { SelectInput, TextInput, UploadArea } from "components";

interface CustomerInfoFormProps {
  handleNext: (values: IResolveInspectionServiceOrderFormSchema["propertyInfo"]) => void
  handlePrevious: () => void;
  initialValues: IResolveInspectionServiceOrderFormSchema["propertyInfo"];
  values: IResolveInspectionServiceOrderFormSchema;
  handleCancel: () => void;
}

const CustomerInfoForm: FC<CustomerInfoFormProps> = ({ initialValues, handleNext, handleCancel }) => {

  const form = useFormik<IResolveInspectionServiceOrderFormSchema["propertyInfo"]>({
    initialValues,
    validationSchema: PropertyInfoFormSchema,
    onSubmit: (values) => {
      console.log({values})
      handleNext(values)
    },
    onReset: () => {
      handleCancel?.();
    }
  })

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        <div>
          <span className="text-xs font-light">Location Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div>
              <RegionPicker
                id="region"
                label="Region"
                placeholder="e.g. Accra East Region"
                required={true}
                {...form}
                setFieldValue={(field: string, value: string) => {
                  form.setFieldValue(field, value)
                  form.setFieldValue("district", null)
                }}
              />
            </div>

            {form.values.region?._id && (
              <div>
                <DistrictPicker
                  id="district"
                  label="District"
                  placeholder="e.g. Makola District"
                  required={true}
                  filter={{ region: form.values.region?._id }}
                  {...form}
                  setFieldValue={(field: string, value: string) => {
                    form.setFieldValue(field, value)
                  }}
                />
              </div>
            )}

            <div className="">
              <TextInput
                id="ghanaPostAddress"
                label="Ghana Post Address"
                type="text"
                required={true}
                placeholder="e.g. GA-232-2323"
                maxLength={11}
                {...form}
              />
            </div>
          </div>
        </div>
        <div className="pt-6">
          <span className="text-xs font-light">Premise Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">

          <div className="col-start-1">
              <PremiseTypePicker
                id="premiseType"
                label="Premise Type"
                placeholder="e.g. Accomodation"
                required={true}
                {...form}
                setFieldValue={(field: string, value: string) => {
                  form.setFieldValue("premiseCategory", null);
                  form.setFieldValue(field, value);
                }}
              />
            </div>
            <div className="col-span-2">
              <PremiseCategoryPicker
                id="premiseCategory"
                label="Premise Category"
                placeholder="e.g. Hotel"
                required={true}
                disabled={!form.values.premiseType}
                {...form}
                filter={{
                  premiseType: form?.values?.premiseType?._id
                }}
              />
            </div>
            <div className="col-start-1">
              <ActivityPicker
                id="activity"
                label="Activity"
                placeholder="e.g. Hospitality"
                required={true}
                {...form}
                setFieldValue={(field: string, value: string) => {
                  form.setFieldValue("subActivity", null);
                  form.setFieldValue(field, value);
                }}
              />
            </div>
            <div className="col-span-2">
              <SubActivityPicker
                id="subActivity"
                label="Sub Activity"
                placeholder="e.g. Guest House"
                required={true}
                disabled={!form.values.activity}
                {...form}
                filter={{
                  activity: form?.values?.activity?._id
                }}
              />
            </div>

            <div className="col-span-2">
              <TextInput
                id="meterLocation"
                label="Meter Location"
                type="text"
                required={true}
                placeholder="e.g. Mensah Enoch Nana Nyankah"
                {...form}
              />
            </div>
            <div className="">
              <TextInput
                id="meterHeight"
                label="Meter Height"
                type="number"
                required={true}
                placeholder="e.g. 5"
                postText="Feet"
                {...form}
              />
            </div>
            <div className="">
              <TextInput
                id="transformerNumber"
                label="Transformer Number"
                type="text"
                required={true}
                placeholder="e.g. RE349349394"
                {...form}
              />
            </div>
            <div className="">
              <SelectInput
                id="transformerPhase"
                label="Transformer Phase"
                required={true}
                placeholder="e.g. Pole"
                {...form}
                options={[
                  {label: "--- select one ---", value: ""},
                  {label: "All", value: "All"},
                  {label: "Red", value: "Red"},
                  {label: "Yellow", value: "Yellow"},
                  {label: "Blue", value: "Blue"},
                ]}
              />
            </div>

            <div className="">
              <TextInput
                id="currentTransformerRatio"
                label="Current Transformer Ratio"
                type="number"
                required={true}
                placeholder="e.g. 34"
                {...form}
              />
            </div>
            <div className="">
              <TextInput
                id="closestPoleNumber"
                label="Closest Pole Number"
                type="text"
                required={true}
                placeholder="e.g. FE349394"
                {...form}
              />
            </div>            


          </div>
        </div>


        <div className="pt-6">
          <span className="text-xs font-light">Premise Information</span>
          <div className="mt-2">
            <UploadArea
              id="propertyImageUrls"
              label="Upload Premise Images"
              accept={{
                "image/*": [".png", ".jpeg", ".jpg"],
              }}
              className="w-full h-96"
              multiple={true}
              maxFiles={6}
              {...form}
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default CustomerInfoForm
