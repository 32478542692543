import { gql, useQuery } from "@apollo/client";
import { FC } from "react";
import { SearchSelectInput } from "components/core";

interface ContractorPickerContainerProps {
  filter?: {
    type?: string;
    district?: string;
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  rawId?: boolean;
}

const GET_CONTRACTORS = gql`
  query GetContractorsSelect($type: ContractorType, $district: ID) {
    contractors: getContractors(
      type: $type
      district: $district
      sort: "name"
    ) {
      _id
      code
      name
      type
      contactPerson {
        fullName
        phoneNumber
        emailAddress
      }
    }
  }
`;

const ContractorPickerContainer: FC<ContractorPickerContainerProps> = ({
  filter,
  id,
  label,
  rawId,
  ...form
}) => {
  const { loading, data } = useQuery(GET_CONTRACTORS, {
    variables: {
      ...(filter ? filter : {}),
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <SearchSelectInput
      id={id ?? "contractor"}
      label={label ?? "Contractor"}
      placeholder='Select Contractor'
      optionsLoading={loading}
      options={(data?.contractors ?? [])?.map((contractor: any) => ({
        label: {
          title: contractor.name as string,
        },
        value: rawId ? contractor._id : contractor,
      }))}
      {...form}
    />
  );
};

export default ContractorPickerContainer;
