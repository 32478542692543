import { gql, useQuery } from '@apollo/client'
import { FC } from 'react';
import { SearchSelectInput } from 'components/core';

interface ActivityPickerContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  rawId?: boolean;
}

const GET_ACTIVITIES = gql`
  query GetActivitiesSelect {
    activities: getActivities(sort: "name") {
      _id
      code
      name
    }
  }
`;

const ActivityPickerContainer: FC<ActivityPickerContainerProps> = ({ id, label, rawId, ...form }) => {
  const { loading, data } = useQuery(GET_ACTIVITIES, {
    notifyOnNetworkStatusChange: false
  })

  return (
    <SearchSelectInput
      id={id ?? "activity"}
      label={label ?? "Activity"}
      placeholder="Select Activity"
      optionsLoading={loading}
      options={(data?.activities ?? [])?.map((activity: any) => ({
        label: {
          title: activity.name as string,
        },
        value: rawId ? activity?._id : activity
      }))}
      {...form}
      disabled={form.disabled}
    />
  )
}

export default ActivityPickerContainer