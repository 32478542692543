import { FC } from "react";
import { Link, useSearch } from "react-location";
// import { LocationGenerics } from "router/location";
import {
  CursorArrowRaysIcon,
  EnvelopeOpenIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "utils";
import { ReadersMonthlyOverviewContainer } from "./containers";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import lodash from "lodash";
import { currentUserVar } from "apollo/cache/auth";
import { LocationGenerics } from "router/location";

const stats = [
  {
    id: 1, name: "Inspections", accessor: "inspection", href: "inspection",
    icon: UsersIcon
  },
  {
    id: 2,
    name: "Installations",
    accessor: "installation",
    href: "installation",
    icon: EnvelopeOpenIcon,
  },
  {
    id: 3,
    name: "Disconnections",
    accessor: "disconnection",
    href: "#",
    icon: CursorArrowRaysIcon,
  },
  { id: 4, name: "Reconnections", accessor: "reconnection", href: "#", icon: UsersIcon },
  {
    id: 5,
    name: "Investigations",
    accessor: "investigation",
    href: "investigation-service-order",
    icon: EnvelopeOpenIcon,
  },
  {
    id: 6,
    name: "Replacements",
    accessor: "replacement",
    href: "replacement",
    icon: CursorArrowRaysIcon,
  },
];

const statusTransitions = [
  { from: "Pending", to: "Completed", averageTime: 349349 },
  { from: "Pending", to: "Assigned", averageTime: 349349 },
  { from: "Assigned", to: "InProgress", averageTime: 4545 },
  { from: "InProgress", to: "Resolved", averageTime: 454545 },
  { from: "Resolved", to: "Completed", averageTime: 394943 },
];

const SERVICE_ORDER_SUMMARY = gql`
  query GetServiceOrdersSummary(
  $region: ID
  $district: ID
  $fromDate: Date
  $toDate: Date
) {
  inspection: getInspectionServiceOrderSummary(
    region: $region
    district: $district
    fromDate: $fromDate
    toDate: $toDate
  ) {
    Assigned
    Completed
    InProgress
    Pending
    Resolved
  }
  installation: getInstallationServiceOrderSummary(
    region: $region
    district: $district
    fromDate: $fromDate
    toDate: $toDate
  ) {
    AssignedToSupplier
    AssignedToInstaller
    Completed
    InProgress
    Pending
    Resolved
  }
  investigation: getInvestigationServiceOrderSummary(
    region: $region
    district: $district
    fromDate: $fromDate
    toDate: $toDate
  ) {
    Assigned
    Completed
    InProgress
    Pending
    Resolved
  }
  replacement: getReplacementServiceOrderSummary(
    region: $region
    district: $district
    fromDate: $fromDate
    toDate: $toDate
  ) {
    AssignedToSupplier
    AssignedToInstaller
    Completed
    InProgress
    Pending
    Resolved
  }
  reconnection: getReconnectionServiceOrderSummary(
    region: $region
    district: $district
    fromDate: $fromDate
    toDate: $toDate
  ) {
    Assigned
    Completed
    InProgress
    Pending
    Resolved
  }
  disconnection: getDisconnectionServiceOrderSummary(
    region: $region
    district: $district
    # fromDate: $fromDate
    # toDate: $toDate
  ) {
    Assigned
    Completed
    InProgress
    Pending
    Resolved
  }
}

`;

const DashboardPage: FC = () => {
  const searchParams = useSearch<LocationGenerics>();
  const { data } = useQuery(SERVICE_ORDER_SUMMARY, {
    variables: {
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
    },
  });
  const currentUser = useReactiveVar(currentUserVar);

  return (
    <div className='flex-1 min-h-full mx-auto  p-4 sm:p-6 overflow-y-auto bg-gray-50'>
      <div className='grid grid-cols-1 items-start gap-4 lg:grid-cols-12 lg:gap-8'>
        <div className='col-span-12'>
          <dl className='grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4'>
            <div className='relative isolate overflow-hidden rounded-lg bg-primary-400 px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6 row-span-2'>
              {/* <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.primary.100),white)] opacity-20" /> */}
              <div className='absolute inset-y-0 right-8 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-15deg] bg-primary-500 shadow-xl shadow-primary-400 ring-1 ring-primary-400 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center' />
              <div className='absolute inset-y-0 right-24 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-20deg] bg-primary-600 shadow-xl shadow-primary-500 ring-1 ring-primary-500 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center' />
              <div className='absolute inset-0 -z-10 bg-gradient-to-b from-transparent via-primary-600/80 to-primary-600' />
              <div className='mx-auto text-white max-w-2xl lg:max-w-4xl'>
                <p>Welcome back,</p>
                <h3 className='text-4xl font-medium'>
                  {currentUser?.firstName}
                </h3>
              </div>
            </div>
            {stats.map((item) => (
              <div
                key={item.id}
                className='relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6'
              >
                <dt>
                  <div className='absolute rounded-md bg-primary-500 p-3'>
                    <item.icon
                      className='h-6 w-6 text-white'
                      aria-hidden='true'
                    />
                  </div>
                  <p className='ml-16 truncate text-sm font-medium text-gray-500'>
                    {item.name}
                  </p>
                </dt>
                <dd className='ml-16 flex justify-between items-end pb-6 sm:pb-7'>
                  <p className='text-2xl font-semibold text-gray-900'>
                    {lodash
                      .chain(data?.[item.accessor])
                      ?.omit(["__typename"])
                      ?.toPairs()
                      ?.map(([key, value]) => value)
                      ?.sum()
                      ?.value()}
                  </p>
                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      data?.[item.accessor]?.Pending
                        ? "bg-primary-100 text-primary-800"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    {data?.[item.accessor]?.Pending ?? 0} Pending
                  </span>
                  <div className='absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6'>
                    <div className='text-sm'>
                      <Link
                        to={`/${item.href}s`}
                        className='font-medium text-primary-600 hover:text-primary-500'
                      >
                        {" "}
                        View all
                        <span className='sr-only'> {item.name} stats</span>
                      </Link>
                    </div>
                  </div>
                </dd>
              </div>
            ))}
          </dl>
        </div>
        {false && (
          <div className='space-y-6'>
            {statusTransitions.map((statusTransition) => (
              <div>
                From <span>{statusTransition.from}</span>
                To <span>{statusTransition.to}</span>
                Average Time <span>{statusTransition.averageTime}</span>
                Deviation <span>{1000000 - statusTransition.averageTime}</span>
              </div>
            ))}
          </div>
        )}
        {false && (
          <div className='col-span-12'>
            <ReadersMonthlyOverviewContainer />
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardPage;
