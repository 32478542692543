import { FC } from "react";
import { Avatar } from "components/core";
import { classNames } from "utils";

interface UserViewProps {
  user: {
    code: string;
    lastName: string;
    firstName: string;
    ghanaCardNumber: string;
    phoneNumber: string;
    emailAddress: string;
    gender: string;
    profileImageUrl: string;
    regionCode: string;
    regionName: string;
    districtCode: string;
    districtName: string;
    role: string;
    meta: {
      isPasswordSet: boolean;
      isBlocked: boolean;
      isDeleted: boolean;
      lastLoginAt: Date;
    };
  };
}

const UserView: FC<UserViewProps> = ({ user }) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Staff Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div className="row-span-2 flex flex-col space-y-3 items-center justify-center">
            <Avatar
              alt={
                [
                  (user?.lastName || "")?.trim(),
                  (user?.firstName || "")?.trim(),
                ]
                  .join(" ")
                  .trim() || "N A"
              }
              src={user?.profileImageUrl || ""}
              size="xl"
            />

            <span
              className={classNames(
                user?.role === "Super"
                  ? "bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50"
                  : "",
                user?.role === "Admin"
                  ? "bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50"
                  : "",
                user?.role === "Agent"
                  ? "bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50"
                  : "",
                "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
              )}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 10 10"
                fill="currentColor"
                className="w-1.5 h-1.5"
              >
                <circle
                  fillRule="evenodd"
                  cx="5"
                  cy="5"
                  r="5"
                  clipRule="evenodd"
                />
              </svg>
              <span>{user?.role || "Unknown"}</span>
            </span>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Staff ID
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {user?.code || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Gender
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {user?.gender || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {user?.lastName || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Other Names
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {user?.firstName || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Contact Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Email Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {user?.emailAddress || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Phone Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {user?.phoneNumber || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Ghana Card
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {user?.ghanaCardNumber || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserView;
