import { FC, useMemo } from "react";
import numeral from "numeral";
import lodash from "lodash";

const MeterRemovalMaterialInfoSummary: FC<{
  data: {
    recoveredMaterials: {
      material: {
        _id: string;
        code: string;
        name: string;
        quantityUnit: string;
      };
      quantity: number;
    }[];
  };
}> = ({ data }) => {
  const recoveredMaterials = useMemo(
    () => lodash.reject(data?.recoveredMaterials, ["quantity", 0]),
    [data]
  );

  return (
    <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
      <div className=''>
        <div className='grid grid-cols-1 gap-4'>
          <div className=''>
            <span className='block text-sm font-light text-gray-700'>
              Removed Materials
            </span>
            <span className='text-xs font-light'>
              Meterials removed during removal of existing meter
            </span>
            <div className='mt-1 w-full overflow-hidden'>
              {recoveredMaterials?.length ? (
                <table className='min-w-full table-fixed divide-y divide-gray-300 border border-gray-300 rounded-md'>
                  <thead className='bg-gray-50'>
                    <tr className='divide-x divide-gray-200'>
                      <th
                        scope='col'
                        className='whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[80%]'
                      >
                        Name
                      </th>
                      <th
                        scope='col'
                        className='whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-right'
                      >
                        Quantity
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {recoveredMaterials?.map((material, idx) => (
                      <tr key={idx} className='divide-x divide-gray-200'>
                        <td className='whitespace- px-2 py-2 text-sm text-gray-700 text-ellipsis overflow-hidden '>
                          {material.material?.name || "N/A"}
                        </td>
                        <td className='whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right'>
                          {numeral(material?.quantity || 0).format("0,0")}{" "}
                          {material.material.quantityUnit}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className='rounded-md bg-amber-50 border border-amber-100 p-6 text-center mt-1 text-sm'>
                  No Materials Were Recorded
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeterRemovalMaterialInfoSummary;
