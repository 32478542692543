import { gql, useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import {
  OfficeCalendarHeader,
  OfficeHeader,
  Shimmers,
  TableComponent,
} from "components";
import _ from "lodash";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { Link, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  wrapClick,
  useUrlState,
  classNames,
  useTableData,
  useDownloadFile,
} from "utils";
import config from "config";
import AssignInvestigationServiceOrderContainer from "./assign";
import ReassignInvestigationServiceOrderContainer from "./reassign";
import ApproveInvestigationServiceOrderContainer from "./approve";
import RejectInvestigationServiceOrderContainer from "./disapprove";
import ResolveInvestigationServiceOrderContainer from "./resolve";
import ViewInvestigationServiceOrderContainer from "./view";
import { Avatar, SelectInput } from "components/core";
import { currentConfigVar } from "apollo/cache/config";
import ActionButton, { Action } from "components/buttons/action-button";
import InvestigationServiceOrdersFilterContainer from "./filter";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import ExportDataContainer from "./export";
import numeral from "numeral";

const GET_INVESTIGATION_SERVICE_ORDERS = gql`
  query GetInvestigationServiceOrders(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $status: InvestigationServiceOrderStatus
    $category: InvestigationServiceOrderCategory
    $priority: InvestigationServiceOrderPriority
    $fromDate: Date
    $toDate: Date
  ) {
    rows: getInvestigationServiceOrders(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
      category: $category
      priority: $priority
      fromDate: $fromDate
      toDate: $toDate
    ) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      contactPerson {
        fullName
        phoneNumber
      }
      location {
        address
        community
      }
      assigner {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      approver {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      disapprover {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
       
      }
      assignee {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      category
      status
      priority
      notes
      estimatedResolutionDate
      assignedAt
      startedAt
      resolvedAt
      completedAt
      rejectedAt
      createdAt
      updatedAt
    }
    count: getInvestigationServiceOrdersCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
      category: $category
      priority: $priority
      fromDate: $fromDate
      toDate: $toDate
    )
  }
`;

const GET_SERVICE_ORDER_SUMMARY = gql`
  query GetInvestigationServiceOrderSummary(
    $region: ID
    $district: ID
    $fromDate: Date
    $toDate: Date
  ) {
    summary: getInvestigationServiceOrderSummary(
      region: $region
      district: $district
      fromDate: $fromDate
      toDate: $toDate
    ) {
      Pending
      Assigned
      Resolved
      InProgress
      Completed
      Disapproved
    }
  }
`;

const orderStatuses = [
  { name: "Pending", href: "Pending" },
  { name: "Assigned", href: "Assigned" },
  { name: "In Progress", href: "InProgress" },
  { name: "Resolved", href: "Resolved" },
  { name: "Completed", href: "Completed" },
];
const altOrderStatuses = [{ name: "Disapproved", href: "Disapproved" }];

const searchOptions = [
  {
    label: "Code",
    value: "code",
    min: 4,
  },

  {
    label: "Contact Name ",
    value: "contactPerson.fullName",
    min: 4,
  },
  {
    label: "Contact Phone Number",
    value: "contactPerson.phoneNumber",
    min: 4,
  },
  {
    label: "Location Address",
    value: "location.address",
    min: 4,
  },
  {
    label: "Location Community",
    value: "location.community",
    min: 4,
  },
];

const InvestigationServiceOrdersPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [priority, setPriority] = useUrlState("priority");
  const [category, setCategory] = useUrlState("category");
  const [orderStatus] = useUrlState("orderStatus");
  const [searchBy, setSearchParameter] = useUrlState("search_field");
  const [searchValue, setSearch] = useUrlState("search");
  const [minSearch, setMinSearch] = useUrlState("minSearch");
  const updateSearchBy = (key: string) => {
    setSearchParameter(key);
    if (key) {
      setMinSearch(searchOptions.find((option) => option.value === key)?.min);
    }
  };
  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search:
        searchParams.search && searchBy
          ? (searchParams.search || "").toString()
          : undefined,
      searchFields: searchBy && searchValue ? [searchBy] : undefined,
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      status: searchParams.orderStatus || undefined,
      priority: searchParams.priority || undefined,
      category: searchParams.category || undefined,
      assignee: searchParams.assignee || undefined,
      approver: searchParams.approver || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
    }),
    [searchParams]
  );

  const { data, loading, networkStatus, refetch } = useQuery(
    GET_INVESTIGATION_SERVICE_ORDERS,
    {
      variables: filter,
      notifyOnNetworkStatusChange: false,
      onCompleted: () => {
        refetchSummary();
      },
    }
  );

  const { data: dataSummary, refetch: refetchSummary } = useQuery(
    GET_SERVICE_ORDER_SUMMARY,
    {
      variables: {
        region: searchParams.region || undefined,
        district: searchParams.district || undefined,
        fromDate: searchParams.fromDate || undefined,
        toDate: searchParams.toDate || undefined,
      },
      notifyOnNetworkStatusChange: false,
    }
  );

  const records = useTableData(data || {});

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "createdAt" } : {}),
        ...(!old?.month ? { month: moment().month() } : {}),
        ...(!old?.year ? { year: moment().year() } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className='flex-1 flex flex-col overflow-hidden bg-gray-50'>
      <OfficeCalendarHeader />
      <div className='block'>
        <div className='border-b border-gray-200 bg-white px-6'>
          <nav className='-mb-px flex space-x-8 items-center' aria-label='Tabs'>
            <Link
              search={(old) => ({
                ...old,
                orderStatus: undefined,
                page: config.constants.page,
                pageSize: config.constants.pageSize,
              })}
              className={classNames(
                !orderStatus
                  ? "border-primary-500 text-primary-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={!orderStatus ? "page" : undefined}
            >
              All Orders
              <span
                className={classNames(
                  !orderStatus
                    ? "bg-primary-100 text-primary-600"
                    : "bg-gray-100 text-gray-900",
                  "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                )}
              >
                {numeral(
                  _.sum(_.values(_.omit(dataSummary?.summary, "__typename")))
                ).format("0,0")}
              </span>
            </Link>
            {orderStatuses.map((_orderStatus) => (
              <Link
                key={_orderStatus.name}
                search={(old) => ({
                  ...old,
                  orderStatus: _orderStatus.href,
                  page: config.constants.page,
                  pageSize: config.constants.pageSize,
                })}
                className={classNames(
                  orderStatus === _orderStatus.href
                    ? "border-primary-500 text-primary-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={
                  orderStatus === _orderStatus.href ? "page" : undefined
                }
              >
                {_orderStatus.name}
                {_orderStatus.href ? (
                  <span
                    className={classNames(
                      orderStatus === _orderStatus.href
                        ? "bg-primary-100 text-primary-600"
                        : "bg-gray-100 text-gray-900",
                      "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                    )}
                  >
                    {numeral(
                      dataSummary?.summary?.[_orderStatus?.href] || 0
                    ).format("0,0")}
                  </span>
                ) : null}
              </Link>
            ))}
            <div className='h-6 w-px bg-gray-300' />
            {altOrderStatuses.map((_orderStatus) => (
              <Link
                key={_orderStatus.name}
                search={(old) => ({
                  ...old,
                  orderStatus: _orderStatus.href,
                  page: config.constants.page,
                  pageSize: config.constants.pageSize,
                })}
                className={classNames(
                  orderStatus === _orderStatus.href
                    ? "border-primary-500 text-primary-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={
                  orderStatus === _orderStatus.href ? "page" : undefined
                }
              >
                {_orderStatus.name}
                {_orderStatus.href && _orderStatus.href !== "BulkInitiation" ? (
                  <span
                    className={classNames(
                      orderStatus === _orderStatus.href
                        ? "bg-primary-100 text-primary-600"
                        : "bg-gray-100 text-gray-900",
                      "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                    )}
                  >
                    {numeral(
                      dataSummary?.summary?.[_orderStatus?.href] || 0
                    ).format("0,0")}
                  </span>
                ) : null}
              </Link>
            ))}
          </nav>
        </div>
      </div>
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"service orders"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            onSearchClicked={setSearch}
            disableSearch={!searchBy}
            renderSearchOption={() => (
              <SelectInput
                id={"searchBy"}
                label={""}
                values={{ searchBy }}
                handleChange={(e: any) => updateSearchBy(e.target.value)}
                handleBlur={undefined}
                options={[
                  {
                    label: "Search By",
                    value: "",
                  },
                  ...searchOptions.map((option) => ({
                    label: option.label,
                    value: option.value,
                  })),
                ]}
              />
            )}
            renderExport={({ exportOpen, setExportOpen }) => (
              <ExportDataContainer
                entityType={""}
                open={exportOpen}
                setOpen={setExportOpen}
                filter={filter}
              />
            )}
            renderFilter={({ filterOpen, setFilterOpen }) => (
              <InvestigationServiceOrdersFilterContainer
                open={filterOpen}
                setOpen={setFilterOpen}
              />
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Code | Date
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Customer
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Location
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Category
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Priority
                </th>
                {["Assigned", "InProgress", "Resolved"].includes(
                  orderStatus
                ) && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Assigned | ETA
                  </th>
                )}
                {["Disapproved"].includes(orderStatus) && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Disapproved
                  </th>
                )}
                {["Completed"].includes(orderStatus) && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Resolution Period
                  </th>
                )}
                {["Assigned", "InProgress", "Resolved", "Completed"].includes(
                  orderStatus
                ) && (
                  <>
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Assigner
                    </th>
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Assignee
                    </th>
                  </>
                )}
                {["Completed", "Disapproved"].includes(orderStatus) && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    {orderStatus === "Disapproved" ? "Disapprover" : "Approver"}
                  </th>
                )}
                {!orderStatus && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Status
                  </th>
                )}
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                {["Assigned", "InProgress", "Resolved"].includes(
                  orderStatus
                ) && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                {["Disapproved"].includes(orderStatus) && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                {["Completed"].includes(orderStatus) && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                )}

                {[
                  "Assigned",
                  "InProgress",
                  "Resolved",
                  "Completed",
                  "Disapproved",
                ].includes(orderStatus) && (
                  <>
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.AvatarShimmer />
                    </td>
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.AvatarShimmer />
                    </td>
                  </>
                )}
                {["Completed", "Disapproved"].includes(orderStatus) && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.AvatarShimmer />
                  </td>
                )}
                {!orderStatus && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.SingleShimmer />
                  </td>
                )}
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.ActionsShimmer actionsCount={4} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='font-medium text-gray-900 dark:text-gray-100'>
                    {item?.code || "N/A"}
                  </div>
                  <div className='font-medium text-gray-500 dark:text-gray-400'>
                    {moment(item?.createdAt).format(dateFormat)}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.contactPerson?.fullName || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-500'>
                    {item?.contactPerson?.phoneNumber || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.location?.address || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-500'>
                    {item?.location?.community || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      item?.category === "Premium"
                        ? `bg-primary-100 text-primary-800`
                        : "",
                      item?.category === "Standard"
                        ? `bg-gray-100 text-gray-800`
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 10 10'
                      fill='currentColor'
                      className='w-1.5 h-1.5'
                    >
                      <circle
                        fillRule='evenodd'
                        cx='5'
                        cy='5'
                        r='5'
                        clipRule='evenodd'
                      />
                    </svg>
                    <span>{item?.category || "Unknown"}</span>
                  </span>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      item?.priority === "High"
                        ? `bg-orange-100 text-orange-800 dark:bg-orange-600 dark:text-orange-100`
                        : "",
                      item?.priority === "Low"
                        ? `bg-gray-100 text-gray-800 dark:bg-gray-600 dark:text-gray-50`
                        : "",
                      item?.priority === "Critical"
                        ? `bg-red-100 text-red-800 dark:bg-red-600 dark:text-red-100`
                        : "",
                      item?.priority === "Medium"
                        ? `bg-yellow-100 text-yellow-800 dark:bg-yello-600 dark:text-yellow-100`
                        : "",
                      !item?.priority
                        ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 10 10'
                      fill='currentColor'
                      className='w-1.5 h-1.5'
                    >
                      <circle
                        fillRule='evenodd'
                        cx='5'
                        cy='5'
                        r='5'
                        clipRule='evenodd'
                      />
                    </svg>
                    <span>{item?.priority || "Unknown"}</span>
                  </span>
                </td>
                {["Assigned", "InProgress", "Resolved"].includes(
                  orderStatus
                ) && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                    <div className='text-gray-900 dark:text-gray-100'>
                      {item?.assignedAt
                        ? moment(item?.assignedAt).format(dateFormat)
                        : "N/A"}
                    </div>
                    <div className='text-gray-500 dark:text-gray-400'>
                      {item?.estimatedResolutionDate
                        ? moment(item?.estimatedResolutionDate).format(
                            dateFormat
                          )
                        : "N/A"}
                    </div>
                  </td>
                )}
                {["Disapproved"].includes(orderStatus) && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                    <div className='text-gray-900 dark:text-gray-100'>
                      <span className='text-xs mr-2 font-light'>from</span>
                      {item?.assignedAt
                        ? moment(item?.assignedAt).format(dateFormat)
                        : "N/A"}
                    </div>
                    {/* TODO: take rejectedAt out when model is modified */}
                    <div className='text-gray-500 dark:text-gray-400'>
                      <span className='text-xs mr-2 font-light'>to</span>
                      {item?.disapprovedAt || item?.rejectedAt
                        ? moment(
                            item?.disapprovedAt || item?.rejectedAt
                          ).format(dateFormat)
                        : "N/A"}
                    </div>
                  </td>
                )}
                {["Completed"].includes(orderStatus) && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                    <div className='text-gray-900 dark:text-gray-100'>
                      <span className='text-xs mr-2 font-light'>from</span>
                      {item?.assignedAt
                        ? moment(item?.assignedAt).format(dateFormat)
                        : "N/A"}
                    </div>
                    <div className='text-gray-500 dark:text-gray-400'>
                      <span className='text-xs mr-2 font-light'>to</span>
                      {item?.completedAt
                        ? moment(item?.completedAt).format(dateFormat)
                        : "N/A"}
                    </div>
                  </td>
                )}
                {["Assigned", "InProgress", "Resolved", "Completed"].includes(
                  orderStatus
                ) && (
                  <>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                      <div className='flex items-center'>
                        <div className='h-10 w-10 flex-shrink-0'>
                          <Avatar
                            disabled={true}
                            alt={
                              [
                                (item?.assigner?.lastName || "")?.trim(),
                                (item?.assigner?.firstName || "")?.trim(),
                              ]
                                .join(" ")
                                .trim() || "N A"
                            }
                            src={item?.assigner?.profileImageUrl || ""}
                          />
                        </div>
                        <div className='ml-4'>
                          <div className='text-gray-900 dark:text-gray-100'>
                            {[
                              (item?.assigner?.lastName || "")?.trim(),
                              (item?.assigner?.firstName || "")?.trim(),
                            ]
                              .join(" ")
                              .trim() || "N A"}
                          </div>
                          <div className='text-gray-500 dark:text-gray-400'>
                            {item?.assigner?.phoneNumber || "N/A"}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                      <div className='flex items-center'>
                        <div className='h-10 w-10 flex-shrink-0'>
                          <Avatar
                            disabled={true}
                            alt={
                              [
                                (item?.assignee?.lastName || "")?.trim(),
                                (item?.assignee?.firstName || "")?.trim(),
                              ]
                                .join(" ")
                                .trim() || "N A"
                            }
                            src={item?.assignee?.profileImageUrl || ""}
                          />
                        </div>
                        <div className='ml-4'>
                          <div className='text-gray-900 dark:text-gray-100'>
                            {[
                              (item?.assignee?.lastName || "")?.trim(),
                              (item?.assignee?.firstName || "")?.trim(),
                            ]
                              .join(" ")
                              .trim() || "N A"}
                          </div>
                          <div className='text-gray-500 dark:text-gray-400'>
                            {item?.assignee?.phoneNumber || "N/A"}
                          </div>
                        </div>
                      </div>
                    </td>
                  </>
                )}
                {["Completed", "Disapproved"].includes(orderStatus) && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                    <div className='flex items-center'>
                      <div className='h-10 w-10 flex-shrink-0'>
                        <Avatar
                          disabled={true}
                          alt={
                            [
                              (orderStatus === "Disapproved"
                                ? item?.disapprover?.lastName
                                : item?.approver?.lastName || ""
                              )?.trim(),
                              (orderStatus === "Disapproved"
                                ? item?.disapprover?.firstName
                                : item?.approver?.firstName || ""
                              )?.trim(),
                            ]
                              .join(" ")
                              .trim() || "N A"
                          }
                          src={
                            orderStatus === "Disapproved"
                              ? item?.disapprover?.profileImageUrl
                              : item?.approver?.profileImageUrl || ""
                          }
                        />
                      </div>
                      <div className='ml-4'>
                        <div className='text-gray-900 dark:text-gray-100'>
                          {[
                            (orderStatus === "Disapproved"
                              ? item?.disapprover?.lastName
                              : item?.approver?.lastName || ""
                            )?.trim(),
                            (orderStatus === "Disapproved"
                              ? item?.disapprover?.firstName
                              : item?.approver?.firstName || ""
                            )?.trim(),
                          ]
                            .join(" ")
                            .trim() || "N A"}
                        </div>
                        <div className='text-gray-500 dark:text-gray-400'>
                          {orderStatus === "Disapproved"
                            ? item?.disapprover?.phoneNumber
                            : item?.approver?.phoneNumber || "N/A"}
                        </div>
                      </div>
                    </div>
                  </td>
                )}
                {!orderStatus && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                    <span
                      className={classNames(
                        "bg-gray-100 text-gray-800",
                        item?.status === "Acknowledged"
                          ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50`
                          : "",
                        item?.status === "Expired"
                          ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50`
                          : "",
                        item?.status === "Failed"
                          ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                          : "",
                        item?.status === "Posted"
                          ? `bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50`
                          : "",
                        item?.status === "Pending"
                          ? `bg-[#eec96b5a] text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50`
                          : "",
                        item?.status === "Success"
                          ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                          : "",
                        item?.status === "Reversed"
                          ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                          : "",
                        !item?.status
                          ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                          : "",
                        "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                      )}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 10 10'
                        fill='currentColor'
                        className='w-1.5 h-1.5'
                      >
                        <circle
                          fillRule='evenodd'
                          cx='5'
                          cy='5'
                          r='5'
                          clipRule='evenodd'
                        />
                      </svg>
                      <span>{item?.status || "Unknown"}</span>
                    </span>
                  </td>
                )}
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {["Pending", "Assigned"].includes(orderStatus) && (
                      <>
                        {!item?.assignee ? (
                          <ActionButton
                            action='assign'
                            onClick={dispatchAction(item?._id, "assign")}
                          />
                        ) : (
                          <ActionButton
                            action='reassign'
                            onClick={dispatchAction(item?._id, "reassign")}
                          />
                        )}
                      </>
                    )}
                    {/* {["Assigned", "InProgress"].includes(orderStatus) && (
                      <ActionButton
                        action='resolve'
                        onClick={dispatchAction(item?._id, "resolve")}
                      />
                    )} */}
                    {["Resolved"].includes(orderStatus) && (
                      <ActionButton
                        action='approve'
                        onClick={dispatchAction(item?._id, "approve")}
                      />
                    )}
                    {["Resolved"].includes(orderStatus) && (
                      <ActionButton
                        action='disapprove'
                        onClick={dispatchAction(item?._id, "reject")}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {!!searchParams.id?.length && (
        <>
          <AssignInvestigationServiceOrderContainer
            open={modal === "assign"}
            setOpen={(val: boolean) => setModal(val ? "assign" : undefined)}
            refetch={refetch}
          />
          <ReassignInvestigationServiceOrderContainer
            open={modal === "reassign"}
            setOpen={(val: boolean) => setModal(val ? "reassign" : undefined)}
            refetch={refetch}
          />
          {/* <ResolveInvestigationServiceOrderContainer
            open={modal === "resolve"}
            setOpen={(val: boolean) => setModal(val ? "resolve" : undefined)}
            refetch={refetch}
          /> */}
          <ApproveInvestigationServiceOrderContainer
            open={modal === "approve"}
            setOpen={(val: boolean) => setModal(val ? "approve" : undefined)}
            refetch={refetch}
          />
          <RejectInvestigationServiceOrderContainer
            open={modal === "reject"}
            setOpen={(val: boolean) => setModal(val ? "reject" : undefined)}
            refetch={refetch}
          />
          <ViewInvestigationServiceOrderContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default InvestigationServiceOrdersPage;
