import { wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { ApproveInstallationServiceOrderForm, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_SERVICE_ORDER } from "./view";


const APPROVE_SERVICE_ORDER = gql`
  mutation ApproveInstallationServiceOrder(
    $id: ID!
    $notes: String!
  ) {
    approveInstallationServiceOrder: approveBacklogInstallationServiceOrder(
      id: $id
      notes: $notes
    ) {
      _id
    }
  }
`

export default function ApproveInstallationServiceOrderContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const searchParams = useSearch<LocationGenerics>();
  const { loading } = useQuery(GET_SERVICE_ORDER, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });
  const [approveInstallationServiceOrder] = useMutation(APPROVE_SERVICE_ORDER)

  const form = useFormik({
    initialValues: {
      notes: "",
    },
    onSubmit: async (values) => {
      await approveInstallationServiceOrder({
        variables: {
          id: searchParams.id,
          ...values
        }
      }).then(({ data }) => {
        if (data.approveInstallationServiceOrder._id) {
          toast(JSON.stringify({ type: "success", title: "Service Order Approved Successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not approve Service Order" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Approve Service Order"
      size="4xl"
      description="Provide notes for approving service order"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={form.isSubmitting}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {form.isSubmitting ? "Approving Service Order..." : "Approve Service Order"}
          </button>
        </>
      )}>
      <ApproveInstallationServiceOrderForm
        form={form}
      />
    </Modal>
  )
}