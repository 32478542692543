import { gql, useQuery } from "@apollo/client";
import { Modal, AnomalyDetailsView, ReadingAnomalyView } from "components";
import { Action } from "components/buttons/action-button";
import { useState, useEffect } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, classNames, useUrlState } from "utils";

const GET_READING_ANOMALY = gql`
  query GetReadingAnomaly($id: ID!) {
    readingAnomaly: getReadingAnomaly(id: $id) {
      _id
      code
      createdAt
      updatedAt
      anomalies
      lastReportedBy {
        _id
        code
        lastName
        firstName
        gender
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
      }
      servicePoint {
        _id
        code
        qrCode
        geoLocation {
          type
          coordinates
        }
        address
        ghanaPostAddress
        propertyCode
        customerCode
        accountCode
        meterCode
        serviceType
        serviceClass
        tariffClass {
          _id
          code
          name
        }
        geoCode
        structureNumber
        transformerPhase
        meterLocation
        meterHeight
        transformerNumber
        currentTransformerRatio
        deliveryPointNumber
        contractedDemand
        poleNumber
        energyCertificateNumber
        energyCertificateDocumentUrl
        status
        createdAt
        updatedAt
      }
      customer {
        _id
        code
        customerType
      
        organization {
          name
        }
        representative {
          fullName
          phoneNumber
          emailAddress
          profileImageUrl
          ghanaCardNumber
        }
        status
        createdAt
        updatedAt
      }
      meter {
        _id
        code
        batchCode
        brand {
          _id
          code
          name
        }
        model {
          _id
          code
          name
          phase
          type
          digits
          voltage
          currentRating
          mechanism
          communicationType
        }
        system {
          _id
          code
          name
        }
        modelType
        status
        balance
        balanceUpdatedAt
        meta {
          lastReadingValue
          lastReadingDate
        }
        meterCode
        location
        createdAt
        updatedAt
      }
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      block {
        _id
        code
        name
      }
      round {
        _id
        code
        name
      }
      plot {
        _id
        code
        name
      }
      reports {
        _id
        anomalies
        description
        imageUrl
        reader {
          _id
          code
          lastName
          firstName
          phoneNumber
          emailAddress
          profileImageUrl
        }
        readingSet {
          _id
          code
          name
          month
          year
        }
        meta {
          anomalyLocation {
            type
            coordinates
          }
          anomalyImageLocation {
            type
            coordinates
          }
        }
        createdAt
      }
      reportsCount
      status
      lastReportedAt
    }
  }
`;

const tabs = [
  { name: "Customer Details", href: "Customer" },
  { name: "Anomaly Details", href: "Anomaly" },
];

export default function ViewReadingAnomalyContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [, setModal] = useUrlState("modal");

  const [tab, setTab] = useState("Anomaly");
  const { data, loading } = useQuery(GET_READING_ANOMALY, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const __setTab = (tab: string) => () => {
    setTab(tab);
  };

  const dispatchAction =
    (id: string, servicePoint: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            servicePoint,
            modal: action,
          }),
        });
      };

  useEffect(() => {
    setTab("Anomaly");
  }, [searchParams.id]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      hidePadding={true}
      title="Anomaly Information"
      description="Details of reading anomaly are shown below"
      loading={loading}
      size="4xl"
      renderActions={() => (
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={dispatchAction(data?.readingAnomaly?._id, data?.readingAnomaly?.servicePoint?._id, "update")}
        >
          Initiate Replacement
        </button>
      )}
    >
      <>
        <div className="block">
          <div className="border-b border-gray-200 bg-white px-6">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((_tab) => (
                <button
                  key={_tab.name}
                  onClick={wrapClick(__setTab(_tab.href))}
                  className={classNames(
                    tab === _tab.href
                      ? "border-primary-500 text-primary-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                    "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={tab === _tab.href ? "page" : undefined}
                >
                  {_tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
        <div className="flex-1 w-full max-h-[65vh] overflow-y-auto  sm:p-6">
          {tab === "Anomaly" && (
            <AnomalyDetailsView
              anomaly={data?.readingAnomaly}
            />
          )}
          {tab === "Customer" && (
            <ReadingAnomalyView anomaly={data?.readingAnomaly} />
          )}
        </div>
      </>
    </Modal>
  );
}
