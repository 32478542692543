import { FC } from "react";
import _ from "lodash";
import numeral from "numeral";
import moment from "moment";
import { ICurrentConfig } from "apollo/cache/config";
import { wrapImage } from "utils";

const LastPaymentInfoSummary: FC<{ data: any; config: ICurrentConfig }> = ({
  data,
  config: { dateFormat },
}) => (
  <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
    <div className=''>
      <span className='text-xs font-light'>Last Payment Information</span>
      <div className='grid grid-cols-3 gap-6 mt-2'>
        <div>
          <span className='block text-sm font-light text-gray-700'>
             Payment Amount (GHS)
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.paymentAmount
              ? numeral(data?.paymentAmount || 0).format("0,0.00")
              : "N/A"}
          </div>
        </div>

        <div className=''>
          <span className='block text-sm font-light text-gray-700'>
             Payment Date
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.paymentDate
              ? moment(data?.paymentDate).format(dateFormat)
              : "N/A"}
          </div>
        </div>
        <div>
          <span className='block text-sm font-light text-gray-700'>
            Receipt Number
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.receiptNumber || "N/A"}
          </div>
        </div>

        <div className=' col-span-2'>
          <span className='block text-sm font-light text-gray-700'>
            Receipt Image
          </span>
          <div className='mt-2 grid grid-cols-1 gap-3'>
            {wrapImage(
              <img
                src={data?.receiptImageUrl}
                alt={"back"}
                className='w-full h-48 text-xs object-cover object-center rounded'
              />
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default LastPaymentInfoSummary;
