import { gql, useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { OfficeCalendarHeader, Shimmers, TableComponent } from "components";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { Link, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  wrapClick,
  useUrlState,
  classNames,
  useTableData,
  useDownloadFile,
} from "utils";
import config from "config";
import AssignBacklogInstallationServiceOrderContainer from "./assign";
import ApproveBacklogInstallationServiceOrderContainer from "./approve";
import ViewBacklogInstallationServiceOrderContainer from "./view";
import { Avatar, SearchSelectInput, SelectInput } from "components/core";
import { currentConfigVar } from "apollo/cache/config";
import ActionButton, { Action } from "components/buttons/action-button";
import lodash from "lodash";
import { useBacklogInstallationServiceOrders } from "./hooks";
import numeral from "numeral";
import DisapproveBacklogInstallationServiceOrderContainer from "./disapprove";
import toast from "react-hot-toast";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import ExportDataContainer from "./export";

const searchOptions = [
  { label: "Code", value: "code", min: 4 },
  { label: "Service Request Number", value: "serviceRequestCode", min: 4 },
  { label: "Service Point Number", value: "servicePointCode", min: 5 },
  { label: "Contact Name", value: "contactPerson.fullName", min: 4 },
  { label: "Contact Phone Number", value: "contactPerson.phoneNumber", min: 4 },
  { label: "Location Address", value: "location.address", min: 4 },
  { label: "Location Community", value: "location.community", min: 4 },
];

const GET_SERVICE_ORDER_SUMMARY = gql`
  query GetBacklogInstallationServiceOrderSummary(
    $region: ID
    $district: ID
    $fromDate: Date
    $toDate: Date
  ) {
    getBacklogInstallationServiceOrderSummary(
      region: $region
      district: $district
      fromDate: $fromDate
      toDate: $toDate
    ) {
      Pending
      AssignedToSupplier
      AssignedToInstaller
      Disapproved
      InProgress
      Resolved
      Completed
    }
  }
`;

const orderStatuses = [
  { name: "Pending", href: "Pending" },
  { name: "Assigned To Supplier", href: "AssignedToSupplier" },
  { name: "Assigned To Installer", href: "AssignedToInstaller" },
  { name: "In Progress", href: "InProgress" },
  { name: "Resolved", href: "Resolved" },
  { name: "Completed", href: "Completed" },
];

const altOrderStatuses = [{ name: "Disapproved", href: "Disapproved" }];

const BacklogInstallationServiceOrdersPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [orderStatus] = useUrlState("orderStatus");
  const [prepaidSyncStatus, setPrepaidSyncStatus] =
    useUrlState("prepaidSyncStatus");

  const [searchBy, setSearchParameter] = useUrlState("search_field");
  const [searchValue, setSearch] = useUrlState("search");
  const [minSearch, setMinSearch] = useUrlState("minSearch");
  const updateSearchBy = (key: string) => {
    setSearchParameter(key);
    if (key) {
      setMinSearch(searchOptions.find((option) => option.value === key)?.min);
    }
  };
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const revokeAction =
    (action: Exclude<Action, "expand" | "goto" | "clone">, val: boolean) =>
    () => {
      // alert(JSON.stringify({ action, val }))
      setModal(val ? action : undefined);
      navigate({
        search: (old) => ({
          ...old,
          id: val ? old?.id : undefined,
        }),
      });
    };

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search:
        searchParams.search && searchBy
          ? (searchParams.search || "").toString()
          : undefined,
      searchFields: searchBy && searchValue ? [searchBy] : undefined,
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      status: searchParams.orderStatus || undefined,
      installationMeterSystemSyncStatus:
        searchParams.prepaidSyncStatus || undefined,
      priority: searchParams.priority || undefined,
      category: searchParams.category || undefined,
      assignee: searchParams.assignee || undefined,
      approver: searchParams.approver || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
    }),
    [searchParams]
  );

  const { installationServiceOrders, count, loading, networkStatus, refetch } =
    useBacklogInstallationServiceOrders(filter);

  const records = useTableData({ rows: installationServiceOrders, count });

  const { data: dataSummary } = useQuery(GET_SERVICE_ORDER_SUMMARY, {
    variables: {
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
    },
    notifyOnNetworkStatusChange: false,
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "createdAt" } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className='flex-1 flex flex-col overflow-hidden bg-gray-50'>
      <OfficeCalendarHeader />
      <div className='block'>
        <div className='border-b border-gray-200 bg-white px-6'>
          <nav
            className='-mb-px flex space-x-4 items-center overflow-x-scroll no-scrollbar'
            aria-label='Tabs'
          >
            <Link
              search={(old) => ({
                ...old,
                orderStatus: undefined,
                page: config.constants.page,
                pageSize: config.constants.pageSize,
              })}
              className={classNames(
                !orderStatus
                  ? "border-primary-500 text-primary-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={!orderStatus ? "page" : undefined}
            >
              All Orders
              <span
                className={classNames(
                  !orderStatus
                    ? "bg-primary-100 text-primary-600"
                    : "bg-gray-100 text-gray-900",
                  "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                )}
              >
                {numeral(
                  lodash.sum(
                    lodash.values(
                      lodash.omit(
                        dataSummary?.getBacklogInstallationServiceOrderSummary,
                        "__typename"
                      )
                    )
                  )
                ).format("0,0")}
              </span>
            </Link>
            {orderStatuses.map((_orderStatus) => (
              <Link
                key={_orderStatus.name}
                search={(old) => ({
                  ...old,
                  orderStatus: _orderStatus.href,
                  page: config.constants.page,
                  pageSize: config.constants.pageSize,
                })}
                className={classNames(
                  orderStatus === _orderStatus.href
                    ? "border-primary-500 text-primary-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={
                  orderStatus === _orderStatus.href ? "page" : undefined
                }
              >
                {_orderStatus.name}
                {_orderStatus.href ? (
                  <span
                    className={classNames(
                      orderStatus === _orderStatus.href
                        ? "bg-primary-100 text-primary-600"
                        : "bg-gray-100 text-gray-900",
                      "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                    )}
                  >
                    {numeral(
                      dataSummary?.getBacklogInstallationServiceOrderSummary?.[
                        _orderStatus?.href
                      ] || 0
                    ).format("0,0")}
                  </span>
                ) : null}
              </Link>
            ))}
            <div className='h-6 w-px bg-gray-300' />
            {altOrderStatuses.map((_orderStatus) => (
              <Link
                key={_orderStatus.name}
                search={(old) => ({
                  ...old,
                  orderStatus: _orderStatus.href,
                  page: config.constants.page,
                  pageSize: config.constants.pageSize,
                })}
                className={classNames(
                  orderStatus === _orderStatus.href
                    ? "border-primary-500 text-primary-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={
                  orderStatus === _orderStatus.href ? "page" : undefined
                }
              >
                {_orderStatus.name}
                {_orderStatus.href && _orderStatus.href !== "BulkInitiation" ? (
                  <span
                    className={classNames(
                      orderStatus === _orderStatus.href
                        ? "bg-primary-100 text-primary-600"
                        : "bg-gray-100 text-gray-900",
                      "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                    )}
                  >
                    {numeral(
                      dataSummary?.getBacklogInstallationServiceOrderSummary?.[
                        _orderStatus?.href
                      ] || 0
                    ).format("0,0")}
                  </span>
                ) : null}
              </Link>
            ))}
          </nav>
        </div>
      </div>
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"service orders"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            onSearchClicked={setSearch}
            disableSearch={!searchBy}
            renderExport={({ exportOpen, setExportOpen }) => (
              <ExportDataContainer
                entityType={""}
                open={exportOpen}
                setOpen={setExportOpen}
                filter={filter}
              />
            )}
            renderSearchOption={() => (
              <SelectInput
                id={"searchBy"}
                label={""}
                values={{ searchBy }}
                handleChange={(e: any) => updateSearchBy(e.target.value)}
                handleBlur={undefined}
                options={[
                  {
                    label: "Search By",
                    value: "",
                  },
                  ...searchOptions.map((option) => ({
                    label: option.label,
                    value: option.value,
                  })),
                ]}
              />
            )}
            renderHeaderItems={() => (
              <div className='mt-4 sm:mt-0.5 sm:ml-16 sm:flex space-x-2'>
                <SearchSelectInput
                  id='prepaidSyncStatus'
                  labelHidden={true}
                  options={[
                    { label: { title: "Pending" }, value: "Pending" },
                    {
                      label: { title: "Forward Synced" },
                      value: "ForwardSynced",
                    },
                    {
                      label: { title: "Reverse Synced" },
                      value: "ReverseSynced",
                    },
                    { label: { title: "Completed" }, value: "Completed" },
                  ]}
                  label={""}
                  placeholder='Select Sync Status'
                  setFieldValue={(_: any, value: string) =>
                    setPrepaidSyncStatus(value)
                  }
                  values={{ prepaidSyncStatus }}
                />
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Code | Date
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Customer
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Location
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Service Point
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Tariff Class
                </th>
                {["AssignedToInstaller", "InProgress", "Resolved"].includes(
                  orderStatus
                ) && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Assigned Meter
                  </th>
                )}
                {["Completed"].includes(orderStatus) && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Installed Meter
                  </th>
                )}
                {[
                  "AssignedToInstaller",
                  "InProgress",
                  "Resolved",
                  "Completed",
                  "Disapproved",
                ].includes(orderStatus) && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Prepayment Sync Status
                  </th>
                )}
                {["AssignedToInstaller", "InProgress", "Resolved"].includes(
                  orderStatus
                ) && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Assigned | ETA
                  </th>
                )}
                {["Disapproved"].includes(orderStatus) && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Disapproved
                  </th>
                )}
                {["Completed"].includes(orderStatus) && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Resolution Period
                  </th>
                )}
                {[
                  "AssignedToSupplier",
                  "AssignedToInstaller",
                  "InProgress",
                  "Resolved",
                  "Completed",
                  "Disapproved",
                ].includes(orderStatus) && (
                  <>
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Assigned By
                    </th>
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Assignee
                    </th>
                  </>
                )}
                {["Completed", "Disapproved"].includes(orderStatus) && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    {orderStatus === "Disapproved" ? "Disapprover" : "Approver"}
                  </th>
                )}
                {!orderStatus && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Status
                  </th>
                )}
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                {[
                  "AssignedToInstaller",
                  "InProgress",
                  "Resolved",
                  "Completed",
                ].includes(orderStatus) && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                {[
                  "AssignedToInstaller",
                  "InProgress",
                  "Resolved",
                  "Completed",
                ].includes(orderStatus) && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.SingleShimmer />
                  </td>
                )}
                {["AssignedToInstaller", "InProgress", "Resolved"].includes(
                  orderStatus
                ) && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                {["Disapproved"].includes(orderStatus) && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                {["Completed"].includes(orderStatus) && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                {[
                  "AssignedToSupplier",
                  "AssignedToInstaller",
                  "InProgress",
                  "Resolved",
                  "Completed",
                  "Disapproved",
                ].includes(orderStatus) && (
                  <>
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.AvatarShimmer />
                    </td>
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.AvatarShimmer />
                    </td>
                  </>
                )}
                {["Completed", "Disapproved"].includes(orderStatus) && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.AvatarShimmer />
                  </td>
                )}
                {!orderStatus && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.SingleShimmer />
                  </td>
                )}

                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.ActionsShimmer actionsCount={4} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='font-medium text-gray-900 dark:text-gray-100'>
                    {item?.code || "N/A"}
                  </div>
                  <div className='font-medium text-gray-500 dark:text-gray-400'>
                    {moment(item?.createdAt).format(dateFormat)}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.contactPerson?.fullName || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-500'>
                    {item?.contactPerson?.phoneNumber || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.location?.address || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-500'>
                    {item?.location?.community || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.servicePoint?.code || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-500'>
                    {item?.servicePoint?.geoCode || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.servicePoint.tariffClassCode || "N/A"} (
                    {item?.servicePoint?.tariffClassName || "N/A"})
                  </div>
                  <div className='text-gray-500 dark:text-gray-500'>
                    {item?.requestedMeterPhase
                      ?.toLowerCase?.()
                      ?.replace("phase", "")
                      ?.trim?.() || "N/A"}{" "}
                    Phase
                  </div>
                </td>
                {["AssignedToInstaller", "InProgress", "Resolved"].includes(
                  orderStatus
                ) && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                    <div className='text-gray-900 dark:text-gray-100'>
                      {item?.installationMeter?.code || "N/A"}
                    </div>
                    <div className='text-gray-500 dark:text-gray-500'>
                      {item?.installationMeter?.modelMeta?.brandName || "N/A"} -{" "}
                      {item?.installationMeter?.modelMeta?.modelName || "N/A"}
                    </div>
                  </td>
                )}
                {["Disapproved"].includes(orderStatus) && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                    <div className='text-gray-900 dark:text-gray-100'>
                      <span className='text-xs mr-2 font-light'>from</span>
                      {item?.assignedAt
                        ? moment(item?.assignedAt).format(dateFormat)
                        : "N/A"}
                    </div>
                    <div className='text-gray-500 dark:text-gray-400'>
                      <span className='text-xs mr-2 font-light'>to</span>
                      {item?.disapprovedAt
                        ? moment(item?.disapprovedAt).format(dateFormat)
                        : "N/A"}
                    </div>
                  </td>
                )}
                {["Completed"].includes(orderStatus) && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                    <div className='text-gray-900 dark:text-gray-100'>
                      {item?.installedMeter?.code || "N/A"}
                    </div>
                    <div className='text-gray-500 dark:text-gray-500'>
                      {item?.installedMeter?.modelMeta?.brandName || "N/A"} -{" "}
                      {item?.installedMeter?.modelMeta?.modelName || "N/A"}
                    </div>
                  </td>
                )}
                {[
                  "AssignedToInstaller",
                  "InProgress",
                  "Resolved",
                  "Completed",
                ].includes(orderStatus) && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                    <span
                      className={classNames(
                        "bg-gray-100 text-gray-800",
                        item?.installationMeterSystemSyncStatus ===
                          "ForwardSynced"
                          ? "bg-blue-100 text-blue-800 dark:bg-blue-600 dark:text-blue-100"
                          : "",
                        item?.installationMeterSystemSyncStatus === "Completed"
                          ? "bg-green-100 text-green-800 dark:bg-green-600 dark:text-green-50"
                          : "",
                        item?.installationMeterSystemSyncStatus ===
                          "ReverseSynced"
                          ? "bg-orange-100 text-orange-800 dark:bg-orange-600 dark:text-orange-100"
                          : "",
                        item?.installationMeterSystemSyncStatus === "Pending"
                          ? "bg-yellow-100 text-yellow-800 dark:bg-yello-600 dark:text-yellow-100"
                          : "",
                        !item?.installationMeterSystemSyncStatus
                          ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                          : "",
                        "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                      )}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 10 10'
                        fill='currentColor'
                        className='w-1.5 h-1.5'
                      >
                        <circle
                          fillRule='evenodd'
                          cx='5'
                          cy='5'
                          r='5'
                          clipRule='evenodd'
                        />
                      </svg>
                      <span>
                        {item?.installationMeterSystemSyncStatus || "Unknown"}
                      </span>
                    </span>
                  </td>
                )}
                {["AssignedToInstaller", "InProgress", "Resolved"].includes(
                  orderStatus
                ) && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                    <div className='text-gray-900 dark:text-gray-100'>
                      {item?.assignedAt
                        ? moment(item?.assignedAt).format(dateFormat)
                        : "N/A"}
                    </div>
                    <div className='text-gray-500 dark:text-gray-400'>
                      {item?.estimatedResolutionDate
                        ? moment(item?.estimatedResolutionDate).format(
                            dateFormat
                          )
                        : "N/A"}
                    </div>
                  </td>
                )}
                {["Completed"].includes(orderStatus) && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                    <div className='text-gray-900 dark:text-gray-100'>
                      <span className='text-xs mr-2 font-light'>from</span>
                      {item?.assignedAt
                        ? moment(item?.assignedAt).format(dateFormat)
                        : "N/A"}
                    </div>
                    <div className='text-gray-500 dark:text-gray-400'>
                      <span className='text-xs mr-2 font-light'>to</span>
                      {item?.completedAt
                        ? moment(item?.completedAt).format(dateFormat)
                        : "N/A"}
                    </div>
                  </td>
                )}
                {[
                  "AssignedToSupplier",
                  "AssignedToInstaller",
                  "InProgress",
                  "Resolved",
                  "Completed",
                ].includes(orderStatus) && (
                  <>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                      <div className='flex items-center'>
                        <div className='h-10 w-10 flex-shrink-0'>
                          <Avatar
                            disabled={true}
                            alt={
                              [
                                (item?.assigner?.lastName || "")?.trim(),
                                (item?.assigner?.firstName || "")?.trim(),
                              ]
                                .join(" ")
                                .trim() || "N A"
                            }
                            src={item?.assigner?.profileImageUrl || ""}
                          />
                        </div>
                        <div className='ml-4'>
                          <div className='text-gray-900 dark:text-gray-100'>
                            {[
                              (item?.assigner?.lastName || "")?.trim(),
                              (item?.assigner?.firstName || "")?.trim(),
                            ]
                              .join(" ")
                              .trim() || "N A"}
                          </div>
                          <div className='text-gray-500 dark:text-gray-400'>
                            {item?.assigner?.phoneNumber || "N/A"}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                      {item.assigneeType === "MeterManufacturingContractor" ? (
                        <div className='flex items-center'>
                          <div className='h-10 w-10 flex-shrink-0'>
                            <Avatar
                              disabled={true}
                              alt={item?.meterContractor?.name || "N A"}
                            />
                          </div>
                          <div className='ml-4'>
                            <div className='text-gray-900 dark:text-gray-100'>
                              {item?.meterContractor?.name || "N/A"}
                            </div>
                            <div className='text-gray-500 dark:text-gray-400'>
                              Meter Manufacturing Contractor
                            </div>
                          </div>
                        </div>
                      ) : item.assigneeType === "FarmingOutContractor" ? (
                        <div className='flex items-center'>
                          <div className='h-10 w-10 flex-shrink-0'>
                            <Avatar
                              disabled={true}
                              alt={item?.farmingOutContractor?.name || "N A"}
                            />
                          </div>
                          <div className='ml-4'>
                            <div className='text-gray-900 dark:text-gray-100'>
                              {item?.farmingOutContractor?.name || "N/A"}
                            </div>
                            <div className='text-gray-500 dark:text-gray-400'>
                              Farming Out Contractor
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className='flex items-center'>
                          <div className='h-10 w-10 flex-shrink-0'>
                            <Avatar
                              disabled={true}
                              alt={
                                [
                                  (item?.assignee?.lastName || "")?.trim(),
                                  (item?.assignee?.firstName || "")?.trim(),
                                ]
                                  .join(" ")
                                  .trim() || "N A"
                              }
                              src={item?.assignee?.profileImageUrl || ""}
                            />
                          </div>
                          <div className='ml-4'>
                            <div className='text-gray-900 dark:text-gray-100'>
                              {[
                                (item?.assignee?.lastName || "")?.trim(),
                                (item?.assignee?.firstName || "")?.trim(),
                              ]
                                .join(" ")
                                .trim() || "N A"}
                            </div>
                            <div className='text-gray-500 dark:text-gray-400'>
                              {item?.assignee?.phoneNumber || "N/A"}
                            </div>
                          </div>
                        </div>
                      )}
                    </td>
                  </>
                )}
                {["Completed", "Disapproved"].includes(orderStatus) && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                    <div className='flex items-center'>
                      <div className='h-10 w-10 flex-shrink-0'>
                        <Avatar
                          disabled={true}
                          alt={
                            [
                              (orderStatus === "Disapproved"
                                ? item?.disapprover?.lastName
                                : item?.approver?.lastName || ""
                              )?.trim(),
                              (orderStatus === "Disapproved"
                                ? item?.disapprover?.firstName
                                : item?.approver?.firstName || ""
                              )?.trim(),
                            ]
                              .join(" ")
                              .trim() || "N A"
                          }
                          src={
                            orderStatus === "Disapproved"
                              ? item?.disapprover?.profileImageUrl
                              : item?.approver?.profileImageUrl || ""
                          }
                        />
                      </div>
                      <div className='ml-4'>
                        <div className='text-gray-900 dark:text-gray-100'>
                          {[
                            (orderStatus === "Disapproved"
                              ? item?.disapprover?.lastName
                              : item?.approver?.lastName || ""
                            )?.trim(),
                            (orderStatus === "Disapproved"
                              ? item?.disapprover?.firstName
                              : item?.approver?.firstName || ""
                            )?.trim(),
                          ]
                            .join(" ")
                            .trim() || "N A"}
                        </div>
                        <div className='text-gray-500 dark:text-gray-400'>
                          {orderStatus === "Disapproved"
                            ? item?.disapprover?.phoneNumber
                            : item?.approver?.phoneNumber || "N/A"}
                        </div>
                      </div>
                    </div>
                  </td>
                )}
                {!orderStatus && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                    <span
                      className={classNames(
                        "bg-gray-100 text-gray-800",
                        item?.status === "Acknowledged"
                          ? "bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50"
                          : "",
                        item?.status === "Expired"
                          ? "bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50"
                          : "",
                        item?.status === "Failed"
                          ? "bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50"
                          : "",
                        item?.status === "Posted"
                          ? "bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50"
                          : "",
                        item?.status === "Pending"
                          ? "bg-[#eec96b5a] text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50"
                          : "",
                        item?.status === "Success"
                          ? "bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50"
                          : "",
                        item?.status === "Reversed"
                          ? "bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50"
                          : "",
                        !item?.status
                          ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                          : "",
                        "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                      )}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 10 10'
                        fill='currentColor'
                        className='w-1.5 h-1.5'
                      >
                        <circle
                          fillRule='evenodd'
                          cx='5'
                          cy='5'
                          r='5'
                          clipRule='evenodd'
                        />
                      </svg>
                      <span>{item?.status || "Unknown"}</span>
                    </span>
                  </td>
                )}
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {[
                      "Pending",
                      "AssignedToSupplier",
                      "AssignedToInstaller",
                      "InProgress",
                    ].includes(orderStatus) && (
                      <>
                        {item?.status === "Pending" ? (
                          <ActionButton
                            action='assign'
                            onClick={dispatchAction(item?._id, "assign")}
                          />
                        ) : (
                          <ActionButton
                            action='reassign'
                            disabled={
                              item.assigneeType ===
                              "MeterManufacturingContractor"
                            }
                            onClick={dispatchAction(item?._id, "assign")}
                          />
                        )}
                      </>
                    )}
                    {["Resolved"].includes(orderStatus) && (
                      <ActionButton
                        action='approve'
                        disabled={
                          item.assigneeType === "MeterManufacturingContractor"
                        }
                        onClick={dispatchAction(item?._id, "approve")}
                      />
                    )}
                    {["Resolved"].includes(orderStatus) && (
                      <ActionButton
                        action='disapprove'
                        disabled={
                          item.assigneeType === "MeterManufacturingContractor"
                        }
                        onClick={dispatchAction(item?._id, "disapprove")}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {!!searchParams.id?.length && (
        <>
          {modal === "assign" && (
            <AssignBacklogInstallationServiceOrderContainer
              open={modal === "assign"}
              setOpen={(val: boolean) => setModal(val ? "assign" : "view")}
              refetch={refetch}
            />
          )}
          {modal === "approve" && (
            <ApproveBacklogInstallationServiceOrderContainer
              open={modal === "approve"}
              setOpen={(val: boolean) => setModal(val ? "approve" : "view")}
              refetch={refetch}
            />
          )}
          {modal === "disapprove" && (
            <DisapproveBacklogInstallationServiceOrderContainer
              open={modal === "disapprove"}
              setOpen={(val: boolean) => setModal(val ? "disapprove" : "view")}
              refetch={refetch}
            />
          )}

          <ViewBacklogInstallationServiceOrderContainer
            open={modal === "view"}
            setOpen={(val: boolean) => revokeAction("view", val)()}
          />
        </>
      )}
    </main>
  );
};

export default BacklogInstallationServiceOrdersPage;
