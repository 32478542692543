import { FC } from "react";
import { IResolveInstallationServiceOrderFormSchema } from "./schema";
import numeral from "numeral";

const MaterialInfoSummary: FC<{
  data: any;
}> = ({ data }) => (
  <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
    <div className=''>
      <span className='text-xs font-light'>Installation Information</span>
      <div className='grid grid-cols-3 gap-6 mt-2'>
        <div>
          <span className='block text-sm font-light text-gray-700'>
            Installation Type
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.installationType?.name || "N/A"}
          </div>
        </div>
      </div>
    </div>
    <div className='pt-6'>
      <span className='text-xs font-light'>Materials Information</span>
      <div className='grid grid-cols-3 gap-6 mt-2'>
        <div className='col-start-1 col-span-3'>
          <span className='block text-sm font-light text-gray-700'>
            Installation Materials
          </span>
          <div className='mt-1 w-full overflow-hidden'>
            <table className='min-w-full table-fixed divide-y divide-gray-300 border border-gray-300 rounded-md'>
              <thead className='bg-gray-50'>
                <tr className='divide-x divide-gray-200'>
                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[80%]'
                  >
                    Name
                  </th>
                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-right'
                  >
                    Quantity
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {data?.materials?.map((material: any, idx: number) => (
                  <tr key={idx} className='divide-x divide-gray-200'>
                    <td className='whitespace- px-2 py-2 text-sm text-gray-700 text-ellipsis overflow-hidden '>
                      {material.material?.name || "N/A"}
                    </td>
                    <td className='whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right'>
                      {numeral(material?.quantity || 0).format("0,0")}{" "}
                      {material.material.quantityUnit}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div className='pt-6'>
      <span className='text-xs font-light'>Extra Information</span>
      <div className='grid gap-6 mt-2'>
        <div>
          <span className='block text-sm font-light text-gray-700'>Notes</span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.notes || "N/A"}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MaterialInfoSummary;
