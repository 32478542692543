import { OfficeHeader, Shimmers, TableComponent } from "components";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useTableData, useUrlState } from "utils";
import config from "config";
import { usePrepaymentErrors } from "./hooks";
import _ from "lodash";

const PrepayemtErrorsPage: FC = () => {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [, setSearch] = useUrlState("search");

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
    }),
    [searchParams]
  );

  const { reasons, count } = usePrepaymentErrors(filter);

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
      }),
    });
  }, [navigate]);

  const records = useTableData({ rows: reasons, count: count });

  return (
    <main className='flex-1 flex flex-col overflow-hidden bg-gray-50'>
      <OfficeHeader />
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"Prepayment Errors"}
            refetch={() => {}}
            isRefetching={false}
            loading={false}
            data={records}
            hasSearch={true}
            onSearchClicked={setSearch}
            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Prepayment Sync Error Code
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Prepayment Sync Error Description
                </th>

                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Prepayment Sync Error Explanation
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Resolution
                </th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='font-medium text-gray-900 dark:text-gray-100'>
                    {item?.code || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='font-medium text-gray-900 dark:text-gray-100'>
                    {item?.description || "N/A"}
                  </div>
                </td>
                <td className=' px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='font-medium text-gray-900 dark:text-gray-100 line-clamp-2 max-w-[400px] flex flex-wrap'>
                    {item?.explanation || "N/A"}
                  </div>
                </td>
                <td className=' px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='font-medium text-gray-900 dark:text-gray-100 line-clamp-2 max-w-[400px] flex flex-wrap'>
                    {_.startCase(item?.resolution || "N/A")}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>
    </main>
  );
};

export default PrepayemtErrorsPage;
