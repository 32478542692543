import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { TextArea } from "components/core";
import { FormikProps } from "formik";
import { FC } from "react";

interface ApproveServiceOrderFormProps {
  form: FormikProps<{
    notes: string;
  }>;
}

const ApproveServiceOrderForm: FC<ApproveServiceOrderFormProps> = ({
  form,
}) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Approval Information</span>
        <div className="grid gap-4 mt-2">
          <div>
            <TextArea
              id="notes"
              label="Notes"
              placeholder="e.g. Details captured are conclusive"
              required={true}
              rows={10}
              {...form}
            />
          </div>
          {false && (
            <div className="rounded-md bg-amber-50 border border-amber-100 p-3">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-amber-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-2">
                  <h3 className="text-sm font-medium text-amber-800">
                    Attention needed
                  </h3>
                  <div className="mt-1 space-y-2 text-sm text-amber-700">
                    <p>
                      Meter assigned is not the same as meter installed on
                      prepayment system. Expected meter{" "}
                      <span className="text-sm font-medium text-amber-800">
                        10130013030
                      </span>{" "}
                      (model: MC041ML045) but got{" "}
                      <span className="text-sm font-medium text-amber-800">
                        1013035353030
                      </span>{" "}
                      (model: MC353ML353)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {false && (
            <div className="rounded-md bg-amber-50 border border-amber-100 p-3">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-amber-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-2">
                  <h3 className="text-sm font-medium text-amber-800">
                    Attention needed
                  </h3>
                  <div className="mt-1 space-y-2 text-sm text-amber-700">
                    <p>
                      Approving this service order would override assigned meter
                      with meter installed on the prepayment system. This means
                      that, meter{" "}
                      <span className="text-sm font-medium text-amber-800">
                        1013035353030
                      </span>{" "}
                      (model: MC353ML353) would be installed in place of{" "}
                      <span className="text-sm font-medium text-amber-800">
                        10130013030
                      </span>{" "}
                      (model: MC041ML045).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApproveServiceOrderForm;
