import { FC } from "react";
import numeral from "numeral";
import moment from "moment";
import { ICurrentConfig } from "apollo/cache/config";
import { wrapImage } from "utils";
import _ from "lodash";

const InvestigationReadingInfoSummary: FC<{
  data: any;
  config: ICurrentConfig;
  type?: String;
}> = ({ data, config: { dateFormat }, type }) => (
  <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
    <div className='grid grid-cols-3 gap-4'>
      <div className=''>
        <span className='block text-sm font-light text-gray-700'>
          {type || ""} Reading Date
        </span>
        <div className='mt-1 block w-full sm:text-sm'>
          {data?.readingDate
            ? moment(data?.readingDate).format(dateFormat)
            : "N/A"}
        </div>
      </div>
      <div className='row-span-3 col-span-2'>
        <span className='block text-sm font-light text-gray-700'>
          {type} Reading Images
        </span>
        <div className='mt-2 grid grid-cols-1 gap-3'>
          {wrapImage(
            <img
              src={data?.readingImageUrl}
              alt={"back"}
              className='w-full h-48 text-xs object-cover object-center rounded'
            />
          )}
        </div>
      </div>
      <div className=''>
        <span className='block text-sm font-light text-gray-700'>
          {type} Reading (KWh)
        </span>
        <div className='mt-1 block w-full sm:text-sm'>
          {_.isNumber(data?.readingValue)
            ? numeral(data?.readingValue).format("0,0")
            : "N/A"}
        </div>
      </div>
    </div>
  </div>
);

export default InvestigationReadingInfoSummary;
