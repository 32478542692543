import { DistrictCodes, ServiceClasses, ServiceTypes } from 'apollo/data';
import lodash from 'lodash';
import * as Yup from 'yup';
export type ServiceType = typeof ServiceTypes[number]["value"];
export type ServiceClass = typeof ServiceClasses[number]["value"];

export interface IResolveInvestigationServiceOrderFormSchema {
  propertyInfo: {
    region: {
      _id: string;
      code: string;
      name: string;
    } | null;
    district: {
      _id: string;
      code: string;
      name: string;
    } | null;

    ghanaPostAddress: string;
    propertyImageUrls: string[];
    closestPoleNumber: string;
    transformerPhase: string;
    currentTransformerRatio: number;
    meterHeight: number;
    premiseType: {
      _id: string;
      code: string;
      name: string;
    } | null;
    premiseCategory: {
      _id: string;
      code: string;
      name: string;
    } | null;
    activity: {
      _id: string;
      code: string;
      name: string;
    } | null;
    subActivity: {
      _id: string;
      code: string;
      name: string;
    } | null;
    transformerNumber: string;
    meterLocation: string;
  };
  serviceInfo: {
    serviceType: ServiceType;
    serviceClass: ServiceClass;
    tariffClass: {
      _id: string;
      code: string;
      name: string;
    } | null;
    contractedDemand: number;
    meterPhase: (1 | 3);
  };
  extraInfo: {
    installationType: {
      _id: string;
      code: string;
      name: string;
      materials: {
        _id: string;
        code: string;
        name: string;
        quantityUnit: string;
      }[];
    } | null;
    appliances: {
      appliance: {
        _id: string;
        code: string;
        name: string;
        wattage: number;
      };
      quantity: number;
    }[];
    materials: {
      material: {
        _id: string;
        code: string;
        name: string;
        quantityUnit: string;
      };
      quantity: number;
    }[];
    notes: string;
  }
};

export const PropertyInfoFormSchema = Yup.object().shape({
  region: Yup.object().shape({
    _id: Yup.string().required(),
    code: Yup.string().required(),
    name: Yup.string().required(),
  }).required(),
  district: Yup.object().shape({
    _id: Yup.string().required(),
    code: Yup.string().required(),
    name: Yup.string().required(),
  }).required(),
  ghanaPostAddress: Yup.string().matches(new RegExp(`/^${lodash.map(DistrictCodes, "code").join("|")}-\d{3}-\d{4}$/`)).required(),
  propertyImageUrls: Yup.array().of(Yup.string().url().required()).required(),
  closestPoleNumber: Yup.string().required(),
  transformerPhase: Yup.string().required(),
  currentTransformerRatio: Yup.number().required(),
  meterHeight: Yup.number().required(),
  meterLocation: Yup.string().required(),
  premiseType: Yup.object().shape({
    _id: Yup.string().required(),
    code: Yup.string().required(),
    name: Yup.string().required(),
  }).required(),
  premiseCategory: Yup.object().shape({
    _id: Yup.string().required(),
    code: Yup.string().required(),
    name: Yup.string().required(),
  }).required(),
  activity: Yup.object().shape({
    _id: Yup.string().required(),
    code: Yup.string().required(),
    name: Yup.string().required(),
  }).required(),
  subActivity: Yup.object().shape({
    _id: Yup.string().required(),
    code: Yup.string().required(),
    name: Yup.string().required(),
  }).required(),
  transformerNumber: Yup.string().required(),
});

export const ServiceInfoFormSchema = Yup.object().shape({
  serviceType: Yup.string().oneOf(lodash.map(ServiceTypes, "value")).required(),
  serviceClass: Yup.string().oneOf(lodash.map(ServiceClasses, "value")).required(),
  tariffClass: Yup.object().shape({
    _id: Yup.string().required(),
    code: Yup.string().required(),
    name: Yup.string().required(),
  }).required(),
  contractedDemand: Yup.number().positive(),
  meterPhase: Yup.number().oneOf([1, 3]).required(),
})

export const ExtraInfoFormSchema = Yup.object().shape({
  installationType: Yup.object().nullable().required("Installation Type is required"),
  appliances: Yup.array().of(Yup.object().shape({
    appliance: Yup.object().shape({
      _id: Yup.string().required("Appliance is required"),
      code: Yup.string().required(),
      name: Yup.string().required(),
      wattage: Yup.number().required(),
    }).required(),
    quantity: Yup.number().min(0).required()
  }).required()).required(),
  materials: Yup.array().of(Yup.object().shape({
    material: Yup.object().shape({
      _id: Yup.string().nullable().notRequired(),
      code: Yup.string().nullable().notRequired(),
      name: Yup.string().typeError("Select Material").required("Material is required"),
      quantityUnit: Yup.string().nullable().notRequired(),
    }).required("Material is required"),
    quantity: Yup.number().min(0).required()
  }).required()).required(),
  notes: Yup.string()
})

export const ResolveInvestigationServiceOrderFormSchema = Yup.object().shape({
  propertyInfo: PropertyInfoFormSchema.required(),
  serviceInfo: ServiceInfoFormSchema.required(),
  extraInfo: ExtraInfoFormSchema.required()
})