import { gql, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { Modal } from "components";
import { useSearch, useNavigate, useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import toast from "react-hot-toast";
import { Action } from "components/buttons/action-button";
import { classNames, wrapClick } from "utils";
import { useState } from "react";
import { currentUserVar } from "apollo/cache/auth";
import { GET_CUSTOMER } from "pages/customers/view";
import InitiateInvestigationServiceOrderForm from "./cards/initiate-investigation-form";
import { useFormik } from "formik";

const INITIATE_SERVICE_ORDER = gql`
  mutation InitiateInvestigationServiceOrder($id: ID!, $notes: String) {
    initiateComplaintInvestigationServiceOrder(id: $id, notes: $notes) {
      _id
    }
  }
`;

export default function InitiateInvestigationServiceOrderContainer({
  open,
  setOpen,
  refetch,
  servicePoint,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
  servicePoint?: string;
}) {
  const [initiateServiceOrder, { loading: initiating }] = useMutation(
    INITIATE_SERVICE_ORDER
  );
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();

  const currentUser = useReactiveVar(currentUserVar);
  const {
    data,
    loading,
    refetch: refetchCustomer,
  } = useQuery(GET_CUSTOMER, {
    variables: {
      getServicePointId: servicePoint,
    },
    notifyOnNetworkStatusChange: false,
  });

  const form = useFormik<any>({
    initialValues: {
      notes: "",
    },
    onSubmit: async (values) => {
      initiateServiceOrder({
        variables: { id: params?.complaint, notes: values.notes },
      }).then(({ data }) => {
        if (data.initiateComplaintInvestigationServiceOrder._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Service Order Initiated  Successfully",
            })
          );
          refetch?.();
          setOpen(false);
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not initiate Service Order",
            })
          );
        }
      });
    },
    onReset: () => {
      form.resetForm();
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      loading={loading}
      setOpen={setOpen}
      title='Initiate Investigation Service Order'
      description='Confirm customer details to initiate investigation service order'
      renderActions={() => (
        <>
          <button
            type='button'
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {!initiating
              ? " Initiate Service Order"
              : " Initiating Service Order  ..."}
          </button>
        </>
      )}
    >
      <InitiateInvestigationServiceOrderForm
        form={form}
        customer={data?.customer}
      />
    </Modal>
  );
}
