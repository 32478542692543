import { FC, Fragment, useMemo } from 'react'
import moment from 'moment';
import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { Avatar } from 'components/core';
import { Link } from 'react-location';
import { classNames } from 'utils';
import _ from "lodash";
import { CheckIcon, ClipboardDocumentListIcon, HandThumbUpIcon, PlayIcon, UserIcon, UserPlusIcon, XMarkIcon } from '@heroicons/react/20/solid'


interface ServiceOrderHistoryViewProps {
  serviceOrder: {
    code: string;
    history: {
      user: ("Approver" | "Assignee" | "Assigner");
      timestamp: string;
      action: ("Assign" | "Reassign" | "Approve" | "Reject" | "Start" | "Resolve");
      notes: string;
    }[];
    assigner: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    assignee: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    approver: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    createdAt: string;
    updatedAt: string;
  };
}

const ServiceOrderHistoryView: FC<ServiceOrderHistoryViewProps> = ({ serviceOrder }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  const assigner = useMemo(() => serviceOrder?.assigner, [serviceOrder]);
  const assignee = useMemo(() => serviceOrder?.assignee, [serviceOrder]);
  const approver = useMemo(() => serviceOrder?.approver, [serviceOrder]);

  const actionIconMap = {
    "Assign": (
      <span
        className={classNames(
          "bg-green-500",
          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
        )}
      >
        <UserPlusIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    "Reassign": (
      <span
        className={classNames(
          "bg-green-500",
          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
        )}
      >
        <UserPlusIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    "Approve": (
      <span
        className={classNames(
          "bg-green-500",
          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
        )}
      >
        <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    "Reject": (
      <span
        className={classNames(
          "bg-red-500",
          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
        )}
      >
        <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    "Start": (
      <span
        className={classNames(
          "bg-green-500",
          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
        )}
      >
        <PlayIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    "Resolve": (
      <span
        className={classNames(
          "bg-green-500",
          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
        )}
      >
        <ClipboardDocumentListIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
  }

  const renderActionDetails = (action: ServiceOrderHistoryViewProps["serviceOrder"]["history"][number]) => {
    switch (action.action) {
      case "Assign": {
        return (
          <p className="text-sm text-gray-500">
            <span className="font-medium text-gray-900">
              {assigner.lastName} {assigner.firstName}
            </span>
            {' '}assigned service request to{' '}
            <span className="font-medium text-gray-900">
              {assignee.lastName} {assignee.firstName}
            </span>
          </p>
        )
      }
      case "Reassign": {
        return (
          <p className="text-sm text-gray-500">
            <span className="font-medium text-gray-900">
              {assigner.lastName} {assigner.firstName}
            </span>
            {' '}assigned service request to{' '}
            <span className="font-medium text-gray-900">
              {assignee.lastName} {assignee.firstName}
            </span>
          </p>
        )
      }
      case "Approve": {
        return (
          <p className="text-sm text-gray-500">
            <span className="font-medium text-gray-900">
              {approver.lastName} {approver.firstName}
            </span>
            {' '}approved service order
          </p>
        )
      }
      case "Reject": {
        return (
          <p className="text-sm text-gray-500">
            <span className="font-medium text-gray-900">
              {approver.lastName} {approver.firstName}
            </span>
            {' '}rejected service order
          </p>
        )
      }
      case "Start": {
        return (
          <p className="text-sm text-gray-500">
            <span className="font-medium text-gray-900">
              {action.user === "Assignee"
                ? [assignee.lastName, assignee.firstName].join(" ")
                : (action.user === "Assigner"
                  ? [assigner.lastName, assigner.firstName].join(" ")
                  : [assigner.lastName, assigner.firstName].join(" ")
                )}
            </span>
            {' '}started service request
          </p>
        )
      }
      case "Resolve": {
        return (
          <p className="text-sm text-gray-500">
            <span className="font-medium text-gray-900">
              {action.user === "Assignee"
                ? [assignee.lastName, assignee.firstName].join(" ")
                : (action.user === "Assigner"
                  ? [assigner.lastName, assigner.firstName].join(" ")
                  : [assigner.lastName, assigner.firstName].join(" ")
                )}
            </span>
            {' '}resolved service request
          </p>
        )
      }

      default:
        return null;
    }
  }

  return (
    <div className="flow-root">
      <ul className="-mb-8">
        {serviceOrder?.history?.map((historyItem, historyItemIdx) => (
          <li key={historyItemIdx}>
            <div className="relative pb-8">
              {historyItemIdx !== serviceOrder.history.length - 1 ? (
                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  {actionIconMap[historyItem.action]}
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-12 pt-1.5">
                  <div className='flex-1'>
                    {renderActionDetails(historyItem)}
                    {historyItem?.notes && (
                      <div className='relative p-4 border border-gray-300 rounded mt-6'>
                        <span className='absolute bg-white px-2 -top-2 text-xs'>Notes</span>
                        <p className='text-sm text-gray-600 font-light'>{historyItem.notes}</p>
                      </div>
                    )}
                  </div>
                  <div className="whitespace-nowrap text-right text-sm flex flex-col text-gray-500">
                    <time dateTime={historyItem.timestamp}>{moment(historyItem.timestamp).format("DD/MM/YYYY")}</time>
                    <time dateTime={historyItem.timestamp}>{moment(historyItem.timestamp).format("hh:mm A")}</time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ServiceOrderHistoryView