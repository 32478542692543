import { wrapClick } from 'utils';
import { BlockPicker, DistrictPicker, ItineraryPicker, PlotPicker, PropertyPicker, RegionPicker, RoundPicker } from 'containers';
import { useFormik } from 'formik';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { Modal } from 'components';

export default function CustomersFilterContainer({ open, setOpen }: { open: boolean; setOpen: any }) {

  const navigate = useNavigate<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();

  const form = useFormik({
    initialValues: {
      region: searchParams.region || "",
      district: searchParams.district || "",
      block: searchParams.block || "",
      round: searchParams.round || "",
      plot: searchParams.plot || "",
      itinerary: searchParams.itinerary || "",
      property: searchParams.property || "",
    },
    onSubmit: (values) => {
      navigate({
        search: (old) => ({
          ...old,
          page: 1,
          region: values.region || undefined,
          district: values.district || undefined,
          block: values.block || undefined,
          round: values.round || undefined,
          plot: values.plot || undefined,
          itinerary: values.itinerary || undefined,
          property: values.property || undefined,
        })
      })
      setOpen(false);
    },
    onReset: () => {
      navigate({
        search: (old) => ({
          ...old,
          page: 1,
          region: undefined,
          district: undefined,
          block: undefined,
          round: undefined,
          plot: undefined,
          itinerary: undefined,
          property: undefined,
        })
      })
      setOpen(false);
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Filter Customers"
      description="Provide query to filter customers"
      renderActions={() => (
        <>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            Apply filter
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.resetForm)}
          >
            Reset Filters
          </button>
        </>
      )}
    >

      <div className='grid grid-cols-2 gap-6'>
        <div>
          <RegionPicker
            id="region"
            label="Region"
            placeholder='Region Name'
            required={true}
            {...form}
            setFieldValue={(field: string, value: string) => {
              form.setFieldValue(field, value)
              form.setFieldValue("district", null)
              form.setFieldValue("block", null)
              form.setFieldValue("round", null)
              form.setFieldValue("plot", null)
              form.setFieldValue("itinerary", null)
              form.setFieldValue("property", null)
            }}
            rawId={true}
          />
        </div>

        {form.values.region && (
          <div>
            <DistrictPicker
              id="district"
              label="District"
              placeholder='District Name'
              required={true}
              filter={{ region: form.values.region }}
              {...form}
              setFieldValue={(field: string, value: string) => {
                form.setFieldValue(field, value)
                form.setFieldValue("block", null)
                form.setFieldValue("round", null)
                form.setFieldValue("plot", null)
                form.setFieldValue("itinerary", null)
                form.setFieldValue("property", null)
              }}
              rawId={true}
            />
          </div>
        )}

        {form.values.district && (
          <div>
            <BlockPicker
              id="block"
              label="Block"
              placeholder='Block Name'
              required={true}
              filter={{
                region: form.values.region,
                district: form.values.district,
              }}
              {...form}
              setFieldValue={(field: string, value: string) => {
                form.setFieldValue(field, value)
                form.setFieldValue("round", null)
                form.setFieldValue("plot", null)
                form.setFieldValue("itinerary", null)
                form.setFieldValue("property", null)
              }}
              rawId={true}
            />
          </div>
        )}

        {form.values.block && (
          <div>
            <RoundPicker
              id="round"
              label="Round"
              placeholder='Round Name'
              required={true}
              filter={{
                region: form.values.region,
                district: form.values.district,
                block: form.values.block,
              }}
              {...form}
              setFieldValue={(field: string, value: string) => {
                form.setFieldValue(field, value)
                form.setFieldValue("plot", null)
                form.setFieldValue("itinerary", null)
                form.setFieldValue("property", null)
              }}
              rawId={true}
            />
          </div>
        )}

        {form.values.round && (
          <div>
            <PlotPicker
              id="plot"
              label="Plot"
              placeholder='Plot Name'
              required={true}
              filter={{
                region: form.values.region,
                district: form.values.district,
                block: form.values.block,
                round: form.values.round,
              }}
              {...form}
              setFieldValue={(field: string, value: string) => {
                form.setFieldValue(field, value)
                form.setFieldValue("itinerary", null)
                form.setFieldValue("property", null)
              }}
              rawId={true}
            />
          </div>
        )}

        {form.values.plot && (
          <div>
            <ItineraryPicker
              id="itinerary"
              label="Itinerary"
              placeholder='Itinerary Name'
              required={true}
              filter={{
                region: form.values.region,
                district: form.values.district,
                block: form.values.block,
                round: form.values.round,
                plot: form.values.plot,
              }}
              {...form}
              setFieldValue={(field: string, value: string) => {
                form.setFieldValue(field, value)
                form.setFieldValue("property", null)
              }}
              rawId={true}
            />
          </div>
        )}

        {form.values.itinerary && (
          <div>
            <PropertyPicker
              id="property"
              label="Property"
              placeholder='Property Code'
              required={true}
              filter={{
                region: form.values.region,
                district: form.values.district,
                block: form.values.block,
                round: form.values.round,
                plot: form.values.plot,
                itinerary: form.values.itinerary,
              }}
              {...form}
              setFieldValue={(field: string, value: string) => {
                form.setFieldValue(field, value)
              }}
              rawId={true}
            />
          </div>
        )}

      </div>
    </Modal>
  )
}
