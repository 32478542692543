import { wrapClick } from "utils";
import {
  ApproverPicker,
  DistrictPicker,
  EstimatorPicker,
  RegionPicker,
} from "containers";
import { useFormik } from "formik";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { Modal, SearchSelectInput } from "components";

export default function InvestigationServiceOrdersFilterContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: any;
}) {
  const navigate = useNavigate<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();

  const form = useFormik({
    initialValues: {
      region: searchParams.region || "",
      district: searchParams.district || "",
      assignee: searchParams.assignee || "",
      assigner: searchParams.assigner || "",
      approver: searchParams.approver || "",
      priority: searchParams.priority || "",
      category: searchParams.category || "",
    },
    onSubmit: (values) => {
      navigate({
        search: (old) => ({
          ...old,
          page: 1,
          region: values.region || undefined,
          district: values.district || undefined,
          assignee: values.assignee || undefined,
          assigner: values.assigner || undefined,
          approver: values.approver || undefined,
          priority: values.priority || undefined,
          category: values.category || undefined,
        }),
      });
      setOpen(false);
    },
    onReset: () => {
      navigate({
        search: (old) => ({
          ...old,
          page: 1,
          region: undefined,
          district: undefined,
          assignee: undefined,
          assigner: undefined,
          approver: undefined,
          priority: undefined,
          category: undefined,
        }),
      });
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Filter Investigation Service orders"
      description="Provide query to filter investigation service orders"
      renderActions={() => (
        <>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            Apply filter
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.resetForm)}
          >
            Reset Filters
          </button>
        </>
      )}
    >
      <div className="grid grid-cols-2 gap-6">
        <div>
          <RegionPicker
            id="region"
            label="Region"
            placeholder="Region Name"
            required={true}
            {...form}
            setFieldValue={(field: string, value: string) => {
              form.setFieldValue(field, value);
              form.setFieldValue("district", null);
              form.setFieldValue("block", null);
              form.setFieldValue("round", null);
              form.setFieldValue("plot", null);
            }}
            rawId={true}
          />
        </div>
        {form.values.region && (
          <div>
            <DistrictPicker
              id="district"
              label="District"
              placeholder="District Name"
              required={true}
              filter={{ region: form.values.region }}
              {...form}
              setFieldValue={(field: string, value: string) => {
                form.setFieldValue(field, value);
                form.setFieldValue("block", null);
                form.setFieldValue("round", null);
                form.setFieldValue("plot", null);
              }}
              rawId={true}
            />
          </div>
        )}
        <div>
          <SearchSelectInput
            id="category"
            options={[
              { label: { title: "Express" }, value: "Express" },
              { label: { title: "Premium" }, value: "Premium" },
              { label: { title: "Standard" }, value: "Standard" },
            ]}
            label={"Category"}
            placeholder="Select Category"
            {...form}
          />
        </div>
        <div>
          <SearchSelectInput
            id="priority"
            // labelHidden={true}
            options={[
              { label: { title: "Low" }, value: "Low" },
              { label: { title: "Medium" }, value: "Medium" },
              { label: { title: "High" }, value: "High" },
              { label: { title: "Critical" }, value: "Critical" },
            ]}
            label={"Priority"}
            placeholder="Select Priority"
            {...form}
          />
        </div>
        <div>
          <EstimatorPicker
            // labelHidden
            rawId
            id="assignee"
            label="Estimator"
            {...form}
            setFieldValue={(field: any, value: string) =>
              form.setFieldValue(field, value)
            }
          />
        </div>
        <div>
          <ApproverPicker
            rawId
            id="approver"
            {...form}
            setFieldValue={(field: any, value: string) =>
              form.setFieldValue(field, value)
            }
          />
        </div>
      </div>
    </Modal>
  );
}
