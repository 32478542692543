import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import ReadingInfoSummary from "./reading";
import RecoveryDataInfoSummary from "./recovery-data";
import { FC } from "react";
import MaterialInfoSummary from "./materials";

interface MeterRemovalServiceOrderResolutionViewProps {
  resolution: {
    recoveryData: any;
    recoveredMaterials: any;
    reading: any;
    notes: string;
  };
  result: string;
}

const MeterRemovalServiceOrderResolutionView: FC<
  MeterRemovalServiceOrderResolutionViewProps
> = ({ resolution, result }) => {
  const currentConfig = useReactiveVar(currentConfigVar);

  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div className=''>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
              Recovery Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>
              Details captured during meter removal service order
            </p>
          </div>
          <div />
        </div>
        <div className='mt-6'>
          <RecoveryDataInfoSummary data={resolution?.recoveryData} />
        </div>
      </div>
      <div className='pt-6 '>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
              Materials Infomation
            </h3>
            <p className='mt-1 text-xs text-gray-500'>
              Details about materials used for the installation
            </p>
          </div>
          <div />
        </div>
        <div className='mt-4'>
          <MaterialInfoSummary
            data={{
              recoveredMaterials: resolution?.recoveredMaterials,
            }}
          />
        </div>
      </div>
      <div className='pt-6'>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
              Reading Infomation
            </h3>
            <p className='mt-1 text-xs text-gray-500'>Details about property</p>
          </div>
          <div />
        </div>
        <div className='mt-6'>
          <ReadingInfoSummary
            data={resolution?.reading}
            config={currentConfig}
          />
        </div>
      </div>
      <div className='pt-6'>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-4 font-normal text-gray-900 dark:text-gray-100'>
              Results Infomation
            </h3>
            <p className='mt-1 text-xs text-gray-500'>
              Details about the outcome of the installation
            </p>
          </div>
          <div />
        </div>
        <div className='mt-4'>
          <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
            <div className='grid grid-cols-3 gap-4'>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Result
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {result || "N/A"}
                </div>
              </div>
              <div className='col-span-3'>
                <span className='block text-sm font-light text-gray-700'>
                  Notes
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {resolution?.notes || "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeterRemovalServiceOrderResolutionView;
