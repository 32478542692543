import { wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, DisapproveReplacementServiceOrderForm } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_REPLACEMENT_SERVICE_ORDER } from "./view";


const DISAPPROVE_SERVICE_ORDER = gql`
  mutation DisapproveReplacementServiceOrder(
    $id: ID!
    $reason: String!
  ) {
    disapproveReplacementServiceOrder(
      id: $id
      reason: $reason
    ) {
      _id
    }
  }
`

export default function DisapproveReplacementServiceOrderContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {

  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_REPLACEMENT_SERVICE_ORDER, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });
  const [disapproveReplacementServiceOrder] = useMutation(DISAPPROVE_SERVICE_ORDER)

  const form = useFormik({
    initialValues: {
      reason: "",
    },
    onSubmit: async (values) => {
      await disapproveReplacementServiceOrder({
        variables: {
          id: searchParams.id,
          ...values
        }
      }).then(({ data }) => {
        if (data.disapproveReplacementServiceOrder._id) {
          toast(JSON.stringify({ type: "success", title: "Service Order Disapproved Successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not reject Service Order" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Disapprove Service Order"
      size="4xl"
      description="Provide reasons for rejecting service order"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={form.isSubmitting}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {form.isSubmitting ? "Disapproving Service Order..." : "Disapprove Service Order"}
          </button>
        </>
      )}
    >
      {data?.replacementServiceOrder?._id && (
        <DisapproveReplacementServiceOrderForm
          form={form}
        />
      )}
    </Modal>
  )
}