import { wrapClick } from "utils";
import { gql, useQuery } from "@apollo/client";
import { Modal } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { ContractorView } from "components";

const GET_CONTRACTOR = gql`
  query GetContractor($id: ID!) {
    contractor: getContractor(id: $id) {
      _id
      code
      name
      type
      address
      ghanaPostAddress
      contactPerson {
        emailAddress
        phoneNumber
        fullName
      }
      district {
        _id
        code
        name
      }
      createdAt
      updatedAt
    }
  }
`;

export default function ViewContractorContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_CONTRACTOR, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false,
  });
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update",
      }),
    });
  };
  const navigateItem = () => {
    navigate({
      to: `./${searchParams.id}`,
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Farming Out Contractor Information"
      description="Details of farming out contractor are shown below"
      renderActions={() => (
        <>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(navigateItem)}
          >
            View Agents
          </button>
        </>
      )}
    >
      <ContractorView contractor={data?.contractor} />
    </Modal>
  );
}
