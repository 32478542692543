import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Header, StepWizardStatus } from "components";
import _ from "lodash";
import moment from "moment";
import { FC, useEffect, useState, useMemo } from "react"
import { useNavigate, useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import { currentConfigVar } from "apollo/cache/config";
import ServiceRequestDetailsContainer from "./service-request-details";
import ContractSigningDetailsContainer from "./contract-signing-details";
import InspectionOrderDetailsContainer from "./inspection-order-details";
import ConnectionChargeDetailsContainer from "./connection-charge-details";
import InstallationOrderDetailsContainer from "./installation-order-details";
import ApplicationChargeDetailsContainer from "./application-charge-details";
import CustomerDetailsContainer from "./customer-details";


const GET_SERVICE_REQUEST = gql`
  query GetServiceRequest($id: ID!) {
    serviceRequest: getServiceRequest(id: $id) {
      _id
      code
      category
      inspection {
        _id
      }
      installation {
        _id
      }
      type
      status
      origin
      createdAt
      updatedAt
      connectionFee {
        _id
      }
      applicationFee {
        _id
      }
    }
  }
`

const serviceRequestSteps = [
  {
    name: "Service Reqeust",
    description: "",
    Component: ServiceRequestDetailsContainer,
  },
  {
    name: "Application Charge",
    description: "",
    Component: ApplicationChargeDetailsContainer,
  },
  {
    name: "Inspection Order",
    description: "",
    Component: InspectionOrderDetailsContainer,
  },
  {
    name: "Contract Signing",
    description: "",
    Component: ContractSigningDetailsContainer,
  },
  {
    name: "Connection Charge",
    description: "",
    Component: ConnectionChargeDetailsContainer,
  },
  {
    name: "Installation Order",
    description: "",
    Component: InstallationOrderDetailsContainer,
  },
  {
    name: "Customer Details",
    description: "",
    Component: CustomerDetailsContainer,
  },
]

const ServiceRequestPage: FC = () => {
  const { pollInterval, dateFormat } = useReactiveVar(currentConfigVar);


  const [step, setStep] = useState(0);
  const [lastStep, setLastStep] = useState(0);

  const { params } = useMatch<LocationGenerics>();

  const { data, refetch } = useQuery(GET_SERVICE_REQUEST, {
    variables: {
      id: params.serviceRequest
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only"
  });

  const filteredFormSteps = useMemo(() => serviceRequestSteps.filter((_step) => true), [])

  const steps = useMemo(() => [
    ...filteredFormSteps,
  ].map((rawStep, href) => ({ ...rawStep, href }))
    , [filteredFormSteps])

  useEffect(() => {
    switch (data?.serviceRequest?.status) {
      case "ApplicationFeeGenerated":
      case "ApplicationFeePaid": {
        setLastStep(1);
        break;
      }
      case "InspectionInitiated":
      case "InspectionFailed":
      case "InspectionCompleted": {
        setLastStep(2);
        break;
      }
      case "ContractGenerated":
      case "ContractSigned": {
        setLastStep(3);
        break;
      }
      case "ConnectionFeeGenerated":
      case "ConnectionFeePaid": {
        setLastStep(4);
        break;
      }
      case "InstallationInitiated":
      case "InstallationCompleted":
      case "InstallationFailed": {
        setLastStep(5);
        break;
      }

      case "Completed": {
        setLastStep(6);
        break;
      }

      default:
        setLastStep(0);
        break;
    }
  }, [data?.serviceRequest?.status])



  const renderView = () => {
    const { Component } = steps[step || 0];
    return <Component
      values={data?.serviceRequest}
      id={_.get(data?.serviceRequest, "_id")}
      code={_.get(data?.serviceRequest, "code")}
      status={_.get(data?.serviceRequest, "status")}
      createdAt={_.get(data?.serviceRequest, "createdAt")}
      updatedAt={_.get(data?.serviceRequest, "updatedAt")}
      refetch={refetch}
    />
  }

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <Header />
      <div className="flex flex-1 overflow-y-auto">
        <div className="bg-gray-100 shadow-xl flex-1 flex p-8 overflow-hidden relative">
          <div className="w-1/3 justify-center flex flex-col space-y-6">
            <div className="p-6 border border-dashed border-gray-300 rounded grid grid-cols-2 gap-6 mr-12">
              <div>
                <span className="block text-md font-light text-gray-700">
                  Service Request Code
                </span>
                <div className="mt-1 block w-full text-md">
                  {data?.serviceRequest?.code || "N/A"}
                </div>
              </div>
              <div className="">
                <span className="block text-md font-light text-gray-700">
                  Service Request Date
                </span>
                <div className="mt-1 block w-full text-md">
                  {data?.serviceRequest?.createdAt ? moment(data?.serviceRequest?.createdAt).format(dateFormat) : "N/A"}
                </div>
              </div>

            </div>
            <StepWizardStatus
              steps={steps}
              step={step}
              setStep={setStep}
              lastStep={lastStep}
              status={data?.serviceRequest?.status}
            />
          </div>
          <div className="flex flex-1 overflow-hidden bg-white rounded-lg">
            {renderView()}
          </div>
        </div>
      </div>
    </main>
  )
}

export default ServiceRequestPage;
