import { gql } from "@apollo/client";

export const GET_SERVICE_ORDERS = gql`
  query GetReplacementServiceOrders(
  $page: Int
  $pageSize: Int
  $sort: String
  $search: String
  $searchFields: [String!]
  $region: ID
  $district: ID
  $status: InstallationServiceOrderStatus
  $priority: InstallationServiceOrderPriority
  $fromDate: Date
  $toDate: Date
) {
  rows: getRegularizationInstallationServiceOrders(
    page: $page
    pageSize: $pageSize
    sort: $sort
    search: $search
    searchFields: $searchFields
    region: $region
    district: $district
    status: $status
    priority: $priority
    fromDate: $fromDate
    toDate: $toDate
  ) {
    _id
    code
    region {
      _id
      code
      name
    }
    district {
      _id
      code
      name
    }
    contactPerson {
      fullName
      phoneNumber
    }
    location {
      address
      community
    }
    meterContractor {
      _id
      code
      name
    }
    farmingOutContractor {
      _id
      code
      name
    }
    assigneeType
    assigner {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      profileImageUrl
    }
    approver {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      profileImageUrl
    }
    disapprover {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
    assignee {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      profileImageUrl
    }
    status
    priority
    estimatedResolutionDate
    servicePoint {
      _id
      code
      geoCode
      tariffClass {
        _id
        code
        name
      }
    }
    servicePointCode
    meter {
      _id
      code
      modelMeta {
        brandCode
        brandName
        systemCode
        systemName
        modelCode
        modelName
        phase
      }
    }
    installationMeter {
      _id
      code
      modelMeta {
        brandCode
        brandName
        systemCode
        systemName
        modelCode
        modelName
      }
    }
    installationMeterSystemCode
    installationMeterSystemSyncStatus
    assignedAt
    startedAt
    resolvedAt
    completedAt
    disapprovedAt
    createdAt
    updatedAt
  }
  count: getRegularizationInstallationServiceOrdersCount(
    search: $search
    searchFields: $searchFields
    region: $region
    district: $district
    status: $status
    priority: $priority
    fromDate: $fromDate
    toDate: $toDate
  )
}

`;

export const GET_SERVICE_ORDERS_SUMMARY = gql`
  query GetReplacementServiceOrderSummary(
  $district: ID 
   $fromDate: Date
  $toDate: Date
  ) {
    getRegularizationInstallationServiceOrderSummary(
    district: $district
    fromDate: $fromDate
    toDate: $toDate
    ) {
      Pending
      AssignedToSupplier
      AssignedToInstaller
      Resolved
      InProgress
      Completed
      Disapproved
    }
  }
`;