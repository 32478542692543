import { gql, useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { FC, SetStateAction, useState } from "react";
import { SearchSelectInput, TextInput } from "components/core";
import { currentUserVar } from "apollo/cache/auth";
import { currentConfigVar } from "apollo/cache/config";
import { wrapClick } from "utils";

interface MeterPickerContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  rawId?: boolean;
  parentValues?: Record<string, any>;
}

const GET_INSTALLABLE_METER = gql`
  query GetInstallableMeter($code: String!) {
    installableMeter: getInstallableMeter(code: $code) {
      _id
      code
      modelType
      brand {
        _id
        code
        name
        createdAt
        updatedAt
      }
      model {
        _id
        code
        name
        brand {
          code
          name
        }
        phase
        type
        # compliance
        digits
        voltage
        currentRating
        mechanism
        communicationType
        createdAt
        updatedAt
      }
    }
  }
`;

const MeterPickerContainer: FC<MeterPickerContainerProps> = ({
  id,
  label,
  placeholder,
  parentValues,
  rawId,
  ...form
}) => {
  const [meterNumber, setMeterNumber] = useState("P201092843");
  const [fetchInstallbleMeter, { loading }] = useLazyQuery(
    GET_INSTALLABLE_METER,
    {
      notifyOnNetworkStatusChange: false,
      // pollInterval,
      onCompleted: (data) => {
        if (data?.installableMeter) {
          form.setFieldValue(id, data?.installableMeter);
        }
      },
    }
  );

  const getMeter = () =>
    fetchInstallbleMeter({
      variables: {
        // compliance:
        //   parentValues?.assigneeInfo?.isCompliant === "Yes" ? "MMS" : "NonMMS",
        code: meterNumber,
      },
    });

  return (
    <div className="flex flex-flow flex-1 w-full space-x-4">
      <div className="flex-1">
        <TextInput
          id={`${id}.code` ?? "meter"}
          label={label ?? "Meter"}
          placeholder={placeholder ?? "Meter"}
          values={meterNumber}
          handleChange={(e: { target: { value: SetStateAction<string> } }) =>
            setMeterNumber(e?.target?.value)
          }
          handleBlur={undefined}
        />
      </div>
      <div className="col-span-6 sm:col-span-1 items-end justify-end flex">
        <button
          type="button"
          onClick={wrapClick(getMeter)}
          className="w-full inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
        >
          {loading ? "Searching..." : "Get meter"}
        </button>
      </div>
    </div>
  );
};

export default MeterPickerContainer;
