import moment from 'moment';
import * as Yup from 'yup';


export const CustomerInfoFormSchema = Yup.object().shape({
  existingServicePoint: Yup.object().required(),
})

export const AssigneeInfoFormSchema = Yup.object().shape({
  assigneeType: Yup.string().required(),
  isCompliant: Yup.string().notRequired(),
  assignee: Yup.object().required()
})

export const MeterInfoFormSchema = Yup.object().shape({
  meter: Yup.object().required()
})

export const ExtraInfoFormSchema = Yup.object().shape({
  estimatedResolutionDate: Yup.date().min(moment().startOf("day").toDate()).required(),
  priority: Yup.string().oneOf(["Low", "Medium", "High", "Critical"]).required(),
  notes: Yup.string().notRequired(),
  debtAmount: Yup.number().notRequired(),
  creditAmount: Yup.number().notRequired(),
  customerPhone: Yup.string()
    .matches(
      /^0(2|5)(0|2|3|4|5|6|7|9)\d{7}$/,
      "Kindly enter a valid phone number"
    )
    .required("Phone number is required"),
  ghanaPostAddress: Yup.string().required("Ghana Post Address is required"),
})

export const AssignServiceOrderFormSchema = Yup.object().shape({
  customerInfo: CustomerInfoFormSchema,
  assigneeInfo: AssigneeInfoFormSchema,
  meterInfo: MeterInfoFormSchema,
  extraInfo: ExtraInfoFormSchema
})