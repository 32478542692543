import { gql, useMutation, useQuery } from '@apollo/client';
import { Modal, ResolveInspectionServiceOrderForm } from 'components';
import { FC } from 'react'
import { useFormik } from "formik"
import toast from "react-hot-toast"
import { IResolveInspectionServiceOrderFormSchema } from 'components/forms/resolve-inspection-service-order/schema';
import _ from 'lodash';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { GET_INSPECTION_SERVICE_ORDER } from './view';

interface IResolveInspectionServiceOrderProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}

const RESOLVE_INSPECTION_SERVICE_ORDER = gql`
  mutation Mutation($id: ID!, $resolution: InspectionServiceOrderResolutionInput!, $result: InspectionServiceOrderResult!) {
    resolveInspectionServiceOrder(id: $id, resolution: $resolution, result: $result) {
      _id
    }
  }
`

const ResolveInspectionServiceOrderContainer: FC<IResolveInspectionServiceOrderProps> = ({ open, setOpen, refetch }) => {

  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_INSPECTION_SERVICE_ORDER, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });

  const [resolveInspectionServiceOrder] = useMutation(RESOLVE_INSPECTION_SERVICE_ORDER)

  const form = useFormik<IResolveInspectionServiceOrderFormSchema>({
    initialValues: {
      propertyInfo: {
        region: null,
        district: null,
        ghanaPostAddress: "",
        propertyImageUrls: [],
        closestPoleNumber: "",
        premiseType: null,
        premiseCategory: null,
        activity: null,
        subActivity: null,
        transformerNumber: "",
        meterLocation: "",
        transformerPhase: "",
        currentTransformerRatio: 0,
        meterHeight: 0,
      },
      serviceInfo: {
        serviceType: "Postpaid",
        serviceClass: "Residential",
        tariffClass: null,
        contractedDemand: 0.5,
        meterPhase: 1,
      },
      extraInfo: {
        installationType: null,
        appliances: [] as {
          appliance: {
            _id: string;
            code: string;
            name: string;
            wattage: number;
          };
          quantity: number;
        }[],
        materials: [] as {
          material: {
            _id: string;
            code: string;
            name: string;
            quantityUnit: string;
          };
          quantity: number;
        }[],
        notes: "",
      }
    },
    onSubmit: async (values) => {
      await resolveInspectionServiceOrder({
        variables: {
          id: data?.inspectionServiceOrder?._id,
          resolution: {
            property: {
              ..._.omit(values.propertyInfo, ["region", "district"]),
              premiseType: values.propertyInfo?.premiseType?._id,
              premiseCategory: values.propertyInfo?.premiseCategory?._id,
              activity: values.propertyInfo?.activity?._id,
              subActivity: values.propertyInfo?.subActivity?._id,
            },
            service: {
              ...values.serviceInfo,
              tariffClass: values.serviceInfo?.tariffClass?._id,
              meterPhase: values.serviceInfo?.meterPhase,
            },
            appliances: values.extraInfo?.appliances?.map((appliance) => ({
              appliance: appliance.appliance._id,
              quantity: appliance.quantity
            })),
            materials: values.extraInfo.materials?.map((material) => ({
              material: material.material._id,
              quantity: material.quantity
            })),
            installationType: values.extraInfo.installationType?._id,
            notes: values.extraInfo.notes,
          },
          result: "Passed"
        }
      }).then(({ data }) => {
        if (data.resolveInspectionServiceOrder._id) {
          toast(JSON.stringify({ type: "success", title: "Service Order Resolved Successfully" }));
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not resolve Service Order" }))
        }
      })
    },
    onReset: () => {
      refetch?.()
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      hideActions={true}
      title="Resolve Service Order"
      loading={loading}
      description="Provide reasons for resolving service order"
    >
      {data?.inspectionServiceOrder?._id && (
        <ResolveInspectionServiceOrderForm
          form={form}
        />
      )}
    </Modal>
  )
}

export default ResolveInspectionServiceOrderContainer;
