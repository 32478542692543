import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { InitiateReplacementServiceOrderForm, Modal } from "components";
import { useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import lodash from "lodash";
import { useEffect } from "react";
import _ from "lodash";

const ASSIGN_SERVICE_ORDER = gql`
  mutation InitiateReplacementServiceOrder(
    $servicePoint: ID!
    $assigneeType: ReplacementServiceOrderAssigneeType!
    $assignee: ID!
    $installationMeter: ID
    $priority: ReplacementServiceOrderPriority!
    $estimatedResolutionDate: Date!
    $notes: String
    $debtAmount: NonNegativeFloat
    $creditAmount: NonNegativeFloat
    $oldMeterNumber: String
    $oldMeterPhase: String
    $customerPhone: String
    $ghanaPostAddress: String
    $replacementReason: String
    $complaint: ID
  ) {
    initiateReplacementServiceOrder(
      servicePoint: $servicePoint
      assigneeType: $assigneeType
      assignee: $assignee
      installationMeter: $installationMeter
      priority: $priority
      estimatedResolutionDate: $estimatedResolutionDate
      notes: $notes
      creditAmount: $creditAmount
      debtAmount: $debtAmount
      oldMeterNumber: $oldMeterNumber
      oldMeterPhase: $oldMeterPhase
      phoneNumber: $customerPhone
      ghanaPostAddress: $ghanaPostAddress
      replacementReason: $replacementReason
      complaint: $complaint
    ) {
      _id
    }
  }
`;

export default function InitiateReplacementServiceOrderContainer({
  open,
  setOpen,
  refetch,
  values,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
  values?: any;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();

  const [initiateReplacementServiceOrder] = useMutation(ASSIGN_SERVICE_ORDER);
  const form = useFormik({
    initialValues: {
      customerInfo: {
        existingServicePoint: null as any,
        caimsServicePoint: null as any,
        search: "",
      },
      assigneeInfo: {
        isCompliant: "",
        assigneeType: "FieldAgent" as
          | "FieldAgent"
          | "FarmingOutContractor"
          | "MeterManufacturingContractor",
        assignee: null as any,
      },
      meterInfo: {
        meter: null as any,
      },
      extraInfo: {
        type: "",
        replacementReason: "",
        estimatedResolutionDate: "",
        priority: "Low",
        notes: "",
        debtAmount: 0,
        creditAmount: 0,
        oldMeterNumber: "",
        oldMeterPhase: "",
        oldMeterNumberTemp: "",
        oldMeterPhaseTemp: "",
        customerPhone: "",
        ghanaPostAddress: "",
      },
    },
    onSubmit: async (values) => {
      await initiateReplacementServiceOrder({
        variables: {
          id: searchParams.id,

          servicePoint: values.customerInfo.existingServicePoint._id,
          assigneeType: values.assigneeInfo?.assigneeType,
          assignee: values.assigneeInfo?.assignee?._id,
          ...(values.meterInfo?.meter
            ? { installationMeter: values.meterInfo?.meter?._id }
            : {}),
          ...lodash.omit(values.extraInfo, [
            "oldMeterNumberTemp",
            "oldMeterPhaseTemp",
          ]),
          complaint: params?.complaint,
        },
      }).then(({ data }) => {
        if (data.initiateReplacementServiceOrder._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Service Order Initiated Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not initiate Service Order",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setFieldValue("customerInfo.search", values?.servicePointCode);
  }, [values, open]);
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      hideActions={true}
      title='Initiate Replacement Service Order'
      size='5xl'
      description='Provide the details to initiate replacement service order'
    >
      <InitiateReplacementServiceOrderForm form={form} />
    </Modal>
  );
}
