import ExtraInfoForm from "./extra-info-form";
import AssigneeInfoForm from "./assignee-info-form";
import AssigneeInfoSummary from "./assignee-info-summary";
import ExtraInfoSummary from "./extra-info-summary";
import ApproverInfoForm from "./approver-info-form";
import ApproverInfoSummary from "./approver-info-summary";

const formSteps = [
  {
    name: 'Assignee Info',
    description: "Information about agent to attend to service order.",
    accessor: "assigneeInfo",
    FormComponent: AssigneeInfoForm,
    SummaryComponent: AssigneeInfoSummary,
  },
  {
    name: 'Approver Info',
    description: "Information about user to approve to service order.",
    accessor: "approverInfo",
    FormComponent: ApproverInfoForm,
    SummaryComponent: ApproverInfoSummary,
  },
  {
    name: 'Extra Info',
    description: 'Information about wallet to be credited.',
    accessor: "extraInfo",
    FormComponent: ExtraInfoForm,
    SummaryComponent: ExtraInfoSummary,
  },
]

export default formSteps;
