import { gql, useQuery } from "@apollo/client";

const GET_INSTALLATION_SERVICE_ORDERS = gql`
  query GetBacklogInstallationServiceOrders(
  $page: Int
  $pageSize: Int
  $sort: String
  $search: String
  $searchFields: [String!]
  $region: ID
  $district: ID
  $status: InstallationServiceOrderStatus
  $assigner: ID
  $approver: ID
  $assignee: ID
  $farmingOutContractor: ID
  $meterContractor: ID
  $fromDate: Date
  $toDate: Date
) {
  rows: getBacklogInstallationServiceOrders(
    page: $page
    pageSize: $pageSize
    sort: $sort
    search: $search
    searchFields: $searchFields
    region: $region
    district: $district
    status: $status
    assigner: $assigner
    approver: $approver
    assignee: $assignee
    farmingOutContractor: $farmingOutContractor
    meterContractor: $meterContractor
    fromDate: $fromDate
    toDate: $toDate
  ) {
    _id
    code
    region {
      _id
      code
      name
    }
    district {
      _id
      code
      name
    }
    contactPerson {
      fullName
      phoneNumber
    }
    location {
      address
      community
    }
    assigner {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      profileImageUrl
    }
    assignee {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      profileImageUrl
    }
    approver {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
       
      }
    disapprover {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
       
      }
    assigneeType
    meterContractor {
      _id
      code
      name
    }
    farmingOutContractor {
      _id
      code
      name
    }
    farmingOutContractorUser {
      _id
      code
      username
      lastName
      firstName
    }
    status
    priority
    estimatedResolutionDate
    servicePointCode
    servicePoint {
      _id
      code
      geoCode
      tariffClassCode
      tariffClassName
    }
    requestedMeterPhase
    installedMeter {
      _id
      code
      modelMeta {
        brandCode
        brandName
        systemCode
        systemName
        modelCode
        modelName
      }
      system {
        _id
        code
        name
      }
      meterCode
    }
    installationMeter {
      _id
      code
      modelMeta {
        brandCode
        brandName
        systemCode
        systemName
        modelCode
        modelName
      }
      system {
        _id
        code
        name
      }
      meterCode
    }
    installationMeterSystemCode
    installationMeterSystemSyncStatus
    assignedAt
    startedAt
    resolvedAt
    completedAt
    disapprovedAt
    createdAt
    updatedAt
  }
  count: getBacklogInstallationServiceOrdersCount(
    search: $search
    searchFields: $searchFields
    region: $region
    district: $district
    status: $status
    meterContractor: $meterContractor
    fromDate: $fromDate
    toDate: $toDate
  )
}

`;

export const useBacklogInstallationServiceOrders = (variables: any) => {
  const { data, ...rest } = useQuery(GET_INSTALLATION_SERVICE_ORDERS, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });
  const installationServiceOrders = data?.rows || [];
  const count = data?.count || 0;
  return { installationServiceOrders, count, ...rest };
};
