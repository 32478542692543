import { gql, useMutation } from "@apollo/client";
import { EscalateInstallationServiceOrder, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { wrapClick } from "utils";

const ESCALATE_SERVICE_ORDER = gql`
  mutation EscalateInstallationServiceOrder(
    $id: ID!
    $reason: String
  ) {
    escalateInstallationServiceOrder(
      id: $id
      reason: $reason
    ) {
      _id
    }
  }
`;

export default function EscalateInstallationServiceOrderContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [escalateInstallationServiceOrder, { loading: closing }] = useMutation(
    ESCALATE_SERVICE_ORDER
  );
  const searchParams = useSearch<LocationGenerics>();
  const form = useFormik<any>({
    initialValues: {
      reason: "",
    },
    onSubmit: async (values) => {
        escalateInstallationServiceOrder({
        variables: { id: searchParams.id, reason: values.reason },
      }).then(({ data }) => {
        if (data.escalateInstallationServiceOrder._id) {
          refetch?.();
          setOpen(false);
          toast(
            JSON.stringify({
              type: "success",
              title: "Service Order Escalated Successfully",
            })
          );
          form.resetForm();
         
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not escalate this Service Order",
            })
          );
        }
      });
    },
    onReset: () => {
      form.resetForm();
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Escalate Service Order'
      size='4xl'
      description='Provide a reason for escalating this service order to SLT'
      renderActions={() => (
        <>
          <button
            type='button'
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {closing ? "Escalating ..." : "Escalate Service Order"}
          </button>
        </>
      )}
    >
      <EscalateInstallationServiceOrder form={form} />
    </Modal>
  );
}
