import { gql, useReactiveVar, useQuery } from "@apollo/client";
import {
  EnvelopeIcon,
  PhoneIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/outline";
import { currentConfigVar } from "apollo/cache/config";
import { FC, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { wrapClick } from "utils";

const GET_CUSTOMER = gql`
  query GetServiceRequestCustomerDetails($id: ID!) {
    serviceRequest: getServiceRequest(id: $id) {
      _id
      code
      category
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      request {
        customer {
          customerType

          organization {
            name
            taxIdentificationNumber
            organizationRegistrationNumber
            organizationRegistrationDate
            organizationRegistrationDocumentUrl
            certificateOfIncorporationDocumentUrl
          }
          representative {
            title
            fullName
            nationality
            phoneNumber
            emailAddress
            profileImageUrl
            hasGhanaCard
            ghanaCardNumber
            ghanaCardIssueDate
            ghanaCardExpiryDate
            ghanaCardFrontImageUrl
            ghanaCardBackImageUrl
            identityCardType
            identityCardNumber
            identityCardIssueDate
            identityCardExpiryDate
            identityCardFrontImageUrl
            identityCardBackImageUrl
          }
        }
        property {
          region {
            _id
            code
            name
          }
          district {
            _id
            code
            name
          }
          ghanaPostAddress
          community
          streetName
          houseNumber
          landmark
          activity {
            _id
            code
            name
          }
        }
        service {
          serviceType
          serviceClass
          energyCertificateNumber
          energyCertificateDocumentUrl
        }
      }
      type
      status
      createdAt
      updatedAt
      contract {
        status
        accountNumber
        contractNumber
      }
      response {
        account {
          _id
          code
        }
        customer {
          _id
          code
        }
        property {
          _id
          code
        }
        servicePoint {
          _id
          code
          geoCode
        }
        meter {
          _id
          code
        }
      }
    }
  }
`;

interface CustomerDetailsContainerProps {
  values: any;
  id: string;
  code: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  refetch?: () => void;
}

const CustomerDetailsContainer: FC<CustomerDetailsContainerProps> = ({
  values,
  code,
  id,
}) => {
  const printRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: code,
    bodyClass: "w-[1000px]",
  });
  const { dateFormat, pollInterval } = useReactiveVar(currentConfigVar);

  const { data, refetch } = useQuery(GET_CUSTOMER, {
    variables: {
      id,
    },
    fetchPolicy: "network-only",
  });

  return (
    <div className='flex-1 flex flex-col overflow-hidden'>
      <div ref={printRef} className='flex-1 flex flex-col overflow-hidden'>
        <div className='hidden print:flex flex-row items-center space-x-4 justify-center py-6 border-b border-gray-300'>
          <div>
            <img
              alt='ECG Logo'
              src={require("assets/logo-new.png")}
              className='h-32 w-32'
            />
          </div>
          <div className='space-y-1 text-gray-900'>
            <h1 className='text-xl text-blue-800 font-bold'>
              ELECTRICITY COMPANY OF GHANA LIMITED
            </h1>
            <div className='flex items-center space-x-6'>
              <div className='flex items-center space-x-2'>
                <EnvelopeIcon className='h-4 w-4' />
                <span>P.O BOX GP 521, ACCRA</span>
              </div>
              <div className='flex items-center space-x-2'>
                <PhoneIcon className='h-4 w-4' />
                <span>0302-611-611</span>
              </div>
              <div className='flex items-center space-x-2'>
                <GlobeAltIcon className='h-4 w-4' />
                <span>ecg@ecggh.com</span>
              </div>
            </div>
            <div className='flex items-center space-x-6'>
              <div className='flex items-center space-x-2'>
                <span className='font-semibold'>VAT REG:</span>
                <span>714V000395</span>
              </div>
              <div className='flex items-center space-x-2'>
                <span className='font-semibold'>Website:</span>
                <span>https://www.ecggh.com/</span>
              </div>
            </div>
          </div>
        </div>
        <div className='space-y-6 divide-y divide-gray-200 p-6 overflow-y-auto flex-1'>
          <div className='p-4'>
            <div className='flex justify-between items-center '>
              <div>
                <h3 className='text-md leading-6 font-semibold text-gray-900 dark:text-gray-100'>
                  Service Supply Information Summary
                </h3>
              </div>
              <div>
                <h3 className='text-md leading-6 font-semibold text-gray-900 dark:text-gray-100'>
                  Contract No.:{" "}
                  <span className='underline tracking-wider'>
                    {data?.serviceRequest?.contract?.contractNumber || "N/A"}
                  </span>
                </h3>
              </div>
            </div>
            <div className='mt-6'>
              <div>
                <h3 className='font-semibold text-sm text-blue-800 uppercase'>
                  Account Information
                </h3>
              </div>
              <div className='mt-2 grid grid-cols-3 border border-gray-300 col-span-2 gap-6 p-4'>
                <div className=''>
                  <span className='block text-sm'>Account Number</span>
                  <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                    {data?.serviceRequest?.response?.account?.code || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm'>Customer Number</span>
                  <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                    {data?.serviceRequest?.response?.customer?.code || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm'>Property Number</span>
                  <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                    {data?.serviceRequest?.response?.property?.code || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm'>Service Point Number</span>
                  <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                    {data?.serviceRequest?.response?.servicePoint?.code ||
                      "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm'>Geographical Code</span>
                  <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                    {data?.serviceRequest?.response?.servicePoint?.geoCode ||
                      "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm'>Meter Number</span>
                  <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                    {data?.serviceRequest?.response?.meter?.code || "N/A"}
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-6'>
              <div>
                <h3 className='font-semibold text-sm text-blue-800 uppercase'>
                  Customer Information
                </h3>
              </div>
              <div className='mt-2 grid grid-cols-5 border border-gray-300 divide-x divide-gray-300'>
                <div className='col-span-2 grid grid-cols-2 gap-6 p-4'>
                  <div className='col-span-2'>
                    <span className='block text-sm'>Name</span>
                    <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                      {data?.serviceRequest?.request?.customer?.representative
                        ?.fullName ||
                        data?.serviceRequest?.request?.customer?.representative
                          ?.fullName ||
                        "N/A"}
                    </div>
                  </div>
                  {data?.serviceRequest?.request?.customer?.representative
                    ?.hasGhanaCard ||
                  data?.serviceRequest?.request?.customer?.representative
                    ?.hasGhanaCard ? (
                    <>
                      <div>
                        <span className='block text-sm'>ID Type</span>
                        <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                          NIA
                        </div>
                      </div>
                      <div>
                        <span className='block text-sm'>ID Number</span>
                        <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                          {data?.serviceRequest?.request?.customer
                            ?.representative?.ghanaCardNumber ||
                            data?.serviceRequest?.request?.customer
                              ?.representative?.ghanaCardNumber ||
                            "N/A"}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <span className='block text-sm'>ID Type</span>
                        <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                          {data?.serviceRequest?.request?.customer
                            ?.representative?.identityCardType ||
                            data?.serviceRequest?.request?.customer
                              ?.representative?.identityCardType ||
                            "N/A"}
                        </div>
                      </div>
                      <div>
                        <span className='block text-sm'>ID Number</span>
                        <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                          {data?.serviceRequest?.request?.customer
                            ?.representative?.identityCardNumber ||
                            data?.serviceRequest?.request?.customer
                              ?.representative?.identityCardNumber ||
                            "N/A"}
                        </div>
                      </div>
                    </>
                  )}
                  <div className='col-span-2'>
                    <span className='block text-sm'>Payer Name</span>
                    <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                      {data?.serviceRequest?.request?.customer?.representative
                        ?.fullName ||
                        data?.serviceRequest?.request?.customer?.representative
                          ?.fullName ||
                        "N/A"}
                    </div>
                  </div>
                </div>
                <div className='col-span-3 grid grid-cols-2 gap-6 p-4'>
                  <div className='col-span-2'>
                    <span className='block text-sm'>Address</span>
                    <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                      {data?.serviceRequest?.request?.property
                        ?.ghanaPostAddress || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm'>Email</span>
                    <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                      {data?.serviceRequest?.request?.customer?.representative
                        ?.emailAddress ||
                        data?.serviceRequest?.request?.customer?.representative
                          ?.emailAddress ||
                        "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm'>Mobile No.</span>
                    <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                      {data?.serviceRequest?.request?.customer?.representative
                        ?.phoneNumber ||
                        data?.serviceRequest?.request?.customer?.representative
                          ?.phoneNumber ||
                        "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm'>Telephone</span>
                    <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                      {data?.serviceRequest?.request?.customer?.representative
                        ?.phoneNumber ||
                        data?.serviceRequest?.request?.customer?.representative
                          ?.phoneNumber ||
                        "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-6'>
              <div>
                <h3 className='font-semibold text-sm text-blue-800 uppercase'>
                  Contract Information
                </h3>
              </div>
              <div className='mt-2 grid grid-cols-5 border border-gray-300 divide-x divide-gray-300'>
                <div className='col-span-2 grid grid-cols-2 gap-6 p-4'>
                  <div className=''>
                    <span className='block text-sm'>Demand</span>
                    <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                      {data?.serviceRequest?.request?.service
                        ?.contractedDemand || "N/A"}{" "}
                      KVh
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm'>Deposit</span>
                    <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                      {data?.meterNumber || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm'>Supply Purpose</span>
                    <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                      {data?.serviceRequest?.request?.property?.activity
                        ?.name || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm'>Tariff</span>
                    <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                      {data?.serviceRequest?.request?.service?.serviceClass ||
                        "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm'>Termination Date</span>
                    <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                      {"N/A"}
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm'>Exemptions</span>
                    <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                      {"N/A"}
                    </div>
                  </div>
                </div>
                <div className='col-span-3 grid grid-cols-2 gap-6 p-4'>
                  <div className='col-span-2'>
                    <span className='block text-sm'>Supply Address</span>
                    <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                      {data?.serviceRequest?.request?.property?.streetName ||
                        "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm'>Landmark</span>
                    <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                      {data?.serviceRequest?.request?.property?.landmark ||
                        "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm'>Area</span>
                    <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                      {data?.serviceRequest?.request?.property?.community ||
                        "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm'>District</span>
                    <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                      {data?.serviceRequest?.request?.property?.district
                        ?.name || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm'>Region</span>
                    <div className='mt-1 block w-full text-sm font-light text-gray-700'>
                      {data?.serviceRequest?.request?.property?.region?.name ||
                        "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='hidden print:block mt-20 text-xs'>
              See conditions of supply at the back
            </div>
          </div>
        </div>
      </div>

      <div className='bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200'>
        <button
          type='button'
          onClick={wrapClick(handlePrint)}
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
        >
          Print
        </button>
      </div>
    </div>
  );
};

export default CustomerDetailsContainer;
