import { gql, useQuery } from "@apollo/client";

const GET_INSTALLATION_SERVICE_ORDERS = gql`
  query GetInstallationServiceOrders(
  $page: Int
  $pageSize: Int
  $sort: String
  $search: String
  $searchFields: [String!]
  $region: ID
  $district: ID
  $status: InstallationServiceOrderStatus
  $category: InstallationServiceOrderCategory
  $assigner: ID
  $approver: ID
  $assignee: ID
  $farmingOutContractor: ID
  $meterContractor: ID
  $fromDate: Date
  $toDate: Date
) {
  rows: getInstallationServiceOrders(
    page: $page
    pageSize: $pageSize
    sort: $sort
    search: $search
    searchFields: $searchFields
    region: $region
    district: $district
    status: $status
    category: $category
    assigner: $assigner
    approver: $approver
    assignee: $assignee
    farmingOutContractor: $farmingOutContractor
    meterContractor: $meterContractor
    fromDate: $fromDate
    toDate: $toDate
  ) {
    _id
    code
    region {
      _id
      code
      name
    }
    district {
      _id
      code
      name
    }
    contactPerson {
      fullName
      phoneNumber
    }
    location {
      address
      community
    }
    assigner {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      profileImageUrl
    }
    assignee {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      profileImageUrl
    }
    assigneeType
    meterContractor {
      _id
      code
      name
    }
    farmingOutContractor {
      _id
      code
      name
    }
    farmingOutContractorUser {
      _id
      code
      username
      lastName
      firstName
    }
    category
    status
    priority
    estimatedResolutionDate
    servicePointCode
    servicePoint {
      _id
      code
      geoCode
    }
    request {
      _id
      code
      type
      inspection {
        resolution {
          service {
            meterPhase
            tariffClass {
              _id
              code
              name
            }
          }
        }
      }
    }
    installationType {
      _id
      code
      name
    }
    installedMeter {
      _id
      code
      modelMeta {
        brandCode
        brandName
        systemCode
        systemName
        modelCode
        modelName
      }
      system {
        _id
        code
        name
      }
      meterCode
    }
    approver {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      disapprover {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
       
      }
      assignee {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
     
    installationMeter {
      _id
      code
      modelMeta {
        brandCode
        brandName
        systemCode
        systemName
        modelCode
        modelName
      }
      system {
        _id
        code
        name
      }
      meterCode
    }
    installationMeterSystemCode
    installationMeterSystemSyncStatus
    assignedAt
    startedAt
    resolvedAt
    completedAt
    rejectedAt
    createdAt
    updatedAt
  }
  count: getInstallationServiceOrdersCount(
    search: $search
    searchFields: $searchFields
    region: $region
    district: $district
    status: $status
    category: $category
    meterContractor: $meterContractor
    fromDate: $fromDate
    toDate: $toDate
  )
}

`;

export const useInstallationServiceOrders = (variables: any) => {
  const { data, ...rest } = useQuery(GET_INSTALLATION_SERVICE_ORDERS, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });
  const installationServiceOrders = data?.rows || [];
  const count = data?.count || 0;
  return { installationServiceOrders, count, ...rest };
};
