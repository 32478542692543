import { gql, useLazyQuery, useReactiveVar } from "@apollo/client";
import {
  OfficeHeader,
  SelectInput,
  Shimmers,
  TableComponent,
} from "components";
import _ from "lodash";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  classNames,
  wrapClick,
  useUrlState,
  useTableData,
  useDownloadFile,
} from "utils";
import config from "config";
import ViewBlockContainer from "./view";
import { currentConfigVar } from "apollo/cache/config";
import ActionButton, { Action } from "components/buttons/action-button";
import { useServiceRegularizations } from "./hooks";
import toast from "react-hot-toast";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
const DATE_FORMAT = "YYYY-MM-DD";

const searchOptions = [
  {
    label: "Code",
    value: "code",
    min: 4,
  },
  {
    label: "Representative Name",
    value: "customerData.representative.fullName",
    min: 4,
  },
  {
    label: "Representative Phone Number",
    value: "customerData.representative.phoneNumber",
    min: 4,
  },
];
export const GET_REGULARIZATION_EXPORT_URL = gql`
  query Query($region: ID, $district: ID) {
    url: getShepRegularizationsExportUrl(district: $district, region: $region)
  }
`;
const ServiceRegularizationsPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [region, setRegion] = useUrlState("region");
  const [district, setDistrict] = useUrlState("district");
  const [searchBy, setSearchParameter] = useUrlState("search_field");
  const [searchValue, setSearch] = useUrlState("search");
  const [minSearch, setMinSearch] = useUrlState("minSearch");

  const updateSearchBy = (key: string) => {
    setSearchParameter(key);
    if (key) {
      setMinSearch(searchOptions.find((option) => option.value === key)?.min);
    }
  };
  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search:
        searchParams.search && searchBy
          ? (searchParams.search || "").toString()
          : undefined,
      searchFields: searchBy && searchValue ? [searchBy] : undefined,
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
    }),
    [searchParams]
  );

  const { serviceRegularizations, count, loading, refetch, networkStatus } =
    useServiceRegularizations(filter);

  const records = useTableData({ rows: serviceRegularizations, count });

  const [createDataExport, { loading: loadingExport }] = useLazyQuery(
    GET_REGULARIZATION_EXPORT_URL
  );

  const { downloadAction, downloadLoading } = useDownloadFile({
    onError: () => {
      toast(
        JSON.stringify({
          type: "error",
          title: "An error occurred downloading file",
        })
      );
    },
    getFileName: () =>
      new Date().toISOString() + "_BacklogInstallationOrders.xlsx",
  });

  const handleDownloadItem = () => {
    createDataExport({
      variables: filter,
      fetchPolicy: "no-cache",
    }).then(({ data }) => {
      if (data.url) {
        downloadAction(`${data.url}`);
      } else {
        toast(
          JSON.stringify({ type: "error", title: "Could not export file" })
        );
      }
    });
  };
  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),

        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-gray-50">
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"regularizations"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            onSearchClicked={setSearch}
            disableSearch={!searchBy}
            hasSearch={true}
            renderSearchOption={() => (
              <SelectInput
                id={"searchBy"}
                label={""}
                values={{ searchBy }}
                handleChange={(e: any) => updateSearchBy(e.target.value)}
                handleBlur={undefined}
                options={[
                  {
                    label: "Search By",
                    value: "",
                  },
                  ...searchOptions,
                ]}
              />
            )} // renderHeaderItems={() => (
            //   <div className='mt-4 sm:mt-0.5 sm:ml-16 sm:flex space-x-2'>
            //     {records?.rows?.length > 0 && (
            //       <button
            //         type='button'
            //         onClick={wrapClick(handleDownloadItem)}
            //         disabled={loadingExport || downloadLoading}
            //         className='ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
            //       >
            //         <ArrowDownTrayIcon
            //           className='-ml-0.5 mr-2 h-4 w-4'
            //           aria-hidden='true'
            //         />
            //         {loadingExport
            //           ? "Processing file for download"
            //           : downloadLoading
            //           ? "Exporting"
            //           : "Export To Excel"}
            //       </button>
            //     )}
            //   </div>
            // )}
            // renderExport={({ exportOpen, setExportOpen }) => (
            //   <ExportDataContainer
            //     open={exportOpen}
            //     setOpen={setExportOpen}
            //     entityType="Regularization"
            //     filter={filter}
            //   />
            // )}

            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Code | Date
                </th>
                {!region && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Region
                  </th>
                )}
                {!district && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    District
                  </th>
                )}
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Customer Type
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Customer
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Category
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  GhanaPost | Community
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Service Type
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                {!region && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                {!district && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item?.code || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {moment(item?.createdAt).format(dateFormat)}
                  </div>
                </td>
                {!region && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <div className="font-medium text-gray-900 dark:text-gray-100">
                      {item?.region?.name || "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-400">
                      {item?.region?.code || "N/A"}
                    </div>
                  </td>
                )}
                {!district && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <div className="font-medium text-gray-900 dark:text-gray-100">
                      {item?.district?.name || "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-400">
                      {item?.district?.code || "N/A"}
                    </div>
                  </td>
                )}
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      item?.customerData?.customerType === "Individual"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                        : "",
                      item?.customerData?.customerType === "Organization"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                        : "",
                      !item?.customerData?.customerType
                        ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="w-1.5 h-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{item?.customerData?.customerType || "Unknown"}</span>
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  {item?.customerData?.customerType === "Individual" ? (
                    <>
                      <div className="text-gray-900 dark:text-gray-100">
                        {item?.customerData?.representative?.fullName || "N/A"}
                      </div>
                      <div className="text-gray-500 dark:text-gray-400">
                        {item?.customerData?.representative?.phoneNumber ||
                          "N/A"}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-gray-900 dark:text-gray-100">
                        {item?.customerData?.organization?.name || "N/A"}
                      </div>
                      <div className="text-gray-500 dark:text-gray-400">
                        {item?.customerData?.representative?.phoneNumber ||
                          "N/A"}
                      </div>
                    </>
                  )}
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      item?.billing?.neighbourhoodClass === "Urban"
                        ? `bg-primary-100 text-primary-800`
                        : "",
                      item?.billing?.neighbourhoodClass === "PeriUrban"
                        ? `bg-cyan-100 text-cyan-800`
                        : "",
                      item?.billing?.neighbourhoodClass === "Rural"
                        ? `bg-purple-100 text-purple-800`
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="w-1.5 h-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>
                      {item?.billing?.neighbourhoodClass || "Unknown"}
                    </span>
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.property?.ghanaPostAddress || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.property?.community || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      item?.service?.serviceClass === "Residential"
                        ? `bg-primary-100 text-primary-800`
                        : "",
                      item?.service?.serviceClass === "NonResidential"
                        ? `bg-cyan-100 text-cyan-800`
                        : "",
                      item?.service?.serviceClass === "Industrial"
                        ? `bg-purple-100 text-purple-800`
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="w-1.5 h-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>
                      {_.startCase(item?.service?.serviceClass) || "Unknown"}
                    </span>
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      item?.status === "SuperAdmin"
                        ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50`
                        : "",
                      item?.status === "Admin"
                        ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50`
                        : "",
                      item?.status === "Supervisor"
                        ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                        : "",
                      item?.status === "Reader"
                        ? `bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50`
                        : "",
                      item?.status === "Queued"
                        ? `bg-gray-100 text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50`
                        : "",
                      item?.status === "Success"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                        : "",
                      item?.status === "Reversed"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                        : "",
                      !item?.status
                        ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="w-1.5 h-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{_.startCase(item?.status || "Pending")}</span>
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {/* <ActionButton
                      action='expand'
                      disabled
                      onClick={navigateItem(item?._id)}
                    /> */}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {!!searchParams.id?.length && (
        <>
          <ViewBlockContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default ServiceRegularizationsPage;
