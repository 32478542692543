import { FC } from "react";
import { Avatar } from "components/core";
import { classNames, wrapImage } from "utils";
import numeral from "numeral";
import moment from "moment";
import lodash from "lodash";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import Map from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MapProvider, Marker } from "react-map-gl";
import config from "config";

interface ReadingViewProps {
  anomaly: any;
}

const ReadingView: FC<ReadingViewProps> = ({ anomaly }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div>
        <span className='text-xs font-light'>Customer Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div className='row-span-2 flex flex-col space-y-3 items-center justify-center'>
            <Avatar
              alt={
                anomaly?.customer?.representative?.fullName?.trim() ||
                anomaly?.customer?.organization?.name?.trim() ||
                anomaly?.customer?.representative?.fullName?.trim() ||
                "N A"
              }
              src={
                anomaly?.customer?.representative?.profileImageUrl?.trim() ||
                anomaly?.customer?.representative?.profileImageUrl?.trim() ||
                ""
              }
              size='xl'
            />
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Customer Code
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {anomaly?.customer?.code || "N/A"}
            </div>
          </div>
          <div className='col-span-2'>
            <span className='block text-sm font-light text-gray-700'>
              Full Name
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {anomaly?.customer?.representative?.fullName?.trim() ||
                anomaly?.customer?.organization?.name?.trim() ||
                anomaly?.customer?.representative?.fullName?.trim() ||
                "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Ghana Card Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {anomaly?.customer?.representative?.ghanaCardNumber?.trim() ||
                anomaly?.customer?.representative?.ghanaCardNumber?.trim() ||
                "N A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Phone Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {anomaly?.customer?.representative?.phoneNumber?.trim() ||
                anomaly?.customer?.representative?.phoneNumber?.trim() ||
                "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Email Address
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {anomaly?.customer?.representative?.emailAddress?.trim() ||
                anomaly?.customer?.representative?.emailAddress?.trim() ||
                "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Contract Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Service Point Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {anomaly?.servicePoint?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Meter Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {anomaly?.meter?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Account Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {anomaly?.servicePoint?.accountCode || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Contracted Demand
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {anomaly?.servicePoint?.contractedDemand || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Customer Type
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {anomaly?.customer?.customerType || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Service Type
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {anomaly?.servicePoint?.serviceType || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Tariff Class
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {anomaly?.servicePoint?.tariffClass?.name || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Property Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Region
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {anomaly?.region?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              District
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {anomaly?.district?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Block
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {anomaly?.block?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Round
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {anomaly?.round?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>Plot</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {anomaly?.plotCode || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Property
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {anomaly?.servicePoint?.propertyCode || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Geo Code
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {anomaly?.servicePoint?.geoCode || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadingView;
