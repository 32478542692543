import { TextArea } from "components/core"
import { FormikProps } from "formik"
import { FC } from "react"

interface DisapproveMeterRemovalServiceOrderFormProps {
  form: FormikProps<{
    reason: string;
  }>;
}

const DisapproveMeterRemovalServiceOrderForm: FC<DisapproveMeterRemovalServiceOrderFormProps> = ({ form }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Disapproval Information</span>
        <div className="grid gap-6 mt-2">
          <div>
            <TextArea
              id="reason"
              label="Disapproval Reason"
              placeholder="e.g. Details captured are inconclusive"
              required={true}
              rows={10}
              {...form}
            />
          </div>

        </div>
      </div>
    </div>
  )
}

export default DisapproveMeterRemovalServiceOrderForm