import { gql, useQuery } from '@apollo/client'
import { FC } from 'react';
import { SearchSelectInput } from 'components/core';

interface PremiseTypePickerContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  rawId?: boolean;
}

const GET_PREMISE_TYPES = gql`
  query GetPremiseTypesSelect {
    premiseTypes: getPremiseTypes(sort: "name") {
      _id
      code
      name
    }
  }
`;

const PremiseTypePickerContainer: FC<PremiseTypePickerContainerProps> = ({ id, label, rawId, ...form }) => {
  const { loading, data } = useQuery(GET_PREMISE_TYPES, {
    notifyOnNetworkStatusChange: false
  })

  return (
    <SearchSelectInput
      id={id ?? "premiseType"}
      label={label ?? "Premise Type"}
      placeholder="Select Premise Type"
      optionsLoading={loading}
      options={(data?.premiseTypes ?? [])?.map((premiseType: any) => ({
        label: {
          title: premiseType.name as string,
        },
        value: rawId ? premiseType?._id : premiseType
      }))}
      {...form}
      disabled={form.disabled}
    />
  )
}

export default PremiseTypePickerContainer