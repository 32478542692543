import { gql } from "@apollo/client";

export const GET_SERVICE_ORDERS = gql`
  query GetRegularizationInspectionServiceOrders(
  $page: Int
  $pageSize: Int
  $sort: String
  $search: String
  $searchFields: [String!]
  $region: ID
  $district: ID
  $status: InspectionServiceOrderStatus
  $priority: InspectionServiceOrderPriority
  $assigner: ID
  $approver: ID
  $assignee: ID
  $fromDate: Date
  $toDate: Date
) {
  rows: getRegularizationInspectionServiceOrders(
    page: $page
    pageSize: $pageSize
    sort: $sort
    search: $search
    searchFields: $searchFields
    region: $region
    district: $district
    status: $status
    priority: $priority
    assigner: $assigner
    approver: $approver
    assignee: $assignee
    fromDate: $fromDate
    toDate: $toDate
  ) {
    _id
    code
    region {
      _id
      code
      name
    }
    district {
      _id
      code
      name
    }
    priority
    contactPerson {
      fullName
      phoneNumber
    }
    location {
      address
      community
    }
    assigner {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      profileImageUrl
      role {
        _id
        code
        name
      }
    }
    servicePointCode
    accountCode
    approver {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      profileImageUrl
      role {
        _id
        code
        name
      }
    }
    disapprover {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
    assignee {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      profileImageUrl
      role {
        _id
        code
        name
      }
    }
    status
    estimatedResolutionDate
    assignedAt
    startedAt
    resolvedAt
    completedAt
    disapprovedAt
    createdAt
    updatedAt
    result
  }
  count: getRegularizationInspectionServiceOrdersCount(
    search: $search
    searchFields: $searchFields
    region: $region
    district: $district
    status: $status
    priority: $priority
    fromDate: $fromDate
    toDate: $toDate
  )
}

`;

export const GET_SERVICE_ORDERS_SUMMARY = gql`
  query GetRegularizationInspectionServiceOrderSummary(
  $search: String
  $searchFields: [String!]
  $region: ID
  $district: ID
  $status: InspectionServiceOrderStatus
  $priority: InspectionServiceOrderPriority
  $assigner: ID
  $approver: ID
  $assignee: ID
  $fromDate: Date
  $toDate: Date
) {
  summary: getRegularizationInspectionServiceOrderSummary(
    search: $search
    searchFields: $searchFields
    region: $region
    district: $district
    status: $status
    priority: $priority
    assigner: $assigner
    approver: $approver
    assignee: $assignee
    fromDate: $fromDate
    toDate: $toDate
  ) {
    Pending
    Assigned
    Resolved
    InProgress
    Completed
    Disapproved
  }
}
`;
