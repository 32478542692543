import { gql, useQuery } from "@apollo/client";


const GET_METER_CONTRACTORS_USERS = gql`
query GetMeterContractorUsers(
    $page: Int
    $pageSize: Int
    $search: String
    $searchFields: [String!]
    $sort: String
    $contractor: ID
  ) {
    rows:getMeterContractorUsers(
      page: $page
      pageSize: $pageSize
      search: $search
      searchFields: $searchFields
      sort: $sort
      contractor: $contractor
    ) {
        _id
        code
        lastName
        firstName
        gender
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        createdAt
        updatedAt
        role
      }
  }
`;


const GET_METER_CONTRACTORS_USERS_COUNT = gql`
query GetMeterContractorsCount(
  $search: String
  $searchFields: [String!]
) {
  count: getMeterContractorUsersCount(
    search: $search
    searchFields: $searchFields
  )
}

`

export const useMeterContractorUsers = (variables: any) => {
  const { data, ...rest } = useQuery(GET_METER_CONTRACTORS_USERS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
  const { data: countData } = useQuery(GET_METER_CONTRACTORS_USERS_COUNT, {
    variables,
  });
  const contractorUsers = data?.rows || [];
  const count = countData?.count || 0;
  return { contractorUsers, count, ...rest };
}