import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { FC } from "react";
import InvestigationServiceInfoSummary from "./service";
import InvestigationReadingInfoSummary from "./reading";
import InvestigationMaterialInfoSummary from "./materials";
import LastPaymentInfoSummary from "./last-payment";

interface InstallationServiceOrderResolutionViewProps {
  resolution: {
    service: any;
    currentReading: any;
    outcomes: any;
    lastPayment: any;
    notes: string;
    materials: any;
  };
  result: string;
}

const InstallationServiceOrderResolutionView: FC<
  InstallationServiceOrderResolutionViewProps
> = ({ resolution, result }) => {
  const currentConfig = useReactiveVar(currentConfigVar);

  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div className='flex justify-start'>
        <img
          src={
            result === "Passed"
              ? require("../../../assets/passed-stamp.png")
              : require("../../../assets/failed-stamp.png")
          }
          className='h-24 '
          alt=''
        />
      </div>
      <div className=''>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
              Service Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>Details about property</p>
          </div>
          <div />
        </div>
        <div className='mt-6'>
          <InvestigationServiceInfoSummary data={resolution?.service} />
        </div>
      </div>
      <div className='pt-6 '>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
              Reading Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>Details about property</p>
          </div>
          <div />
        </div>
        <div className='mt-6'>
          <InvestigationReadingInfoSummary
            data={{
              readingDate: resolution?.currentReading?.readingDate,
              readingValue: resolution?.currentReading?.readingValue,
              readingImageUrl: resolution?.currentReading?.readingImageUrl,
              notes: resolution?.notes,
            }}
            config={currentConfig}
          />
      </div>
      </div>
      <div className='pt-6 '>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
              Materials Infomation
            </h3>
            <p className='mt-1 text-xs text-gray-500'>
              Details about materials used for the investigation
            </p>
          </div>
          <div />
        </div>
        <div className='mt-4'>
          <InvestigationMaterialInfoSummary
            data={{
              recoveredMaterials: resolution?.materials,
            }}
          />
        </div>
      </div>
      <div className='pt-6'>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
              Last Payment Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>
              Details about the last payment
            </p>
          </div>
          <div />
        </div>
        <div className='mt-6'>
          <LastPaymentInfoSummary
            data={resolution?.lastPayment}
            config={currentConfig}
          />
        </div>
      </div>
    </div>
  );
};

export default InstallationServiceOrderResolutionView;
